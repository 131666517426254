import React,{ useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { useNavigate,useParams } from "react-router-dom";
import EmployeeDetailsHeader from "layouts/common_components/emp_details_header";
import dashboardApi from "layouts/common_components/apibase_url";
import  secureLocalStorage  from  "react-secure-storage";
import Pagination from 'react-bootstrap/Pagination';
//import 'bootstrap/dist/css/bootstrap.min.css';
import ReactPaginate from 'react-paginate';


function SentMail() {
  const employee_id = secureLocalStorage.getItem('emp_id');

  const token = secureLocalStorage.getItem('token');
  const [latter_type, set_latter_type] = useState('');
  const [department,set_department] = useState('');
  const [emp_id,set_emp_id] = useState('');
  const [department_list,set_department_list] = useState([]);
  const [emplist,set_emp_list] = useState([]);
  const [mail_type,set_mail_type] = useState([]);
  const navigate = useNavigate();
  const [validationErrors, setValidationErrors] = useState({});
  const [currentPage, setCurrentPage] = useState(0); 
  const [number, setNumber] = useState(1);
  useEffect(() => {
   if (!token) {
    navigate("/sign-in");
   // return null;
  }
  }, [token, navigate]);

  useEffect(()=>{
    get_emp_details();
   },[department])


    const clear_fields = ()=>{
        set_department('');
        set_emp_id('');
        set_mail_type('');

    }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const endpoint = 'get-active-department';
        const response = await dashboardApi.get(endpoint);
  
        if (response.data.status === 200) {
         set_department_list(response.data.data);
        } else {
          console.error('Error fetching data:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  }, []);

  const sent_mail = async () => {
    const errors = {};
    if (!emp_id) {
        errors.emp_id = 'Emp is required';
    }
    if (!mail_type) {
        errors.mail_type = 'Mail Type is required';
    }
    if (!department) {
        errors.department = 'Department is required';
    }

    setValidationErrors(errors);
    if (Object.keys(errors).length === 0) {
     try {
      const response = await dashboardApi.get('send-welcome-mail', {
        params: {
          emp_id: emp_id,
         
        },
      });
      if (response.data.status === 200) {
           Swal.fire({
             icon: 'success',
             text: response.data.message,
        });
        clear_fields();
     } else {
        console.error('Error fetching data:', response.data.error);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
   } 
  };

  const get_emp_details = async () => {
    if(department){
        try {
            //const response = await dashboardApi.post('department-employee', {
           const response = await dashboardApi.get(`department-employee/${department}`);
            if (response.data.status === 200) {
                set_emp_list(response.data.data)
            } else {
              console.error('Error fetching data:', response.data.error);
            }
          } catch (error) {
            console.error('Error fetching data:', error);
          }

    }
  };
return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <div className="card-body">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3">
                            <select className="form-control"
                              value={mail_type}
                              onChange={(e) => set_mail_type(e.target.value)}
                              >
                                <option value=''>Select Mail Type</option>
                                <option value='welcome_mail'>Welcome Mail</option>
                                </select>
                                {validationErrors.mail_type && (
                                 <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.mail_type}</div>
                                )}
                               </div>

                               <div className="col-md-3">
                               <select className="form-control"
                                value={department}
                                onChange={(event)=>{
                                  set_department(event.target.value)}}
                                >
                               <option value=''>Select Deprtment</option>
                                {department_list.map((departments) => (
                                <option key={departments.id} value={departments.id}>
                                {departments.department_name}
                              </option>
                              ))}
                            </select>
                            {validationErrors.department && (
                                 <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.department}</div>
                                )}
                            </div>

                             <div className="col-md-3">
                               <select className="form-control"
                                value={emp_id}
                                onChange={(event)=>{
                                  set_emp_id(event.target.value)}}
                                >
                               <option value=''>Select Employee</option>
                                {emplist.map((employee) => (
                                <option key={employee.emp_id} value={employee.emp_id}>
                                {employee.emp_fname} {employee.emp_lame}
                              </option>
                              ))}
                            </select>
                            {validationErrors.emp_id && (
                                 <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.emp_id}</div>
                                )}
                         </div>
                         <div className="col-md-3">
                         <button className="btn btn-info btn-sm" onClick={sent_mail}>Sent</button>

                         </div>
                         </div>
                         </div>
                </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default SentMail;
