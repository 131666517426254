import Icon from "@mui/material/Icon";
import SignIn from "layouts/authentication/sign-in";
import Guest_client_details from "layouts/sales/moving";
import Verified_client_details from "layouts/sales/moving/verified_client";
import ClientProfile from "layouts/sales/moving/client_profile";
import TodayFollowup from "layouts/sales/moving/today-followup";
import Mature_client_details from "layouts/sales/moving/mature_client";
import MatureClientProfile from "layouts/sales/moving/mature_client_profile";
import secureLocalStorage  from  "react-secure-storage";
import ActiveClient from "layouts/sales/moving/active_client";
import InActiveClient from "layouts/sales/moving/inactive_client";
import ExpireClient from "layouts/sales/moving/expire_client";
import LeftFollowup from "layouts/sales/moving/left-followup";
import ClientRequestDetails from "layouts/sales/moving/client_request_details";
const emp_id = secureLocalStorage.getItem('emp_id');
const token = secureLocalStorage.getItem('token');
const permission = secureLocalStorage.getItem('permission');
const parsedPermission = permission ? JSON.parse(permission) : [];

const salesroute = [
{
      type: "collapse",
      name: "Sales",
      key: "Sales",
      icon: <Icon fontSize="small">account_tree</Icon>,
      nested: [
        {
            route: "/guest-client-details",
            name: "Sales Panel",
            key: "Sales Panel",
            icon: <Icon fontSize="small">published_with_changes</Icon>,
            component: (token!=null) ? <Guest_client_details name ='gest-client'/> : <SignIn/>,
        },
      ]
    },
    {
      route: "/guest-client-details",
      component: (token!=null) ? <Guest_client_details name ='gest-client'/> :  <SignIn/>,
    },
    {
      route: "/verified-client-details",
      component: (token!=null) ? <Verified_client_details name ='verified-client'/> :  <SignIn/>,
    },
    {
      route: "/mature-client-details",
      component: (token!=null) ? <Mature_client_details name ='mature-client'/> :  <SignIn/>,
    },
    {
      route: "/active-client",
      component: (token!=null) ? <ActiveClient/> :  <SignIn/>,
    },
    {
      route: "/inactive-client",
      component: (token!=null) ? <InActiveClient/> :  <SignIn/>,
    },
    {
      route: "/expire-client",
      component: (token!=null) ? <ExpireClient/> :  <SignIn/>,
    },
    {
      route: "/client-request-details",
      component: (token!=null) ? <ClientRequestDetails/> :  <SignIn/>,
    },
    {
      route: "/today-followup",
      component: (token!=null) ? <TodayFollowup/> :  <SignIn/>,
    },
    
    {
      route: "/left-followup",
      component: (token!=null) ? <LeftFollowup/> :  <SignIn/>,
    },
    {
      route: "/client-profile/:clientId",
      component: (token!=null) ? <ClientProfile/> :  <SignIn/>,
    },
    {
      route: "/mature-client-profile/:clientId",
      component: (token!=null) ? <MatureClientProfile/> :  <SignIn/>,
    }
];
export default salesroute;