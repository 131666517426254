import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import dashboardApi from 'layouts/common_components/apibase_url';
import secureLocalStorage from 'react-secure-storage';
import { Link, Navigate, useNavigate,useParams } from 'react-router-dom';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Swal from "sweetalert2";

function EmpRoster() {
    const [employees, setEmployees] = useState([]);
    const [datesInMonth, setDatesInMonth] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(0);
    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth()); 
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const empId = secureLocalStorage.getItem('emp_id');
    const daysPerPage = 15;
    const [openmodal,setopenmodal] = useState(false);
    const [old_date,setold_date] = useState('');
    const [new_date,setnew_date] = useState('');
    const [reason,setreason] = useState('');
   const [validationErrors,setValidationErrors] = useState({});
   const [rosterchangerequest,setrosterchangerequest] = useState([]);

    useEffect(() => {
        const fetchEmployees = async () => {
            try {
                const response = await dashboardApi.get('show-emp-roster', {
                    params: {
                        emp_id: empId,
                        month: selectedMonth + 1, 
                        year: selectedYear,
                    },
                });
                setEmployees(response.data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching employee data:', error);
                setLoading(false);
            }
        };

        const getCurrentMonthDates = (month, year) => {
            const daysInMonth = new Date(year, month + 1, 0).getDate();
            const dates = [];
            for (let day = 1; day <= daysInMonth; day++) {
                dates.push(`${year}-${String(month + 1).padStart(2, '0')}-${String(day).padStart(2, '0')}`);
            }
            setDatesInMonth(dates);
        };

        getCurrentMonthDates(selectedMonth, selectedYear);
        fetchEmployees();
    }, [selectedMonth, selectedYear]);

    useEffect(()=>{
        fetchrosterrequest()

    },[empId])

    const openpopup = ()=>{
        setopenmodal(true);


    }
    const closepopup = ()=>{
        setnew_date('');
        setold_date('');
        setreason('');
        setopenmodal(false);
        setValidationErrors({})

    }

    const savedata = async()=>{

    const errors = {};
    if(!old_date){
        errors.old_date = ' Old Date is Required';

    }
    if(!reason){
        errors.reason = 'Reason Is Required';

    }

    if(!new_date){
        errors.new_date = 'New Date Is Required';

    }

    setValidationErrors(errors);
    if (Object.keys(errors).length === 0) {
        const formData = new FormData();
        formData.append('emp_id',empId);
        formData.append('old_date',old_date);
        formData.append('new_date',new_date);
        formData.append('reason',reason);
        try {
            const response =  await dashboardApi.post('request-to-change-roster', formData);
          if (response.data.status === 200) {
            Swal.fire({
              icon: 'success',
              text: response.data.message,
            });
            fetchrosterrequest();
            closepopup();
            //fetchEmployees()
    
          }
          else {
            Swal.fire({
              icon: 'error',
              text: response.data.message || 'An error occurred',
            });
          }
        } catch (error) {
          Swal.fire({
            icon: 'error',
            text: 'An error occurred while creating',
          });
        } finally {
          //setIsSubmitting(false);
        }

     }


    }

        const fetchrosterrequest = async () => {
            try {
                const response = await dashboardApi.get('roster-change-request-of-particular-employee', {
                    params: {
                        emp_id: empId,
                    },
                });
                if(response.data.status ==200){
                     setrosterchangerequest(response.data.data);

                }
               
                //setLoading(false);
            } catch (error) {
                console.error('Error fetching employee data:', error);
                //setLoading(false);
            }
        };



    if (loading) {
        return <div>Loading...</div>;
    }

    const totalPages = Math.ceil(datesInMonth.length / daysPerPage);
    const currentDates = datesInMonth.slice(currentPage * daysPerPage, (currentPage + 1) * daysPerPage);

    const handleNext = () => {
        if (currentPage < totalPages - 1) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePrevious = () => {
        if (currentPage > 0) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleMonthChange = (event) => {
        setSelectedMonth(event.target.value - 1); 
    };

    const handleYearChange = (event) => {
        setSelectedYear(event.target.value);
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Card>
                            <div className="card-body">
                                <h6>Employee Roster</h6>

                                <div className="filters">
                                 <div style={{float:'right'}}>
                                   <button className='btn btn-info btn-sm' onClick={openpopup} >Change Request</button>
                                 </div>
                                    <FormControl variant="outlined" style={{ marginRight: '10px' }}>
                                        <InputLabel>Month</InputLabel>
                                        <Select
                                            value={selectedMonth + 1} // Display 1-based month number
                                            onChange={handleMonthChange}
                                            className='form-control'
                                            label="Month"
                                        >
                                            {Array.from({ length: 12 }).map((_, index) => (
                                                <MenuItem key={index} value={index + 1}> {/* Use 1-based value */}
                                                    {new Date(0, index).toLocaleString('default', { month: 'long' })}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>

                                    <FormControl variant="outlined" style={{ marginRight: '10px' }}>
                                        <InputLabel>Year</InputLabel>
                                        <Select
                                            value={selectedYear}
                                            onChange={handleYearChange}
                                            label="Year"
                                            className='form-control'
                                        >
                                            {Array.from({ length: 5 }).map((_, index) => (
                                                <MenuItem key={selectedYear - 2 + index} value={selectedYear - 2 + index}>
                                                    {selectedYear - 2 + index}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>

                                <div className="table-container">
                                    <table className="roster-table table-responsive">
                                        <thead>
                                            <tr>
                                                <td className="fixed-column">Name</td>
                                                {currentDates.map(date => (
                                                    <td key={date}>{date}</td>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {employees.map(employee => (
                                                <tr key={employee.emp_name}>
                                                    <td className="fixed-column">{employee.emp_name}</td>
                                                    {currentDates.map(date => {
                                                        const isPresent = employee.attendance_dates && employee.attendance_dates.includes(date);
                                                        const isLeave = employee.leave_dates && employee.leave_dates.includes(date);
                                                        return (
                                                            <td key={date} className={isPresent ? 'present' : isLeave ? 'leave' : ''}>
                                                                {isPresent ? 'WO' : isLeave ? 'Leave' : 'p'}
                                                            </td>
                                                        );
                                                    })}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                
                                <div style={{ marginTop: '20px' }}>
                                    <Button 
                                        onClick={handlePrevious} 
                                        disabled={currentPage === 0} 
                                        variant="contained" 
                                        color="primary"
                                    >
                                        Previous
                                    </Button>
                                    <Button 
                                        onClick={handleNext} 
                                        disabled={currentPage >= totalPages - 1} 
                                        variant="contained" 
                                        color="primary"
                                        style={{ marginLeft: '10px' }}
                                    >
                                        Next
                                    </Button>
                                </div>
                            </div>
                        </Card>
                        <hr/>
                        <Card>
                        <center>Roster Change Request</center>
                       
                         <div className='col-md-12'>
                        <table className='table table-bordered table-hovered'>
                        <tr>
                        <td>Old Date</td>
                        <td>New Date</td>
                        <td>Reason</td>
                        <td>Status</td>
                        <td>Action By</td>
                        </tr>

                        {rosterchangerequest.map((row, index) => (
                        <tr key={row.emp_id}>
                          <td>{row.old_date}</td>
                          <td>{row.new_date}</td>
                          <td>{row.reason}</td>
                          <td>{row.status}</td>
                          <td>{row.emp_fname} {row.emp_lame}</td>
                        </tr>
                      ))}

                        </table>
                        </div>

                        </Card>

                         <Dialog
        open={openmodal}
        //onClose={closeModalHandler}
        fullWidth
        PaperProps={{ style: { maxWidth: 'md', padding: '40px', height:'500px'} }}
      >
        <DialogTitle>Send Roster Change  Request</DialogTitle>
        <DialogContent>
          <form>
           <div className='container'>
            <div className="row">
                <div className="col-md-6">
                <label htmlFor="short name" style={{ fontSize: '16px',height:'20px' }}>Old Date</label>
                   <input 
                     type="date"
                     className="form-control"
                     value={old_date}
                     onChange={(e) => setold_date(e.target.value)}
                   />
                   {validationErrors.old_date && (
                        <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.old_date}</div>
                     )}

                </div>

                 <div className="col-md-6">
                <label htmlFor="short name" style={{ fontSize: '16px',height:'20px' }}>New Date</label>
                   <input 
                     type="date"
                     className="form-control"
                     value={new_date}
                     onChange={(e) => setnew_date(e.target.value)}
                   />
                   {validationErrors.new_date && (
                        <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.new_date}</div>
                     )}

                </div>

            </div>
            <br/>

            <div className ="row">
                        <div className="form-group col-md-12">
                          <label htmlFor="short name" style={{ fontSize: '16px',height:'20px' }}>Reason</label>
                           <textarea rows={5} cols={5} style={{height:'50px'}}
                            value={reason}
                            onChange={(e) => setreason(e.target.value)}
                            className = "form-control"


                           />
                     {validationErrors.reason && (
                        <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.reason}</div>
                     )}
                    </div>
                    </div>


           

           </div>
          </form>
        </DialogContent>
        <DialogActions>
          <button className="btn btn-danger" onClick ={closepopup} >
            Close
          </button>
          <button className="btn btn-info" onClick={savedata} >
            Save
          </button>
        </DialogActions>
      </Dialog>
                    </Grid>
                </Grid>
            </MDBox>
        </DashboardLayout>
    );
}

export default EmpRoster;
