import React, { useState, useEffect } from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Pusher from "pusher-js";
import { ToastContainer, toast } from 'react-toastify';
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
// Material Dashboard 2 React example components
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
// Data
import { Link } from 'react-router-dom';
import "layouts/superadmin/components/global.css";

import dashboardApi from "layouts/common_components/apibase_url";
import { useNavigate } from 'react-router-dom';
import ClientDetailsHeader from 'layouts/common_components/client_details_header';
import secureLocalStorage from "react-secure-storage";
import "react-datepicker/dist/react-datepicker.css";
import Pagination from 'react-bootstrap/Pagination';
import ReactPaginate from 'react-paginate';
import "./followupStyle.css";
import Swal from 'sweetalert2';

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

function ClientRequestDetails()
{
  const navigate = useNavigate();
  const emp_id = secureLocalStorage.getItem('emp_id');
  const token = secureLocalStorage.getItem('token');
  const department = secureLocalStorage.getItem('department_id');
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [number, setNumber] = useState(1);
  const [postPerPage, setPostPerPage] = useState(10);
  const [validationErrors, setValidationErrors] = useState({});
  const [mobileNumber, setMobileNumber] = useState('');
  const [companyDetails, setCompanyDetails] = useState([]);
  const [selectedRequestType, setSelectRequestType] = useState('');
  const [selectedRequestCategory, setSelectedRequestCategory] = useState('');
  const [requestServiceDetails, setRequestServiceDetails] = useState([]);
  const [requestCategory, setRequestCategory] = useState([]);
  const [requestActivePackage, setRequestActivePackage] = useState([]);
  const [selectedRequestActivePackage, setSelectedRequestActivePackage] = useState('');
  const [selectedComp, setSelectedComp] = useState('');
  const [packageRemark, setPackageRemarks] = useState();
  const [checkboxState, setCheckboxState] = useState({
    competitor: false,
    area: false,
  });

  const [compMobileNumber, setCompMobileNumber] = useState('');
  const [compEmailId, setCompEmailId] = useState('');
  const [compClientId, setCompClientId] = useState('');
  const [compStatus, setCompStatus] = useState('');
  const [compNewNoStatus, setShowNewNumber] = useState(0);
  const [packageMobileNoStatus, setShowPackageNumber] = useState(0);
  const [compNewEmailStatus, setShowNewEmail] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const [mobileNewNumber, setNewMobileNumber] = useState('');
  const [newEmailId, setNewEmailId] = useState('');
  const [otp, setOtp] = useState('');
  const [checkMobileStatus, setCheckMobileStatus] = useState('');
  const [isReadOnly, setIsReadOnly] = useState(false);

  const emptyAllFildes = () => {
    setSelectRequestType('');
    setSelectedRequestCategory('');
    setSelectedRequestActivePackage('');
    setSelectedComp('');
    setPackageRemarks('');
    setCheckboxState('');
    setIsReadOnly(false);
    setOtp('');
    setNewEmailId('');
    setNewMobileNumber('');
    setCheckMobileStatus('');
    setShowPackageNumber('');
    setPackageMobileNumber('');
    setShowNewNumber(0);
    setShowNewEmail(0);
    setCompStatus('');
    setCompClientId('');
    setCompEmailId('');
    setCompMobileNumber('');
  }

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setCheckboxState((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  useEffect(() => {
    const handleCompanyInfo = () => {
      const company = companyDetails.find(cd => cd.comp_id === selectedComp);
      if (company) {
        setCompMobileNumber(company.mobile_no);
        setCompEmailId(company.email);
        setCompClientId(company.client_id);
        setCompStatus(company.status);
      }
      else {
        setCompMobileNumber('');
        setCompEmailId('');
        setCompClientId('');
        setCompStatus('');
      }
    };
    handleCompanyInfo();
  }, [selectedComp, companyDetails]);

  const [documentFile, setDocumentFile] = useState([]);

  const handleFileChange = (e) => {
    const errors = {};
    const files = e.target.files;
    const allowedTypes = [
      'image/jpeg',
      'image/png',
      'image/gif',
      'image/webp',
      'image/bmp',
      'image/tiff',
      'image/svg+xml'
    ];

    const validFiles = Array.from(files).filter((file) =>
      allowedTypes.includes(file.type)
    );


    if (validFiles.length !== files.length) {
      errors.validFiles = 'Only image files (JPG, PNG, GIF, Webp, Bmp, Tiff, Svg) are allowed.';
    }
    else {
      console.log('Valid image files:', validFiles);
    }
    setValidationErrors(errors);
    if (Object.keys(errors).length > 0) {
      return;
    }
    setDocumentFile(e.target.files);
  };
  const [requestType, setRequestType] = useState("pending");
  const [pendingStatus, setPendingStatus] = useState("");
  const [processStatus, setProcessStatus] = useState("");
  const [newStatus, setNewStatus] = useState("");
  const [completedStatus, setCompletedStatus] = useState("");
  const [raiseRequest, setRaiseRequest] = useState([]);
  const [countRaiseRequest, setCountRaiseRequest] = useState({});
  const [supportReqStatus, setSupportRequestStatus] = useState([]);

  const [requestModal, showRequestModal] = useState(false);
  const [reqDetails, setRequestDetails] = useState([]);
  const [toggleStatus, setToggleStatus] = useState(0);

  const [packageStatus, setPackageStatus] = useState('');
  const [requestStatus, setRequestStatus] = useState('');

  const [packageStopFields, setPackageStopFileds] = useState(0);
  const [packageStartDate, setPackageStartDate] = useState('');
  const [adminRemarks, setAdminRemarks] = useState('');
  const [supportMessage, setSupportMessage] = useState('');
  const [supportSuccessMessage, setSupportSuccessMessage] = useState('');

  const [packageMobileNumber, setPackageMobileNumber] = useState('');
  const [increaseLeads,setIncreaseLeads]=useState('');

  const sendPackageMobile = async () => {
    setErrorMessage('');
    const errors = {};
    if (!selectedComp) {
      errors.selectedComp = 'Select company first.';
    }

    if (!packageMobileNumber) {
      errors.packageMobileNumber = 'Enter package mobile number.';
    }

    if (packageMobileNumber.length != '10') {
      errors.packageMobileNumber = 'Enter valid mobile number.';
    }

    setValidationErrors(errors);
    if (Object.keys(errors).length > 0) {
      return;
    }
    const formData = new FormData();
    formData.append('mobile_no', packageMobileNumber);
    formData.append('emp_id', emp_id);
    formData.append('client_id', compClientId);
    formData.append('comp_id', selectedComp);
    try {
      const response = await dashboardApi.post('send-otp-mobile', formData);

      if (response.data.status === 200) {
        setShowPackageNumber(1);
        setIsReadOnly(true);
        setErrorMessage('OTP Sent successfully.');
      }
      else {
        setIsReadOnly(false);
        setShowPackageNumber(0);
        setErrorMessage('Something went wrong on sent otp.');
      }
    }
    catch (error) {
      console.error('Error sending OTP to mobile:', error);
      setErrorMessage('Failed to send OTP. Please try again later.');
    }
  };

  const getDateRange = () => {
    const today = new Date();
    const minDate = today.toISOString().split('T')[0]; // Today
    const maxDate = new Date(today.setDate(today.getDate() + 7))
      .toISOString()
      .split('T')[0]; // 7 days from today
    return { minDate, maxDate };
  };
  const { minDate, maxDate } = getDateRange();

  const emptyFormFilds = () => {
    setPackageStartDate('');
    setAdminRemarks('');
    setSupportMessage('');
    setPackageStatus('');
    setRequestStatus('');
    setIncreaseLeads('');
  }

  const submitRequest = async () => {
    const errors = {};
    
    if (reqDetails.support_category_id == 1 && reqDetails.count_stop_package < reqDetails.packageInfo.start_stop_count && requestStatus !=5)
    {
      if (emp_id != reqDetails.exe_id) 
      {
        if(!packageStatus)
        {
          errors.packageStatus = 'Select Package status';
        }

        

        if (packageStatus == 2) {
          if (!packageStartDate) {
            errors.packageStartDate = 'Select package start date.';
          }
        }
      }
    }
    let totalLeads='';
    if(reqDetails.exe_id !=emp_id)
    {
      if(reqDetails.support_category_id == 18 && requestStatus !=5)
      {
        let percent = reqDetails.packageInfo.increase_percent;
        let balanceLead = reqDetails.packageInfo.total_lead - reqDetails.packageInfo.sent_lead;
        let maxOfferLead = Math.round((balanceLead * percent) / 100);
        if(!increaseLeads)
        {
          errors.maxOfferLead = `Enter number of increase leads.`;
        }
    
        if(increaseLeads > maxOfferLead)
        {
          errors.maxOfferLead = `You can't increase more than ${maxOfferLead} leads`;
        }
        totalLeads = Number(reqDetails.packageInfo.due_lead) + Number(increaseLeads);
      }
    }

    if (!adminRemarks) {
      errors.adminRemarks = 'Enter Remarks.';
    }

    if (!requestStatus) {
      errors.requestStatus = 'Select request status.';
    }
    setValidationErrors(errors);
    if (Object.keys(errors).length > 0) {
      return;
    }

    const formData = new FormData();
    formData.append('package_status', packageStatus);
    formData.append('package_start_date', packageStartDate);
    formData.append('admin_remarks', adminRemarks);
    formData.append('request_status', requestStatus);
    formData.append('req_id', reqDetails.id);
    formData.append('package_id', reqDetails.package_id);
    formData.append('support_id', reqDetails.support_service_id);
    formData.append('category_id', reqDetails.support_category_id);
    formData.append('client_id', reqDetails.client_id);
    formData.append('comp_id', reqDetails.comp_id);
    formData.append('emp_id', emp_id);
    formData.append('req_by', reqDetails.exe_id);
    formData.append('stop_count', reqDetails.count_stop_package);
    formData.append('increase_count', reqDetails.company_increase_count);
    formData.append('increase_leads',totalLeads);

    try {
      const response = await dashboardApi.post('save-request-details-data', formData);
      if (response.data.status === 200) {
        emptyFormFilds();
        viewRequestHistory(reqDetails.id);
        setSupportSuccessMessage(response.data.message);
      }
      else {
        setSupportMessage(response.data.message);
      }
    }
    catch (error) {
      setSupportMessage('Failed to update Details. Please try again later. ');
    }
  }



  useEffect(() => {
    if (packageStatus != 1) {
      setPackageStopFileds(1);
    }
    else {
      setPackageStopFileds(0);
    }

  }, [packageStatus]);


  const showFollwupToggle = (status) => {
    setToggleStatus(status);
  }



  const setIncreaseLead =(event)=>
  {
    const errors = {};
    let percent =reqDetails.packageInfo.increase_percent;
    let balanceLead = reqDetails.packageInfo.total_lead - reqDetails.packageInfo.sent_lead;
   // let maxOfferLead = Math.round((balanceLead * percent) / 100);

    let company_increase_count = reqDetails.company_increase_count;
    //let package_increase_count = reqDetails.packageInfo.package_increase_count;
    let due_increase_count = reqDetails.packageInfo.due_increase_count;
    let decrease_factor = reqDetails.packageInfo.decrease_factor;
    
    let calculatePercent =  percent - (decrease_factor * company_increase_count);
    //alert(calculatePercent);
    let maxOfferLead = Math.round((balanceLead * calculatePercent) / 100);

    if(event.target.value < 1)
    {
      errors.maxOfferLead = `Enter minimum 1 lead.`;
    }

    if(event.target.value <= maxOfferLead)
    {
      setIncreaseLeads(event.target.value);
    }
    else
    {
      errors.maxOfferLead = `You can't increase more than ${maxOfferLead} leads`;
    }
    
    setValidationErrors(errors);
    if(Object.keys(errors).length > 0)
    {
      return;
    }
  }

  const closeRequestModal = () => {
    emptyFormFilds();
    showRequestModal(false);
    getGenratedRequestDetails(requestType);
  }

  const handlePageClick = (selected) => {
    setCurrentPage(selected.selected);
  };

  const setActiveStatus = (requestType) => {
    setRequestType(requestType);
    if (requestType === "pending") {
      setPendingStatus("active");
      setNewStatus("");
      setProcessStatus("");
      setCompletedStatus("");
    } else if (requestType === "new") {
      setPendingStatus("");
      setNewStatus("active");
      setProcessStatus("");
      setCompletedStatus("");
    } else if (requestType === "process") {
      setPendingStatus("");
      setNewStatus("");
      setProcessStatus("active");
      setCompletedStatus("");
    }
    else if (requestType === "completed") {
      setPendingStatus("");
      setNewStatus("");
      setProcessStatus("");
      setCompletedStatus("active");
    }
    else {
      setPendingStatus("");
      setNewStatus("active");
      setProcessStatus("");
      setCompletedStatus("");
    }
  }

  useEffect(() => {
    getRequestDetails();
    getGenratedRequestDetails("pending");
  }, [currentPage]);

  const getGenratedRequestDetails = async (reqType) => {
    setLoading(true);
    setActiveStatus(reqType);
    getCountGenratedRequestDetails();
    try {
      const response = await dashboardApi.get(`get-genrated-request-details`, {
        params: {
          emp_id: emp_id,
          request_type: reqType,
          per_page: postPerPage,
          page: currentPage + 1,
        }
      });
      if (response.data.status === 200) {
        setLoading(false);
        const responseData = response.data.data;
        const clientData = responseData.data || responseData;
        setNumber(responseData.last_page);
        setRaiseRequest(clientData);

      }
      else {
        setLoading(false);
        console.error('Error fetching countries:', response.data.message);
      }
    }
    catch (error) {
      setLoading(false);
      console.error('Error fetching countries:', error);
    }
  }

  const getCountGenratedRequestDetails = async () => {
    try {
      const response = await dashboardApi.get(`get-count-genrated-request-details`, {
        params: {
          emp_id: emp_id,
          // request_type: reqType,
        }
      });
      if (response.data.status === 200) {
        const responseData = response.data.data;
        setCountRaiseRequest(responseData);

      }
      else {

        console.error('Error fetching countries:', response.data.message);
      }
    }
    catch (error) {
      console.error('Error fetching countries:', error);
    }
  }


  const handleMobileNumberChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value) && value.length <= 10) {
      setMobileNumber(value);
    }
  };

  const handlePackageMobileNumberChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value) && value.length <= 10) {
      setPackageMobileNumber(value);
    }
  };



  const handleNewMobileNumberChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value) && value.length <= 10) {
      setNewMobileNumber(value);
    }
  };

  const getCompanyDetails = async () => {
    const errors = {};
    if (mobileNumber.length < 10) {
      errors.mobileNumber = 'Enter valid mobile number.';
    }
    setValidationErrors(errors);
    if (Object.keys(errors).length > 0) {
      return;
    }

    try {
      const response = await dashboardApi.get('get-request-company-details', {
        params:
        {
          emp_id: emp_id,
          mobileNumber: mobileNumber,
        }
      });

      if (response.data.status === 200) {
        setCompanyDetails(response.data.data);
        setValidationErrors('');
      }
      else {
        setCompanyDetails([]);
        errors.mobileNumber = response.data.message;
        setValidationErrors(errors);
        return;
      }
    }
    catch (error) {
      console.log(error);
    }
  }

  const getRequestDetails = async () => {
    try {
      const response = await dashboardApi.get(`get-request-service-details`, {
        params: {
          emp_id: emp_id,
        }
      });
      if (response.data.status === 200) {
        setRequestServiceDetails(response.data.data);
      }
      else {
        console.error('Error fetching countries:', response.data.message);
      }
    }
    catch (error) {
      console.error('Error fetching countries:', error);
    }
  }

  useEffect(() => {
    const getRequestCategoryDetails = async () => {
      try {
        const response = await dashboardApi.get(`get-request-category-details`, {
          params: {
            emp_id: emp_id,
            service_id: selectedRequestType,
          }
        });
        if (response.data.status === 200) {
          setRequestCategory(response.data.data);
        }
        else {
          console.error('Error fetching countries:', response.data.message);
        }
      }
      catch (error) {
        console.error('Error fetching countries:', error);
      }
    }
    getRequestCategoryDetails();
  }, [selectedRequestType]);

  const sendStartStopRequest = async () =>
  {
    const errors = {};
    if (!selectedComp)
    {
      errors.selectedComp = 'Select company first.';
    }

    if(!selectedRequestType)
    {
      errors.selectedRequestType = ' Select request type.';
    }

    if (!selectedRequestCategory)
    {
      errors.selectedRequestCategory = ' Select request category.';
    }

    if (!selectedRequestActivePackage)
    {
      errors.selectedRequestActivePackage = 'Select request active package.';
    }

    if (!packageRemark)
    {
      errors.packageRemark = 'Enter remarks.';
    }
    setValidationErrors(errors);
    if (Object.keys(errors).length > 0) {
      return;
    }

    const formData = new FormData();
    formData.append('comp_id', selectedComp);
    formData.append('request_id', selectedRequestType);
    formData.append('category_id', selectedRequestCategory);
    formData.append('package_id', selectedRequestActivePackage);
    formData.append('remarks', packageRemark);
    formData.append('created_by', emp_id);

    try
    {
      const response = await dashboardApi.post('package-active-deactive-request', formData);
      if(response.data.status === 200)
      {
        getGenratedRequestDetails("pending");
        emptyAllFildes();
        Swal.fire({
          icon: 'success',
          text: response.data.message,
        });
      }
      else
      {
        Swal.fire({
          icon: 'error',
          text: response.data.message,
        });
      }
    }
    catch (error)
    {
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while creating the product',
      });
    }
  }

  const sendAppRequest = async () =>
  {
    const errors = {};
    if (!selectedComp)
    {
      errors.selectedComp = 'Select company first.';
    }

    if (!selectedRequestType)
    {
      errors.selectedRequestType = ' Select request type.';
    }

    if (!selectedRequestCategory) {
      errors.selectedRequestCategory = ' Select request category.';
    }

    if (!packageRemark) {
      errors.packageRemark = 'Enter remarks.';
    }
    setValidationErrors(errors);
    if (Object.keys(errors).length > 0) {
      return;
    }

    const formData = new FormData();
    formData.append('comp_id', selectedComp);
    formData.append('request_id', selectedRequestType);
    formData.append('category_id', selectedRequestCategory);
    formData.append('package_id', selectedRequestActivePackage);
    formData.append('remarks', packageRemark);
    formData.append('created_by', emp_id);

    for (let i = 0; i < documentFile.length; i++) {
      formData.append('document_file[]', documentFile[i]);
    }
    try
    {
      const response = await dashboardApi.post('create-app-request', formData);
      if (response.data.status === 200)
      {
        getGenratedRequestDetails("pending");
        emptyAllFildes();
        Swal.fire({
          icon: 'success',
          text: response.data.message,
        });
      }
      else
      {
        Swal.fire({
          icon: 'error',
          text: response.data.message,
        });
      }
    }
    catch (error)
    {
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while creating the product',
      });
    }
  }

  useEffect(() => {
    const getActivePackageDetails = async () => {
      try {
        const response = await dashboardApi.get(`get-sales-request-active-package`, {
          params: {
            emp_id: emp_id,
            comp_id: selectedComp,
            category_id: selectedRequestCategory,
            req_type: selectedRequestType
          }
        });

        if (response.data.status === 200) {
          setRequestActivePackage(response.data.data);
        }
      } catch (error) {
        console.error('Error fetching active package details:', error);
      }
    };

    if(selectedRequestType === '1')
    {
      getActivePackageDetails();
    }
  }, [emp_id, selectedComp, selectedRequestCategory, selectedRequestType]);

  const sendmobile = async () => 
  {
    setErrorMessage('');
    const errors = {};
    if (!selectedComp)
    {
      errors.selectedComp = 'Select company first.';
    }
    setValidationErrors(errors);
    if (Object.keys(errors).length > 0)
    {
      return;
    }
    const formData = new FormData();
    formData.append('mobile_no', compMobileNumber);
    formData.append('emp_id', emp_id);
    formData.append('client_id', compClientId);
    formData.append('comp_id', selectedComp);
    try
    {
      const response = await dashboardApi.post('send-otp-mobile', formData);
      if(response.data.status === 200)
      {
        setShowNewNumber(1);
        setErrorMessage('OTP Sent successfully.');
      }
      else
      {
        setShowNewNumber(0);
        setErrorMessage('Something went wrong on sent otp.');
      }
    }
    catch (error)
    {
      console.error('Error sending OTP to mobile:', error);
      setErrorMessage('Failed to send OTP. Please try again later.');
    }
  };




  const sendOtpEmail = async () => {
    setErrorMessage('');
    const formData = new FormData();
    formData.append('email', compEmailId);
    formData.append('emp_id', emp_id);
    formData.append('client_id', compClientId);
    formData.append('comp_id', selectedComp);

    try {
      const response = await dashboardApi.post('send-otp-email', formData);
      if (response.data.status === 200) {
        setShowNewEmail(true);
        setErrorMessage('OTP sent successfully.');
      }
      else {

        setShowNewEmail(false);
        setErrorMessage(response.data.message);
        setErrorMessage('Something went wrong on sent otp.');
      }
    } catch (error) {
      ;
      console.error('Error sending OTP to email:', error);
      setErrorMessage('Failed to send OTP. Please try again later.');
    }
  };

  const SubmitNewNumber = async (changeType) =>
  {
    setErrorMessage('');
    const errors = {};

    // Validation logic
    if (!selectedComp) {
      errors.selectedComp = 'Please select a company first.';
    }

    if (changeType === 1) {
      if (!otp) {
        errors.mobileNumberOtp = 'Please enter OTP.';
      }

      if (!mobileNewNumber) {
        errors.mobileNewNumber = 'Please enter a new mobile number.';
      } else if (mobileNewNumber.length !== 10) {
        errors.mobileNewNumber = 'Please enter a valid 10-digit mobile number.';
      }
    }

    if (changeType === 3) {
      if (!otp) {
        errors.mobileNumberOtp = 'Please enter OTP.';
      }

      if (!packageMobileNumber) {
        errors.packageMobileNumber = 'Please enter a new mobile number.';
      } else if (packageMobileNumber.length !== 10) {
        errors.packageMobileNumber = 'Please enter a valid 10-digit mobile number.';
      }

      if (!selectedRequestActivePackage) {
        errors.selectedRequestActivePackage = 'Please select an active package.';
      }
    }

    if (changeType === 2) {
      if (!otp) {
        errors.emailIdOtp = 'Please enter OTP.';
      }

      if (!newEmailId) {
        errors.newEmailId = 'Please enter a new email ID.';
      } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(newEmailId)) {
        errors.newEmailId = 'Please enter a valid email address.';
      }
    }

    setValidationErrors(errors);

    if (Object.keys(errors).length > 0) {
      return; // Stop execution if there are validation errors
    }

    // Prepare form data
    const formData = new FormData();
    formData.append('otp', otp);
    formData.append('mobile', mobileNewNumber || packageMobileNumber || '');
    formData.append('email', newEmailId || '');
    formData.append('emp_id', emp_id);
    formData.append('client_id', compClientId);
    formData.append('comp_id', selectedComp);
    formData.append('package_id', selectedRequestActivePackage || '');
    formData.append('request_id', selectedRequestType);
    formData.append('category_id', selectedRequestCategory);

    try {
      let response;
      if (changeType === 3) {
        response = await dashboardApi.post('update-mobile-number-to-package', formData);
      } else {
        response = await dashboardApi.post('update-client-number-email-sales', formData);
      }

      if (response.data.status === 200) {
        emptyAllFildes();
        setErrorMessage(response.data.message);
      } else {
        setErrorMessage(response.data.message || 'Failed to update details. Please try again later.');
      }
    } catch (error) {
      setErrorMessage('Failed to update details. Please try again later.');
    }
  };

  const checkNewPackageNumber = async () =>
  {
    const errors = {};
    if (!packageMobileNumber) {
      errors.packageMobileNumber = 'New mobile number is required.';
    }

    if (packageMobileNumber.length != 10) {
      //setErrorMessage('Enter OTP.');
      errors.packageMobileNumber = 'Invalied mobile no.';
    }
    setValidationErrors(errors);

    if (Object.keys(errors).length > 0) {
      return;
    }

    setIsReadOnly(false);
    setShowPackageNumber(0);
  }


  const checkNewNumber = async () => {
    const errors = {};
    if (!mobileNewNumber) {
      errors.mobileNewNumber = 'New mobile number is required.';
    }

    if (mobileNewNumber.length != 10) {
      //setErrorMessage('Enter OTP.');
      errors.mobileNewNumber = 'Invalied mobile no.';
    }
    setValidationErrors(errors);

    if (Object.keys(errors).length > 0) {
      return;
    }

    try {
      const response = await dashboardApi.get(`check-new-mobile-number`, {
        params: {
          emp_id: emp_id,
          mobileNumber: mobileNewNumber,
          client_id: compClientId,
          comp_id: selectedComp
        }
      });
      if (response.data.status === 200) {
        setIsReadOnly(false);
        setCheckMobileStatus(1);
        setErrorMessage(response.data.message);
      }
      else {
        setIsReadOnly(true);
        setCheckMobileStatus(2);
        setErrorMessage('');
        console.error('Error fetching countries:', response.data.message);
      }
    }
    catch (error) {
      console.error('Error fetching countries:', error);
    }
  }

  const changeNewNumber = () => {
    setIsReadOnly(false);
  }

  const viewRequestHistory = async (id) => {
    try {
      const response = await dashboardApi.get(`get-sales-request-details`, {
        params: {
          emp_id: emp_id,
          r_id: id,
        }
      });

      if (response.data.status === 200) {
        showRequestModal(true);
        setRequestDetails(response.data.data);
        getSupportReqStatus();

      }
      else {
        setIsReadOnly(true);
        setCheckMobileStatus(2);
        setErrorMessage('');
        console.error('Error fetching countries:', response.data.message);
      }
    }
    catch (error) {
      console.error('Error fetching countries:', error);
    }
  }

  const getSupportReqStatus = async (id) => {
    try {
      const response = await dashboardApi.get(`get-support-request-status`, {
        params: {
          emp_id: emp_id,
          req_by: reqDetails.exe_id
        }
      });

      if (response.data.status === 200) {
        setSupportRequestStatus(response.data.data);
      }
      else {
        console.error('Error fetching countries:', response.data.message);
      }
    }
    catch (error) {
      console.error('Error fetching countries:', error);
    }
  }



  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3} pb={3}>
        <Card>
          <div className="card-body p-2">
            {(department == '3' || department == '9') &&
              <div className="d-flex justify-content-between align-items-center">
                <ClientDetailsHeader></ClientDetailsHeader>
              </div>
            }

            <div className="col-sm-12 d-flex " style={{ overflowY: "hidden" }}>
              <div className="p-1">
                <button className={`btn btn-primary small tabss ${pendingStatus}`} style={{ width: "max-content" }} onClick={() => {

                  getGenratedRequestDetails("pending");
                }}>Pending Request
                  <small className="position-relative bg-danger px-1 text-white rounded" style={{ left: "6px", bottom: "6px" }}>{countRaiseRequest.pending_request}</small>
                </button>
              </div>

              <div className="p-1">
                <button className={`btn btn-primary small tabss ${processStatus}`} style={{ width: "max-content" }} onClick={() => {
                  //setActiveStatus("process");
                  getGenratedRequestDetails("process");
                }}>Process Request<small className="position-relative bg-danger px-1 text-white rounded" style={{ left: "6px", bottom: "6px" }}>{countRaiseRequest.process_request}</small></button>
              </div>

              <div className="p-1">
                <button className={`btn btn-primary small tabss ${completedStatus}`} onClick={() => {
                  //setActiveStatus("completed");
                  getGenratedRequestDetails("completed");
                }} style={{ width: "max-content" }}>Completed Request<small className="position-relative bg-danger px-1 text-white rounded" style={{ left: "6px", bottom: "6px" }}>{countRaiseRequest.complete_request}</small></button>
              </div>

              {(department == '3' || department == '9') &&
                <div className="p-1">
                  <button
                    className={`btn btn-primary small tabss ${newStatus}`}
                    onClick={() => {
                      setActiveStatus("new");
                    }}
                    style={{ width: "max-content" }}
                  >
                    Add New Request <span className="btn btn-success btn-sm">+</span>
                  </button>
                </div>
              }
            </div>

            {requestType === 'new' &&
              <>
                <div className='d-flex flex-wrap px-sm-0 px-2'>
                  <div className='col-sm-2 d-flex my-2 flex-column  px-sm-2 px-1'>
                    <small>Mobile No <span style={{ color: 'red', fontSize: '20px' }}>*</span></small>
                    <input
                      type="text"
                      className='form-control'
                      value={mobileNumber}
                      onChange={handleMobileNumberChange}
                    />
                  </div>

                  <div className='col-sm-1 d-flex my-2 flex-column justify-content-end  px-sm-2 px-1'>
                    <span className="btn btn-primary btn-sm" onClick={getCompanyDetails}>Search</span>
                  </div>

                  <div className='col-sm-12'>
                    {validationErrors.mobileNumber && (
                      <small className="text-danger d-block">{validationErrors.mobileNumber}</small>
                    )}
                  </div>
                </div>


                <div className="card-body p-2 client-req-detail">

                  {companyDetails && companyDetails.map((cd, index) => (

                    <label className='d-flex align-items-center border p-2 rounded mb-3 small position-relative overflow-hidden' >
                      <span style={{ color: 'red', fontSize: '20px' }}>*</span>
                      <input
                        type="radio"
                        name="req-list"
                         value={cd.comp_id}
                        onChange={() => setSelectedComp(cd.comp_id)}
                      />
                      <span></span>
                      <div className='d-flex flex-wrap' style={{marginLeft:'15px'}}>
                        <div className='px-1 col-12'>
                          Client Info :- <strong>( {cd.client_name} )</strong> <strong>{cd.business_name}</strong>
                        </div>

                        <div className="col-12 px-1">
                          Contact Info :- <strong>{cd.mobile_no}</strong> , <strong>{cd.email} </strong>
                        </div>

                        <div className="col-sm-4 col-12 px-1">
                          Pan Card :-  <strong>{cd.pan_card}</strong>
                        </div>

                        <div className="col-sm-4 col-12 px-1">
                          <strong>
                            {
                              cd.status == '1' ? <span style={{ color: 'red' }}>Verified</span> :
                                cd.status == '2' ? <span style={{ color: 'green' }}>Active</span> :
                                  cd.status == '3' ? <span style={{ color: 'red' }}>Inactive</span> :
                                    cd.status == '4' ? <span style={{ color: 'red' }}>Expire</span> :
                                      cd.status == '5' ? <span style={{ color: 'red' }}>Blacklist</span> :
                                        cd.status == '6' ? <span style={{ color: 'red' }}>IsDeleted</span> : 'Pending'
                            }
                          </strong>
                        </div>
                        <div className="col-sm-4 col-12 px-1">
                          <strong>{cd.emp_name} </strong>
                        </div>
                      </div>
                    </label>
                  ))}
                  {validationErrors.selectedComp && (
                    <small className="text-danger d-block">{validationErrors.selectedComp}</small>
                  )}
                </div>


                {companyDetails.length > 0 &&
                  <div className="card-body p-2  d-flex flex-wrap" >
                    <div class="col-sm-4 col-12 px-1">
                      <label className='small mb-0 mt-2'>Request Type <strong style={{ color: 'red', fontSize: '20px' }}>*</strong></label>
                      <select className="form-control"
                        value={selectedRequestType}
                        onChange={(e) => setSelectRequestType(e.target.value)}
                      >
                        <option value=''>Select Request</option>
                        {requestServiceDetails && requestServiceDetails.map((req) => (
                          <option key={req.id} value={req.id}>
                            {req.title}
                          </option>
                        ))}
                      </select>
                      {validationErrors.selectedRequestType && (
                        <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.selectedRequestType}</div>
                      )}
                    </div>


                    {selectedRequestType &&
                      <div className="col-sm-4 col-12 px-1">
                        <label className='small mb-0 mt-2'>Request Category <span style={{ color: 'red', fontSize: '20px' }}>*</span></label>
                        <select className="form-control"
                          value={selectedRequestCategory}
                          onChange={(e) => setSelectedRequestCategory(e.target.value)}
                        >
                          <option value=''>Select Category</option>
                          {requestCategory && requestCategory.map((rc) => (
                            <option key={rc.id} value={rc.id}>
                              {rc.title}
                            </option>
                          ))}
                        </select>
                        {validationErrors.selectedRequestCategory && (
                          <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.selectedRequestCategory}</div>
                        )}
                      </div>
                    }

                    {selectedRequestType === '1' && (
                      <div className="col-sm-4 col-12 px-1">
                        <label className='small mb-0 mt-2'>Select Package <span style={{ color: 'red', fontSize: '20px' }}>*</span> </label>

                        <select
                          className="form-control"
                          value={selectedRequestActivePackage}
                          onChange={(e) => setSelectedRequestActivePackage(e.target.value)}
                        >
                          <option value="">Select Package</option>
                          {requestActivePackage &&
                            requestActivePackage.map((rap) => (
                              <option key={rap.package_id} value={rap.package_id}>
                                {rap.package_name}
                                {rap.package_status == 1 ?
                                  <span style={{ color: 'green' }}> (Active)</span>
                                  : rap.package_status == 2 ? <span style={{ color: 'red' }}> (Inactive)</span>
                                    : rap.package_status == 3 ? <span style={{ color: 'red' }}> (Stop by due payment) </span>
                                      : rap.package_status == 4 ? <span style={{ color: 'red' }}> (Expire)</span> : 'Pending'

                                }
                              </option>
                            ))}
                        </select>
                        {validationErrors.selectedRequestActivePackage && (
                          <div className="text-danger" style={{ fontSize: '15px' }}>
                            {validationErrors.selectedRequestActivePackage}
                          </div>
                        )}
                      </div>
                    )}


                    {selectedRequestCategory == '4' && selectedRequestActivePackage && (
                      <div className="col-sm-6 col-12 px-1">

                        <div className="form-check">
                          <br />
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="removeCompetitor"
                            name="competitor"
                            checked={checkboxState.competitor}
                            onChange={handleCheckboxChange}
                          />
                          <label className="form-check-label small mb-0" htmlFor="removeCompetitor">
                            Remove Competitor to increase lead flow if possible  <span style={{ color: 'red', fontSize: '20px' }}>*</span>
                          </label>
                        </div>
                        <div className="form-check">

                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="expandArea"
                            name="area"
                            checked={checkboxState.area}
                            onChange={handleCheckboxChange}
                          />

                          <label className="form-check-label small mb-0" htmlFor="expandArea">
                            Expand the target area to improve lead flow if possible <span style={{ color: 'red', fontSize: '20px' }}>*</span>
                          </label>
                        </div>

                      </div>
                    )}

                    {selectedRequestType === '6' &&
                      <>
                        <div className="col-sm-4 col-12 px-1">
                          <label className='small mb-0 mt-2'>Scrren Short</label>
                          <input className="form-control" type="file" multiple accept="image/*" onChange={handleFileChange} />
                          {validationErrors.validFiles && (
                            <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.validFiles}</div>
                          )}
                        </div>

                        <div className="col-sm-4 col-12 px-1">
                          <label className='small mb-0 mt-2'>Remarks <span style={{ color: 'red', fontSize: '20px' }}>*</span></label>
                          <input
                            type="text"
                            className="form-control"
                            value={packageRemark}
                            onChange={(e) => setPackageRemarks(e.target.value)}
                          />
                          {validationErrors.packageRemark && (
                            <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.packageRemark}</div>
                          )}
                        </div>

                        <div className="col-sm-2 d-flex my-2 flex-column justify-content-end">
                          <button className="btn btn-primary btn-sm" onClick={sendAppRequest}>Send App Request</button>
                        </div>
                      </>
                    }

                    {selectedRequestCategory == 28 &&
                      <>
                        <div className="col-sm-4 col-12 px-1">
                          <label className='small mb-0 mt-2'>Remarks <span style={{ color: 'red', fontSize: '20px' }}>*</span></label>
                          <input
                            type="text"
                            className="form-control"
                            value={packageRemark}
                            onChange={(e) => setPackageRemarks(e.target.value)}
                          />
                          {validationErrors.packageRemark && (
                            <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.packageRemark}</div>
                          )}
                        </div>
                        <div className="col-sm-2 d-flex my-2 flex-column justify-content-end">
                          <button className="btn btn-primary btn-sm" onClick={sendAppRequest}>Send Request</button>
                        </div>
                      </>
                    }

                    {selectedRequestCategory && selectedRequestActivePackage && selectedRequestType != '6' && selectedRequestCategory != 29 &&
                      <>
                        <div className="col-sm-4 col-12 px-1">
                          <label className='small mb-0 mt-2'>Remarks <span style={{ color: 'red', fontSize: '20px' }}>*</span></label>
                          <input
                            type="text"
                            className="form-control"
                            value={packageRemark}
                            onChange={(e) => setPackageRemarks(e.target.value)}
                          />
                          {validationErrors.packageRemark && (
                            <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.packageRemark}</div>
                          )}
                        </div>

                        {selectedRequestCategory != 4 &&
                          <div className="col-sm-2 d-flex my-2 flex-column justify-content-end">
                            <button className="btn btn-primary btn-sm" onClick={sendStartStopRequest}>Send Request</button>
                          </div>
                        }

                        {selectedRequestCategory == 4 && checkboxState.competitor == 1 && checkboxState.area == 1 &&
                          <div className="col-sm-2 d-flex my-2 flex-column justify-content-end">
                            <button className="btn btn-primary btn-sm" onClick={sendStartStopRequest}>Send Request</button>
                          </div>
                        }
                      </>
                    }

                    {selectedRequestType == 1 && selectedRequestCategory == 29 &&
                      <>
                        <div className="col-sm-4 col-12 px-1">
                          <label className='small mb-0 mt-2'>Mobile No</label>
                          <input
                            type="text"
                            className="form-control"
                            value={packageMobileNumber} handleMobileNumberChange
                            onChange={handlePackageMobileNumberChange}
                            readOnly={isReadOnly}
                          />
                          {validationErrors.packageMobileNumber && (
                            <div className="text-danger" style={{ fontSize: '15px' }}>
                              {validationErrors.packageMobileNumber}
                            </div>
                          )}
                        </div>
                        {packageMobileNoStatus != 1 &&
                          <div className="d-flex flex-column justify-content-end col-sm-2">
                            <button type="button" className="btn btn-primary mt-2" onClick={() => sendPackageMobile()}>
                              Send OTP
                            </button>
                          </div>
                        }

                        {isReadOnly && packageMobileNoStatus ==1 &&
                          <div className="d-flex flex-column justify-content-end col-sm-2 " style={{ marginBottom: '1px' }}>
                            <button type="button" className="btn btn-danger mt-2" onClick={() => changeNewNumber()}>
                              Change Number
                            </button>
                          </div>
                        }

                        {packageMobileNoStatus == 1 && isReadOnly == false &&
                          <div className="d-flex flex-column justify-content-end col-sm-2" style={{ marginBottom: '1px' }}>
                            <button type="button" className="btn btn-danger mt-2" onClick={() => checkNewPackageNumber()}>
                              Check Number
                            </button>
                          </div>
                        }

                        {packageMobileNoStatus == 1 && isReadOnly &&
                          <>
                            <div className="d-flex flex-column justify-content-end col-sm-2" style={{ marginBottom: '1px' }}>
                              <label className='small mb-0 mt-2'>Mobile OTP</label>
                              <input type="text" className="form-control" value={otp}
                                onChange={(e) => setOtp(e.target.value)} />
                              {validationErrors.mobileNumberOtp && (
                                <div className="text-danger" style={{ fontSize: '15px' }}>
                                  {validationErrors.mobileNumberOtp}
                                </div>
                              )}
                            </div>



                            {isReadOnly && packageMobileNoStatus == 1 &&
                              <div className="d-flex flex-column justify-content-end col-sm-2" style={{ marginBottom: '1px' }}>
                                <button type="button" className="btn btn-danger mt-2" onClick={() => SubmitNewNumber(3)}>
                                  Save
                                </button>
                              </div>
                            }
                          </>
                        }
                      </>
                    }


                    {selectedRequestType == 7 &&
                      <>
                        {selectedRequestCategory == 25 &&
                          <>
                            <div className="col-sm-4 col-12 px-1">
                              <label className='small mb-0 mt-2'>Old Mobile No</label>
                              <input
                                type="text"
                                className="form-control"
                                value={compMobileNumber}
                                readOnly
                              />
                            </div>
                            {compNewNoStatus != 1 &&
                              <div className="col-sm-4 col-12 px-1">

                                <button type="button" className="btn btn-primary mt-2" onClick={() => sendmobile()}>
                                  Send OTP
                                </button>
                              </div>
                            }

                            {compNewNoStatus == 1 &&
                              <>
                                <div className="d-flex flex-column justify-content-end col-sm-2" style={{ marginBottom: '1px' }}>
                                  <label className='small mb-0 mt-2'>Mobile OTP</label>
                                  <input type="text" className="form-control" value={otp}
                                    onChange={(e) => setOtp(e.target.value)} />
                                  {validationErrors.mobileNumberOtp && (
                                    <div className="text-danger" style={{ fontSize: '15px' }}>
                                      {validationErrors.mobileNumberOtp}
                                    </div>
                                  )}
                                </div>

                                <div className="d-flex flex-column justify-content-end col-sm-2 col-12 px-1">
                                  <label className='small mb-0 mt-2'>New Mobile No</label>
                                  <input
                                    type="text"
                                    className='form-control'
                                    value={mobileNewNumber}
                                    readOnly={isReadOnly}
                                    onChange={handleNewMobileNumberChange}
                                  />
                                  {validationErrors.mobileNewNumber && (
                                    <div className="text-danger" style={{ fontSize: '15px' }}>
                                      {validationErrors.mobileNewNumber}
                                    </div>
                                  )}
                                </div>

                                {compNewNoStatus == 1 && isReadOnly == false &&
                                  <div className="d-flex flex-column justify-content-end col-sm-2" style={{ marginBottom: '1px' }}>
                                    <button type="button" className="btn btn-danger mt-2" onClick={() => checkNewNumber()}>
                                      Check Number
                                    </button>
                                  </div>
                                }

                                {isReadOnly &&
                                  <div className="d-flex flex-column justify-content-end col-sm-2 " style={{ marginBottom: '1px' }}>
                                    <button type="button" className="btn btn-danger mt-2" onClick={() => changeNewNumber()}>
                                      Change Number
                                    </button>
                                  </div>
                                }

                                {isReadOnly && checkMobileStatus == 2 &&
                                  <div className="d-flex flex-column justify-content-end col-sm-2" style={{ marginBottom: '1px' }}>
                                    <button type="button" className="btn btn-danger mt-2" onClick={() => SubmitNewNumber(1)}>
                                      Save
                                    </button>
                                  </div>
                                }

                              </>
                            }
                          </>
                        }

                        {selectedRequestCategory == 26 &&
                          <>
                            <div className="col-sm-4 col-12 px-1">
                              <label className='small mb-0 mt-2'>Old Email Id</label>
                              <input
                                type="text"
                                className="form-control"
                                value={compEmailId}
                                readOnly
                              />
                            </div>

                            {compNewEmailStatus != 1 &&
                              <div className="col-sm-4 col-12 px-1">
                                <button type="button" className="btn btn-primary mt-2" onClick={() => sendOtpEmail()}>
                                  Send OTP
                                </button>
                              </div>
                            }

                            {compNewEmailStatus == 1 &&
                              <>
                                <div className="d-inline-block col-sm-4" style={{ marginBottom: '1px' }}>
                                  <label className='small mb-0 mt-2'>Email OTP</label>
                                  <input type="text" className="form-control" value={otp}
                                    onChange={(e) => setOtp(e.target.value)} />
                                  {validationErrors.emailIdOtp && (
                                    <div className="text-danger" style={{ fontSize: '15px' }}>
                                      {validationErrors.emailIdOtp}
                                    </div>
                                  )}
                                </div>

                                <div className="col-sm-4 col-12 px-1">
                                  <label className='small mb-0 mt-2'> New Email Id</label>
                                  <input
                                    type="text"
                                    className='form-control'
                                    value={newEmailId}
                                    onChange={(e) => setNewEmailId(e.target.value)}
                                  />
                                  {validationErrors.newEmailId && (
                                    <div className="text-danger" style={{ fontSize: '15px' }}>
                                      {validationErrors.newEmailId}
                                    </div>
                                  )}
                                </div>

                                {compNewEmailStatus == 1 &&
                                  <div className="d-flex flex-column justify-content-end col-sm-2" style={{ marginBottom: '1px' }}>
                                    <button type="button" className="btn btn-danger mt-2" onClick={() => SubmitNewNumber(2)}>
                                      Save
                                    </button>
                                  </div>
                                }

                              </>
                            }
                          </>
                        }


                      </>
                    }
                  </div>
                }
                <div className='small mb-0 mt-2 col-sm-12'>
                  {errorMessage && (
                    <span id="Error" style={{ color: 'red' }}>{errorMessage}</span>
                  )}
                </div>
              </>
            }

            {requestType != 'new' &&

              <div>
                {loading ? (
                  <p style={{ align: 'center' }}>
                    <img src={`${process.env.PUBLIC_URL}/newfileloding.gif`} style={{ height: '80px', width: 'auto' }}></img>
                  </p>
                ) : (
                  <div className='mx-2' style={{ overflowY: "hidden" }}>
                    <table className="table table-bordered table-hovered">
                      <tr>
                        <td>S.No</td>
                        <td>Client Name</td>
                        <td>Business Name</td>
                        <td>Product</td>
                        <td>Service Name</td>
                        <td>Req Type</td>
                        <td>Req Category</td>
                        <td>Remarks</td>
                        <td>Created By</td>
                        <td>Assign to</td>
                        <td>Status</td>
                        <td>Created Date</td>
                        <td>Action</td>
                      </tr>

                      {raiseRequest && raiseRequest.map((rr, index) => (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{rr.client_name}</td>
                          <td>{rr.business_name}</td>
                          <td>{rr.product_name}</td>
                          <td>{rr.service_name}</td>
                          <td>{rr.title}</td>
                          <td>{rr.category_title}</td>
                          <td>{rr.complaint}</td>
                          <td>{rr.emp_name}</td>
                          <td>{rr.assign_emp}</td>
                          <td style={{ fontWeight: '700' }}>
                            {
                              (rr.status == 1 || rr.status == 0) ? <span style={{ color: 'red' }}>Pending</span> :
                                rr.status == 2 ? <span style={{ color: '#007bff' }}>Process</span> :
                                  rr.status == 3 ? <span style={{ color: 'green' }}>Completed</span> :
                                    rr.status == 4 ? <span style={{ color: 'green' }}>Confirm</span> :
                                      rr.status == 5 ? <span style={{ color: 'red' }}>Rejected</span> :''
                            }
                          </td>
                          <td>{rr.created_at}</td>
                          <td>

                            <button className="btn btn-sm btn-success" onClick={() => viewRequestHistory(rr.id)}>View</button>

                          </td>
                        </tr>
                      ))}
                    </table>

                    <Pagination className="custom-pagination">
                      <ReactPaginate
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={number}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination"}
                        activeClassName={"active"}
                        pageLinkClassName={"page-link-style"}
                        breakLinkClassName={"break-link-style"}
                        previousLinkClassName={"prev-link-style"}
                        nextLinkClassName={"next-link-style"}
                      />
                    </Pagination>
                  </div>
                )}
              </div>
            }

          </div>
        </Card>
      </MDBox>
      <Footer />

      <Dialog open={requestModal} fullWidth PaperProps={{ style: { maxWidth: '80%', padding: '20px' } }}>
        <DialogTitle>Request Details</DialogTitle>
        <DialogContent style={{ height: 'auto' }}>
          <div className="row">
            <div className="col-sm-6">
              Client Name :- <b>{reqDetails.client_name}</b> <br />
              Business Name :- <b>{reqDetails.business_name}</b> <br />
              Package Name :- <b>{reqDetails.package_name}</b> <br />
              Package Status :- <b style={{ color: 'red' }}>

                {reqDetails.package_status == 0 ? <span style={{ color: 'red' }}>Pending</span>
                  : reqDetails.package_status == 1 ? <span style={{ color: 'green' }}>Active</span>
                    : reqDetails.package_status == 2 ? <span style={{ color: 'red' }}>Stop By admin</span>
                      : reqDetails.package_status == 3 ? <span style={{ color: 'red' }}>Stop By Finance</span>
                        : reqDetails.package_status == 4 ? <span style={{ color: 'green' }}>Expire</span>
                          :
                          ''
                }
              </b> <br />

              Created By :- <b style={{ color: 'red' }}>{reqDetails.emp_name}</b> <br />
              Assign to :- <b style={{ color: 'red' }}>{reqDetails.assign_emp}</b> <br />
              Created Date :- <b style={{ color: 'red' }}>{reqDetails.created_at}</b> <br />
            </div>

            <div className="col-sm-6">
              Request Type :- <b>{reqDetails.title}</b> <br />
              Request Category :- <b>{reqDetails.category_title}</b> <br />
              Remarks :- <b style={{color:'red'}}>{reqDetails.complaint}</b> <br />
              Status :- <b>{reqDetails.status == 1 ? <span style={{ color: 'red' }}>Pending</span>
                : reqDetails.status == 2 ? <span style={{ color: 'red' }}>Process</span>
                  : reqDetails.status == 3 ? <span style={{ color: 'green' }}>Completed</span>
                    : reqDetails.status == 4 ? <span style={{ color: 'green' }}>Confirm By {reqDetails.emp_name}</span>
                      :reqDetails.status == 5 ? <span style={{ color: 'green' }}>Rejected </span>: ''
              }</b>
              <br />
            </div>

            <div className="col-sm-12">
              <table className="table table-bordered table-hovered">
                {reqDetails.status != 4 && reqDetails.status != 5 &&
                  <tr>
                    <td style={{ textAlign: "end" }} colspan="5">
                      {toggleStatus == 0 &&
                        <button className="btn btn-sm btn-primary" onClick={() => showFollwupToggle(1)}>+</button>
                      }

                      {toggleStatus == 1 &&
                        <button className="btn btn-sm btn-primary" onClick={() => showFollwupToggle(0)}>-</button>
                      }
                    </td>
                  </tr>
                }

                

                {toggleStatus == 1 && reqDetails.status != 4 && reqDetails.status !=5 &&
                  <>
                    <tr>
                      <td colspan="5">
                        <div className="row">

                          {reqDetails.support_category_id ==22 &&
                            <div className="col-sm-12 d-flex flex-wrap">
                              <Link className="btn btn-primary btn-sm" to="/add-return-renewal" target='_blank'>Create return lead package
                              </Link>
                            </div>
                          }

                         
                          {reqDetails.support_category_id == 18 &&
                            <>
                            <div className="col-12 d-flex flex-wrap">
                              <label className='small mb-0 col-sm-3 col-12 border p-1'>Total Lead :<b> {reqDetails.packageInfo.total_lead}</b></label>
                              <label className='small mb-0 col-sm-3 col-12 border p-1'>Sent Lead : <b>{reqDetails.packageInfo.sent_lead}</b></label>
                              <label className='small mb-0 col-sm-3 col-12 border p-1'>Due Lead : <b>{reqDetails.packageInfo.due_lead}</b></label>
                              <label className='small mb-0 col-sm-3 col-12 border p-1'>Due Amount :<b>{reqDetails.packageInfo.due_amount},</b></label>
                              <label className='small mb-0 col-sm-3 col-12 border p-1'>Due Date :<b>{reqDetails.packageInfo.due_date}</b></label>
                              <label className='small mb-0 col-sm-3 col-12 border p-1'>Pkg start date :<b>{reqDetails.packageInfo.package_start_date}</b></label>
                              <label className='small mb-0 col-sm-3 col-12 border p-1'>Pkg End Expected date :<b>{reqDetails.packageInfo.package_end_expected_date}</b></label>
                              <label className='small mb-0 col-sm-3 col-12 border p-1'>Last Lead Sent Date :<b>{reqDetails.packageInfo.last_lead_sent_date}</b></label>
                            </div>
                            
                            </>
                          }

                          {reqDetails.company_increase_count >= reqDetails.packageInfo.due_increase_count &&
                          
                          <span className="col-sm-12" style={{color:'red'}}> 
                            Company due lead increase limits have been exceeded; you cannot increase due lead for this company.
                          </span>
                              
                          }
                          
                          {reqDetails.package_increase_count > 0 &&
                            
                            <span className="col-sm-12" style={{color:'red'}}> Package due lead increase limits have been exceeded; you cannot increase due lead for this package
                            </span>
                          }
                         

                          <div className="col-sm-2">
                            <label className='small mb-0 mt-2'>Request Status  <span style={{ color: 'red', fontSize: '20px' }}>*</span></label>
                            <select className="form-control" value={requestStatus} onChange={(e) => setRequestStatus(e.target.value)}>
                              <option value="">Select Request Status</option>
                              {supportReqStatus && supportReqStatus.map((srs) => (
                                <option value={srs.id}>{srs.title}</option>
                              ))}
                            </select>
                            {validationErrors.requestStatus && (
                              <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.requestStatus}</div>
                            )}
                          </div>

                          

                          {
                            (reqDetails.exe_id !== emp_id) &&
                            reqDetails.support_category_id === 18 &&
                            requestStatus !== 5 &&
                            reqDetails.company_increase_count < reqDetails.packageInfo.due_increase_count &&
                            reqDetails.package_increase_count < 1 && (
                              <div className="col-sm-2">
                                <label className="small mb-0 mt-2">
                                  Increased Lead <span style={{ color: 'red', fontSize: '20px' }}>*</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={increaseLeads}
                                  onChange={setIncreaseLead}
                                />
                                {validationErrors.maxOfferLead && (
                                  <div className="text-danger" style={{ fontSize: '15px' }}>
                                    {validationErrors.maxOfferLead}
                                  </div>
                                )}
                              </div>
                            )
                          }

                          {reqDetails.support_service_id == 1 && requestStatus !=5 &&
                            <>
                              {reqDetails.support_category_id == 1 &&
                                <>
                                  {emp_id != reqDetails.exe_id && reqDetails.count_stop_package < reqDetails.packageInfo.start_stop_count &&
                                    <>

                                      


                                      <div className="col-sm-2">
                                        <label className='small mb-0 mt-2'>Package Status <span style={{ color: 'red', fontSize: '20px' }}>*</span></label>
                                        <select className="form-control" value={packageStatus} onChange={(e) => setPackageStatus(e.target.value)}>
                                          <option value="">Select Package Status</option>
                                          <option value="1">Active</option>
                                          <option value="2">InActive</option>
                                        </select>
                                      </div>

                                      {packageStopFields == 1 &&
                                        <>
                                          {packageStatus == 2 &&
                                            <div className="col-sm-2">
                                              <label className='small mb-0 mt-2'>Package Start Date <span style={{ color: 'red', fontSize: '20px' }}>*</span></label>
                                              <input
                                                type="date"
                                                className="form-control"
                                                value={packageStartDate}
                                                min={minDate}
                                                max={maxDate}
                                                onChange={(e) => setPackageStartDate(e.target.value)}
                                              />
                                              {validationErrors.packageStartDate && (
                                                <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.packageStartDate}</div>
                                              )}
                                            </div>
                                          }
                                        </>
                                      }
                                    </>
                                  }
                                  <>
                                  {reqDetails.count_stop_package >= reqDetails.packageInfo.start_stop_count &&
                                    <span className="col-sm-12" style={{color:'red'}}>The package's start and stop limits have been exceeded; you cannot deactivate this package now</span>
                                  }
                                  </>
                                </>
                              }
                              
                            </>
                          }

                          <div className="col-sm-4">
                            <label className='small mb-0 mt-2'>Remarks <span style={{ color: 'red', fontSize: '20px' }}>*</span></label>

                            <input type="text" className="form-control" value={adminRemarks} onChange={(e) => setAdminRemarks(e.target.value)} />

                            {validationErrors.adminRemarks && (
                              <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.adminRemarks}</div>
                            )}
                          </div>

                          <div className="col-sm-2 d-flex flex-column justify-content-end">
                            <button className="btn btn-primary btn-sm" onClick={submitRequest}>Submit</button>
                          </div>
                        </div>
                      </td>
                    </tr>

                    {reqDetails.media_file &&
                      <tr>
                        <td colspan="2">
                          <div className='d-flex d-flex justify-content-between p-3'>
                            <span>Media, Link and Docs</span>
                            <strong className='border p-1 px-2 rounded'>{reqDetails.media_file.split(',').length} Items</strong>
                          </div>
                          <div className='d-flex' style={{ overflowY: 'hidden' }}>
                            {reqDetails.media_file && reqDetails.media_file.split(',').map((item) =>
                              <div className="col-sm-4 mb-2">
                                <a href={'https://workforce.r-ims.com/' + item} target='_blank' className="d-flex bg-primary p-2 rounded">
                                  <img className='rounded' src={'https://workforce.r-ims.com/' + item} alt="Example Image" title="document" width="100%" style={{ maxHeight: '250px' }} />
                                </a>
                              </div>
                            )}
                          </div>
                        </td>
                      </tr>
                    }
                  </>
                }

                {reqDetails.req_history && (
                  <>
                    <tr style={{ fontWeight: '700' }}>

                      <td>Chats</td>

                    </tr>
                    {reqDetails.req_history.map((rh, index) => (
                      <tr key={index}>
                        <td>
                          <b>
                            {rh.followup_by == 1 ? <span style={{ color: 'red' }}>Vendor</span> : rh.followup_by == 2 ? <span style={{ color: '#0962c2' }}> Sales</span> : rh.followup_by == 3 ? <span style={{ color: 'green' }}> Admin</span> : 'Others'}
                          </b>
                          <i><b style={{ marginRight: '30px' }}> ({rh.followup_by_emp})</b></i>
                          <i style={{ marginRight: '30px' }}> {rh.created_at}</i>
                          <i style={{ marginRight: '30px' }}> 
                            {rh.status==1?
                            <span style={{color:'red'}}>Pending</span>:
                            rh.status==2?<span style={{color:'red'}}>Process</span>:
                            rh.status==3?<span style={{color:'green'}}>Completed</span>:
                            rh.status==4?<span style={{color:'green'}}>Confirm</span>:
                            rh.status==5?<span style={{color:'red'}}>Rejected</span>:''
                          }
                          </i>
                          <br />
                          <label className='small mb-0 mt-2'>{rh.remarks}</label>
                        </td>
                      </tr>
                    ))}
                  </>
                )}
              </table>
            </div>
          </div>
        </DialogContent>

        <DialogActions className='d-flex align-items-center justify-content-between'>
          <div>
            <label className='small mb-0 mt-2' style={{ color: 'red' }}>{supportMessage}</label>
            <label className='small mb-0 mt-2' style={{ color: 'green' }}>{supportSuccessMessage}</label>
          </div>
          <Link className="btn btn-info btn-sm" onClick={closeRequestModal}>
            Close
          </Link>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
}
export default ClientRequestDetails;
