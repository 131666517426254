import { useState, useEffect } from "react";
import secureLocalStorage from "react-secure-storage";
import Pusher from "pusher-js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import dashboardApi from "layouts/common_components/apibase_url";

function RequestNotification() {
  const emp_id = secureLocalStorage.getItem("emp_id");
  const navigate = useNavigate();

  const handleViewClick = async (message, type) => {
    if (type === "new-sales-request" || type === "package-status-request") {
      try {
        const response = await dashboardApi.post("update-request-notification-seen-status", {
          emp_id: emp_id,
          type: type,
        });

        if (response.data.status === 200) {
          navigate("/client-request-details");
        } else {
          console.error("Error updating seen status:", response.data.error);
        }
      } catch (error) {
        console.error("Error updating seen status:", error);
      }
    }
  };

  const CustomCloseButton = ({ toastId }) => (
    <button
      onClick={() => toast.dismiss(toastId)} // Explicitly dismiss the toast using its ID
      style={{
        background: "none",
        border: "none",
        color: "#fff",
        fontSize: "16px",
        cursor: "pointer",
      }}
    >
      ✖
    </button>
  );

  useEffect(() => {
    const pusher = new Pusher("8b30b9768db844760f19", {
      cluster: "ap2",
      encrypted: true,
    });

    const channel = pusher.subscribe(`sales-request-event-${emp_id}`);

    channel.bind("sales-request-event", (data) => {
      // Declare the toast ID variable first
      const toastId = toast.success(
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <span>{data.message}</span>
        </div>,
        {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false,
          hideProgressBar: false,
          closeOnClick: false, // Prevent auto-close on content click
          pauseOnHover: false,
          draggable: false,
          style: { background: "green", color: "white", width: "700px" },
          closeButton: <CustomCloseButton toastId={data.toastId} />, // Use the current toast ID
          onClick: () => handleViewClick(data.message, data.type),
        }
      );
    });

    return () => {
      pusher.unsubscribe(`sales-request-event-${emp_id}`);
      pusher.disconnect();
    };
  }, [emp_id]);

  return <ToastContainer />;
}

export default RequestNotification;
