import React, { useState, useEffect, useRef } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Link, Navigate } from 'react-router-dom';
import { Bar } from 'react-chartjs-2';
import MDBox from 'components/MDBox';
import DashboardLayout from 'theme_layout/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'theme_layout/Navbars/DashboardNavbar';
import Footer from 'theme_layout/Footer';
import ComplexStatisticsCard from 'theme_layout/Cards/StatisticsCards/ComplexStatisticsCard';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './dashboard-main-page.css';
import dashboardApi from 'layouts/common_components/apibase_url';
import reportsLineChartData from 'layouts/dashboard/data/reportsLineChartData';
import secureLocalStorage from 'react-secure-storage';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
//import { Bar } from 'react-chartjs-2';
//import totalcollection from '../assets/images/totalcollection.png';

const SalesDashboard = () => {
  const navigate = useNavigate();
  const { sales } = reportsLineChartData;
  const [productlist, setProductlist] = useState([]);
  const empId = secureLocalStorage.getItem('emp_id');
  const dept_id = secureLocalStorage.getItem('department_id');
  const desi_id = secureLocalStorage.getItem('designation_id');
  const [product, setproduct] = useState('');
  const [servicelist, setServicelist] = useState([]);
  const [service, setservice] = useState('');
  const [start_date, setstart_date] = useState('');
  const [end_date, setend_date] = useState('');
  const [sales_data, setSalesData] = useState({});
  const [grouplist, setgrouplist] = useState([]);
  const [group, setgroup] = useState('');
  const [emp_list, getemp_list] = useState([]);
  const [selectedmanagers, setselectedmanagers] = useState();
  const [salesdashboard, get_salesdashboard] = useState({});
  const [manager_team, get_manager_team] = useState([]);
  const [checkmanager, setcheckmanager] = useState('');
  const [selectedemployee, setselectedemployee] = useState('');
  const [sales_comperison_data, get_sales_compersion_data] = useState([]);
  const [compersionFilter, setCompersionFilter] = useState(false);
  const [bardata, setbardata] = useState([]);
  const [percent, setpercent] = useState();
  const [zerodays, getzerodays] = useState();
  const [business_lead, get_business_lead] = useState([]);
  const [sales_missing_followup, set_sales_missing_followup] = useState();
  const [sales_missing_kra_kpi, set_sales_missing_kra_kpi] = useState();
  const [openmodal, setopenmodal] = useState(false);
  const [sales_manager_lit, get_sales_manager_list] = useState([]);
  const [id, setid] = useState('');
  const [selectedmanagerid, setselectedmanagerid] = useState('');
  const [ValidationErrors, setValidationErrors] = useState({});
  const [is_reporting_manager, set_is_reporting_manager] = useState({});
  const [assigned_member, set_assigned_team_member] = useState([]);
  const [enq_info_data, get_enq_info_data] = useState({})
  const [category_list, set_category_list] = useState([]);
  const [category, selectedcategory] = useState('');
  const [followup_count, set_followup_count] = useState([]);
  //alert(is_reporting_manager)
  //console.log(assigned_member);


  //console.log(compersion_filter);

  useEffect(() => {
    productdetails();
    fetchdata();
    groupdetails();
    get_emp_list();
    get_dashboard_data();
    get_sales_manager_team();
    bar_chart_data();
    kra_and_kpi_percent();
    business_lead_data();
    kra_and_kpi_calculate();
    notification();
    get_manager_team_details();
    check_reporting_manager()
    get_followup_data();
  }, []);

  useEffect(() => {
    if (dept_id == 9)
      get_sales_manager();

  }, [dept_id == 9])

  useEffect(() => {
    get_followup_data_filter();

  }, [product, service, group, selectedmanagers, selectedemployee, start_date, end_date, category]);


  useEffect(() => {
    business_lead_data_filter();

  }, [product, service, group, start_date, end_date, category]);

  const get_manager_team_details = async () => {
    try {
      const response = await dashboardApi.get(`get-all-manager-employee/${empId}`)

      if (response.data.status === 200) {
        get_sales_manager_list(response.data.data)
      } else {
        console.error('Error fetching data:', response.data.error);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }

  }

  const businessLeadRef = useRef(null);

  const scrollToBusinessLead = () => {
    businessLeadRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const check_reporting_manager = async () => {
    try {
      const response = await dashboardApi.get(`check-reporting-manager/${empId}`)
      //console.log(response);

      if (response.data.status == 200) {
        set_is_reporting_manager(response.data.data)
      } else {
        console.error('Error fetching data:', response.data.error);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }

  }

  const get_followup_data = async () => {
    try {
      const response = await dashboardApi.get('get-monthly-payment-followups', {
        params: {
          emp_id: empId,
          start_date: start_date,
          end_date: end_date,
          product: product,
          service: service,
          employee: selectedemployee,
          group: group,
          category: category,
          manager: selectedmanagers,
        },
      });
      if (response.data.status === 200) {
        const responseData = response.data.data;
        set_followup_count(responseData);
      } else {
        console.error('Error fetching data:', response.data.error);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


  const get_followup_data_filter = async () => {
    try {
      const response = await dashboardApi.get('get-monthly-payment-followups', {
        params: {
          emp_id: empId,
          start_date: start_date,
          end_date: end_date,
          product: product,
          service: service,
          employee: selectedemployee,
          group: group,
          category: category,
          manager: selectedmanagers,

        },
      });
      if (response.data.status === 200) {
        const responseData = response.data.data;
        set_followup_count(responseData);
      } else {
        console.error('Error fetching data:', response.data.error);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };










  const closepopup = () => {
    setopenmodal(false);
    setselectedmanagerid('');

  }






  useEffect(() => {
    fetchlist();
  }, [product, end_date, start_date, service]);

  useEffect(() => {
    get_sales_dashboard_filter();
  }, [product, service, category, group, selectedmanagers, selectedemployee, start_date, end_date]);

  useEffect(() => {
    compersion_data();

  }, [product, service, category, group, selectedmanagers, selectedemployee, start_date, end_date]);

  useEffect(() => {
    bar_chart_data_filter();

  }, [product, service, category, group, selectedmanagers, selectedemployee, start_date, end_date])

  useEffect(() => {
    kra_and_kpi_show_percent_filter();

  }, [product, service, group, selectedmanagers, selectedemployee, start_date, end_date]);
 useEffect(()=>{
  count_sales_followup();

 },[product, service, group, selectedmanagers, selectedemployee, start_date, end_date]);






  const assignview = async (id) => {
    setid(id);
    setopenmodal(true);
    try {
      const endpoint = `assigned-member-on-business-lead/${id}`;
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
        set_assigned_team_member(response.data.data);
      } else {
        console.error('Error fetching data:', response.data.error);
      }

    } catch (error) {
      console.error('Error fetching data:', error);
    }



  }

  const assign_business_lead_to_manager = async () => {
    const errors = {};
    if (!selectedmanagerid) {
      errors.selectedmanagerid = 'Please Select Any Team Member';

    }

    setValidationErrors(errors);
    if (Object.keys(errors).length === 0) {
      const formData = new FormData();
      formData.append('id', id);
      formData.append('manager_id', selectedmanagerid);
      try {
        const response = await dashboardApi.post('assigned-business-lead-to-manager', formData);
        if (response.data.status === 200) {
          Swal.fire({
            icon: 'success',
            text: response.data.message,
          });
          setopenmodal(false);

        }
        else {
          Swal.fire({
            icon: 'error',
            text: response.data.message || 'An error occurred',
          });
        }
      } catch (error) {
        Swal.fire({
          icon: 'error',
          text: 'An error occurred while creating',
        });
      } finally {
        //setIsSubmitting(false);
      }

    }

  }

  useEffect(() => {
    if (service) {
      const fetchData = async () => {
        try {
          const endpoint = `get-category-based-on-service-id/${service}`;
          const response = await dashboardApi.get(endpoint);

          if (response.data.status === 200) {
            set_category_list(response.data.data);
          } else {
            console.error('Error fetching data:', response.data.message);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchData();
    }
  }, [service]);



  const prepareChartData = () => {

    const labels = bardata.map(item => item.month);
    const data = bardata.map(item => item.total_collection);
    const get_year = bardata.map(item => item.year);



    return {
      labels: labels,
      datasets: [
        {
          label: 'Monthly Sales',
          data: data,
          backgroundColor: 'green',
          borderColor: 'rgba(75, 192, 192, 1)',
          borderWidth: 1,
        },
      ],
    }
  }


  const fetchlist = async () => {
    try {
      const response = await dashboardApi.get('count-sales-data', {
        params: {
          emp_id: empId,
          start_date: start_date,
          end_date: end_date,
          product: product,
          category: service,
          employee: selectedemployee
        },
      });
      if (response.data.status === 200) {
        const responseData = response.data.data;
        setSalesData(responseData);
      } else {
        console.error('Error fetching data:', response.data.error);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const compersion_data = async () => {
    if (compersionFilter) {
      try {
        const response = await dashboardApi.get('sales-dashboard-data', {
          params: {
            emp_id: empId,
            start_date: start_date,
            end_date: end_date,
            product: product,
            category: category,
            service: service,
            employee: selectedemployee,
            group: group,
            manager: selectedmanagers,
          },
        });
        if (response.data.status === 200) {
          const responseData = response.data.data;
          get_sales_compersion_data(responseData);
        } else {
          console.error('Error fetching data:', response.data.error);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  };

  const handleCheckboxChange = (checked) => {
    setCompersionFilter(checked);
  };

  const bar_chart_data = async () => {
    try {
      const response = await dashboardApi.get('get-total-collection', {
        params: {
          emp_id: empId,
          start_date: start_date,
          end_date: end_date,
          product: product,
          category: category,
          service: service,
          employee: selectedemployee,
          group: group,
          manager: selectedmanagers,
        },
      });
      if (response.data.status === 200) {
        const responseData = response.data.data;
        setbardata(responseData);
      } else {
        console.error('Error fetching data:', response.data.error);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const get_sales_manager = async () => {
    try {
      const response = await dashboardApi.get('get-sales-managers')

      if (response.data.status === 200) {
        get_sales_manager_list(response.data.data)
      } else {
        console.error('Error fetching data:', response.data.error);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


  const kra_and_kpi_percent = async () => {
    try {
      const response = await dashboardApi.get('show-kra-and-kpi-sales-dashboard', {
        params: {
          emp_id: empId,
          start_date: start_date,
          end_date: end_date,
          product: product,
          category: service,
          employee: selectedemployee,
          group: group,
          manager: selectedmanagers,
        },
      });
      if (response.data.status === 200) {
        const responseData = response.data.data;
        setpercent(responseData);
      } else {
        console.error('Error fetching data:', response.data.error);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const kra_and_kpi_show_percent_filter = async () => {
    try {
      const response = await dashboardApi.get('show-kra-and-kpi-sales-dashboard', {
        params: {
          emp_id: empId,
          start_date: start_date,
          end_date: end_date,
          product: product,
          category: service,
          employee: selectedemployee,
          group: group,
          manager: selectedmanagers,
        },
      });
      if (response.data.status === 200) {
        const responseData = response.data.data;
        setpercent(responseData);
      } else {
        console.error('Error fetching data:', response.data.error);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const bar_chart_data_filter = async () => {
    try {
      const response = await dashboardApi.get('get-total-collection', {
        params: {
          emp_id: empId,
          start_date: start_date,
          end_date: end_date,
          product: product,
          category: category,
          service: service,
          employee: selectedemployee,
          group: group,
          manager: selectedmanagers,
        },
      });
      if (response.data.status === 200) {
        const responseData = response.data.data;
        setbardata(responseData);
      } else {
        console.error('Error fetching data:', response.data.error);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const get_sales_dashboard_filter = async () => {
    if (!compersionFilter) {
      try {
        const response = await dashboardApi.get('sales-dashboard-data', {
          params: {
            emp_id: empId,
            start_date: start_date,
            end_date: end_date,
            product: product,
            category: category,
            service: service,
            group: group,
            manager: selectedmanagers,
            employee: selectedemployee
          },
        });
        if (response.data.status === 200) {
          const responseData = response.data.data;
          get_salesdashboard(responseData);
        } else {
          console.error('Error fetching data:', response.data.error);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  };

  const productdetails = async () => {
    try {
      const endpoint = 'get-product-list';
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
        setProductlist(response.data.data);
      } else {
        console.error('Error fetching data:', response.data.error);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };




  const kra_and_kpi_calculate = async () => {
    try {
      const endpoint = 'count-sales-kra-kpi';
      const response = await dashboardApi.get(endpoint);
    } catch (error) {
      console.error('Error fetching data:', error);
    }

  }

  const business_lead_data = async () => {
    try {
      const endpoint = `show-business-lead-data/${empId}`;

      const response = await dashboardApi.get(endpoint, {
        params: {
          start_date: start_date,
          end_date: end_date,
          product: product,
          category: category,
          service: service,
          group: group,
        },
      });

      if (response.data.status === 200) {
        get_business_lead(response.data.data);
      } else {
        console.error('Error fetching data:', response.data.error);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const business_lead_data_filter = async () => {
    try {
      const endpoint = `show-business-lead-data/${empId}`;

      const response = await dashboardApi.get(endpoint, {
        params: {
          start_date: start_date,
          end_date: end_date,
          product: product,
          category: category,
          service: service,
          group: group,
        },
      });

      if (response.data.status === 200) {
        get_business_lead(response.data.data);
      } else {
        console.error('Error fetching data:', response.data.error);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const get_emp_list = async () => {
    try {
      const endpoint = 'get-sales-managers';
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
        getemp_list(response.data.data);
      } else {
        console.error('Error fetching data:', response.data.error);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const groupdetails = async () => {
    try {
      const endpoint = `get-group-based-on-sales-emp/${empId}`;
      const response = await dashboardApi.get(endpoint);

      if (response.data.status == 200) {
        setgrouplist(response.data.data);
      } else {
        console.error('Error fetching data:', response.data.error);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const get_dashboard_data = async () => {
    try {
      const response = await dashboardApi.get('sales-dashboard-data', {
        params: {
          emp_id: empId,
        },
      });
      if (response.data.status === 200) {
        const responseData = response.data.data;
        get_salesdashboard(responseData);
      } else {
        console.error('Error fetching data:', response.data.error);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const get_sales_manager_team = async () => {
    try {
      const endpoint = `sales-dashboard-show-manager-team/${empId}`;
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
        get_manager_team(response.data.data);
        setcheckmanager(response.data.reporting_manager);
      } else {
        console.error('Error fetching data:', response.data.error);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }

  }

  const count_sales_followup = async () => {
    try {
      const response = await dashboardApi.get('count-sales-followup', {
        params: {
            emp_id: empId,
            start_date: start_date,
            end_date: end_date,
            product: product,
            category: category,
            service: service,
            group: group,
            manager: selectedmanagers,
            employee: selectedemployee
          },
      });
      if (response.data.status === 200) {
        response.data.followup_data;
        set_sales_missing_followup(response.data.missing_followup);
        //set_sales_missing_kra_kpi(response.data.missing_kra_kpi)
      } else {
        console.error('Error fetching data:', response.data.error);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const notification = async () => {
    try {
      const endpoint = `send-sales-notification/${empId}`;
      const response = await dashboardApi.get(endpoint);
    } catch (error) {
      console.error('Error fetching data:', error);
    }

  }

  const business_lead_popup = async (id) => {
    try {
      const endpoint = `get-business-lead-status/${id}`;
      const response = await dashboardApi.get(endpoint);
      if (response.data.status == 200) {
        navigate(`/add-followup-details/${encodeURIComponent('business_lead')}/${id}`);
      }
      else {
        Swal.fire({
          icon: 'error',
          text: response.data.message,
        });

      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }


  }

  const followupdatacounta = () => {

    const labels = followup_count.map(item => item.month);
    const data = followup_count.map(item => item.total_payment_followup);
    const get_year = followup_count.map(item => item.year);

    //console.log(labels);

    return {
      labels: labels,
      datasets: [
        {
          label: 'Monthly payment Followup',
          data: data,
          backgroundColor: 'blue',
          borderColor: 'rgba(75, 192, 192, 1)',
          borderWidth: 1,
        },
      ],
    }
  }





  const upcoming = salesdashboard.renew !== undefined ? salesdashboard.renew : '0';
  const new_sale = salesdashboard.new_sale !== undefined ? salesdashboard.new_sale : '0';
  const renew_sale = salesdashboard.renew_sale !== undefined ? salesdashboard.renew_sale : '0';
  const upcoming_renewal = salesdashboard.renew !== undefined ? salesdashboard.renew : '0';
  const upcoming_renewal_amount = salesdashboard.renewal_amount !== undefined ? salesdashboard.renewal_amount : '0';
  const accuracy_percent = salesdashboard.accuracy_percent !== undefined ? salesdashboard.accuracy_percent : '0';
  const dead = salesdashboard.dead !== undefined ? salesdashboard.dead : '0';
  const kra_kpi_total = salesdashboard.missing_target !== undefined ? salesdashboard.missing_target : '0';
  const isMissing = kra_kpi_total > 0;
  const style = {
    color: 'white',
    border: '3px solid',
    backgroundColor: isMissing ? 'red' : 'green',
    fontSize: '14px'
  };

  const upcoming_compersion = sales_comperison_data.renew !== undefined ? sales_comperison_data.renew : '0';
  const new_sale_compersion = sales_comperison_data.new_sale !== undefined ? sales_comperison_data.new_sale : '0';
  const renew_sale_compersion = sales_comperison_data.renew_sale !== undefined ? sales_comperison_data.renew_sale : '0';
  const upcoming_renewal_compersion = sales_comperison_data.renew !== undefined ? sales_comperison_data.renew : '0';
  const upcoming_renewal_amount_compersion = sales_comperison_data.renewal_amount !== undefined ? sales_comperison_data.renewal_amount : '0';
  const accuracy_percent_compersion = sales_comperison_data.accuracy_percent !== undefined ? sales_comperison_data.accuracy_percent : '0';
  const dead_compersion = sales_comperison_data.dead !== undefined ? sales_comperison_data.dead : '0';





  useEffect(() => {
    if (product) {
      const fetchData = async () => {
        try {
          const endpoint = `get-service-list-by-product-id/${product}`;
          const response = await dashboardApi.get(endpoint);

          if (response.data.status === 200) {
            setServicelist(response.data.data);
          } else {
            console.error('Error fetching data:', response.data.message);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchData();
    }
  }, [product]);

  const fetchdata = async () => {
    try {
      const response = await dashboardApi.get('count-sales-data', {
        params: {
          emp_id: empId,
          start_date: start_date,
          end_date: end_date,
          product: product,
          category: service,
        },
      });
      if (response.data.status === 200) {
        const responseData = response.data.data;
        setSalesData(responseData);
      } else {
        console.error('Error fetching data:', response.data.error);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  //alert();


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        <div>
          <div className="d-flex flex-wrap">
            <div className="col-sm-3 col-6 p-1">
              <select
                className="form-control form-select"
                value={group}
                onChange={(e) => setgroup(e.target.value)}
                aria-label="Default select example"
                style={{ width: '100%', padding: '8px', fontSize: '11px' }}
              >
                <option>Select Group</option>
                {grouplist.map((row) => (
                  <option key={row.group_id} value={row.group_id}>
                    {row.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="col-sm-3 col-6 p-1">
              <select
                className="form-control form-select"
                value={product}
                onChange={(e) => setproduct(e.target.value)}
                aria-label="Default select example"
                style={{ width: '100%', padding: '8px', fontSize: '11px' }}
              >
                <option>Select Product</option>
                {productlist.map((row) => (
                  <option key={row.id} value={row.id}>
                    {row.product_name}
                  </option>
                ))}
              </select>
            </div>

            <div className="col-sm-3 col-6 p-1">
              <select
                className="form-control form-select"
                value={service}
                onChange={(e) => setservice(e.target.value)}
                aria-label="Default select example"
                style={{ width: '100%', padding: '8px', fontSize: '11px' }}
              >
                <option value="">Select Service</option>
                {servicelist.map((ser) => (
                  <option key={ser.id} value={ser.id}>
                    {ser.service_name}
                  </option>
                ))}
              </select>
            </div>

            <div className="col-sm-3 col-6 p-1">
              <select
                className="form-control form-select"
                value={category}
                onChange={(e) => selectedcategory(e.target.value)}
                aria-label="Default select example"
                style={{ width: '100%', padding: '8px', fontSize: '11px' }}
              >
                <option value="">Select Category</option>
                {category_list.map((row) => (
                  <option key={row.id} value={row.id}>
                    {row.category_name}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-sm-3 col-6 p-1">
              <input
                type="date"
                value={start_date}
                onChange={(e) => setstart_date(e.target.value)}
                className="form-control"
                style={{ width: '100%', padding: '8px', fontSize: '11px' }}
              />
            </div>
            <div className="col-sm-3 col-6 p-1">
              <input
                type="date"
                value={end_date}
                onChange={(e) => setend_date(e.target.value)}
                className="form-control"
                style={{ width: '100%', padding: '8px', fontSize: '11px' }}
              />
            </div>
            {empId == 'RIMS1' &&
              <div className="col-sm-3 col-6 p-1">
                <select
                  className="form-control form-select"
                  value={selectedmanagers}
                  onChange={(e) => setselectedmanagers(e.target.value)}
                  aria-label="Default select example"
                  style={{ width: '100%', padding: '8px', fontSize: '11px' }}
                >
                  <option>Select Managers</option>
                  {emp_list.map((row) => (
                    <option key={row.emp_id} value={row.emp_id}>
                      {row.emp_fname} {row.emp_lame}
                    </option>
                  ))}
                </select>
              </div>
            }
            {checkmanager == 1 &&
              <div className="col-sm-3 col-6 p-1">
                <select
                  className="form-control form-select"
                  value={selectedemployee}
                  onChange={(e) => setselectedemployee(e.target.value)}
                  aria-label="Default select example"
                  style={{ width: '100%', padding: '8px', fontSize: '11px' }}
                >
                  <option>Select Employee</option>
                  {manager_team.map((row) => (
                    <option key={row.emp_id} value={row.emp_id}>
                      {row.emp_fname} {row.emp_lame}
                    </option>
                  ))}
                </select>
              </div>
            }

          </div>
        </div>
        <div className='d-flex flex-wrap my-3'>
          <div className='col-sm-4 col-12 px-sm-2 px-0 py-1'>
            <span className='btn btn-success w-100' style={{ fontSize: '12px' }} >Today Extra KRA And Kpi : {salesdashboard.extra_followup}</span>
          </div>

          <div className='col-sm-4 col-12 px-sm-2 px-0 py-1'>
            <span className='btn btn-success w-100' style={{ fontSize: '12px' }} >Today KRA & KPI Complete Percent : {percent}%</span>
          </div>

          <div className='col-sm-4 col-12 px-sm-2 px-0 py-1'>
            <span className='btn btn-success w-100' style={{ fontSize: '12px' }} >Today Missing Followup : {sales_missing_followup}</span>
          </div>

          <div className='col-sm-4 col-12 px-sm-2 px-0 py-1'>
            <span className='btn btn-danger w-100' style={{ fontSize: '12px' }} onClick={scrollToBusinessLead}>Business Leads : {salesdashboard.business_lead_count}</span>
          </div>
          <div className='col-sm-4 col-12 px-sm-2 px-0 py-1'>
            <Link to='/missing-kra-kpi' style={{ fontSize: '12px' }} className='btn btn-danger w-100 fs-6'>Missing KRA And Kpi(Payment Followup) : {salesdashboard.missing_target}</Link>
          </div>
        </div>

        <div className="d-flex flex-wrap">
          <div className="col-sm-7 col-12 d-flex flex-wrap align-content-start">
            <div className='d-flex flex-wrap align-items-center justify-content-between col-12 p-0'>
              <div className='d-flex  align-items-center px-sm-1 my-2'>
                <input
                  type="checkbox"
                  checked={compersionFilter}
                  onChange={(e) => handleCheckboxChange(e.target.checked)}
                  id='fghfgn'
                />
                <label for='fghfgn' className='m-0 mx-2' style={{ fontSize: '14px' }} >
                  Apply Compersion Filter</label>
              </div>
            </div>
            <div className='col-sm-4 col-6  p-1'>
              <Link to='/lead-sale-price'>
                <div className="card align-items-center px-2 py-3 h-100 justify-content-center" >
                  <img src={`${process.env.PUBLIC_URL}/sales_dashboard/leadsale.png`} style={{ height: '60px' }} alt="group loss" />
                  <div class="text-center mt-3">
                    <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>Lead Sale Price</h5>
                    <h5 class="card-title m-0" style={{ fontSize: '12px', color: 'black' }}>{salesdashboard.lead_sale_price}</h5>
                  </div>
                </div>
              </Link>

            </div>

            <div className='col-sm-4 col-6  p-1'>
              <Link className='h-100'
                to={`/group-loss/${encodeURIComponent('group-loss')}`}
                style={{ textDecoration: 'none' }}
              >
                <div className="card align-items-center px-2 py-3 h-100 justify-content-center" >
                  <img src={`${process.env.PUBLIC_URL}/sales_dashboard/grouploss.png`} style={{ height: '60px' }} alt="group loss" />
                  <div class="text-center mt-3">
                    <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>Group Loss/Category loss</h5>
                    <h5 class="card-title m-0" style={{ fontSize: '12px', color: 'black' }}>{salesdashboard.group_loss}</h5>
                  </div>
                </div>
              </Link>

            </div>

            <div className='col-sm-4 col-6  p-1'>
              <Link className='h-100'
                to = '/buffer-amount'
                style={{ textDecoration: 'none' }}
              >
                <div className="card align-items-center px-2 py-3 h-100 justify-content-center" >
                  <img src={`${process.env.PUBLIC_URL}/sales_dashboard/fiat-money.png`} style={{ height: '60px' }} alt="group loss" />
                  <div class="text-center mt-3">
                    <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>Buffer Amount</h5>
                    <h5 class="card-title m-0" style={{ fontSize: '12px', color: 'black' }}>{salesdashboard.buffer_amount}</h5>
                  </div>
                </div>
              </Link>

            </div>
          {empId == 'RIMS1' && 
            <div className='col-sm-4 col-6  p-1'>
              <Link className='h-100'
               to='/total-collection'
                style={{ textDecoration: 'none' }}
              >
                <div className="card align-items-center px-2 py-3 h-100 justify-content-center" >
                  <img src={`${process.env.PUBLIC_URL}/sales_dashboard/totalcollection.png`} style={{ height: '60px' }} alt="group loss" />

                  <div class="text-center mt-3">
                    <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>Total Collection</h5>
                    <h5 class="card-title m-0" style={{ fontSize: '12px', color: 'black' }}>{salesdashboard.collection}</h5>
                    {
                      compersionFilter && (
                        <h5 class="card-title m-0" style={{ fontSize: '12px', color: 'black' }}> ({start_date}-{end_date}) ={sales_comperison_data.collection}</h5>
                      )}
                  </div>
                </div>
              </Link>

            </div>
     }
 
          {dept_id == 3 && 
            <div className='col-sm-4 col-6  p-1'>
              <Link className='h-100'
               to='/team-collection'
                style={{ textDecoration: 'none' }}
              >
                <div className="card align-items-center px-2 py-3 h-100 justify-content-center" >
                  <img src={`${process.env.PUBLIC_URL}/sales_dashboard/totalcollection.png`} style={{ height: '60px' }} alt="group loss" />

                  <div class="text-center mt-3">
                    <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>Total Collection</h5>
                    <h5 class="card-title m-0" style={{ fontSize: '12px', color: 'black' }}>{salesdashboard.collection}</h5>
                    {
                      compersionFilter && (
                        <h5 class="card-title m-0" style={{ fontSize: '12px', color: 'black' }}> ({start_date}-{end_date}) ={sales_comperison_data.collection}</h5>
                      )}
                  </div>
                </div>
              </Link>

            </div>
     }

            <div className='col-sm-4 col-6  p-1'>
              <Link className='h-100'
                to='/upcoming-renewal-data'
                style={{ textDecoration: 'none' }}
              >
                <div className="card align-items-center px-2 py-3 h-100 justify-content-center" >
                  <img src={`${process.env.PUBLIC_URL}/sales_dashboard/renewal.png`} style={{ height: '60px' }} alt="group loss" />
                  <div class="text-center mt-3">
                    <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>Upcoming Renewal/Amount</h5>
                    <h5 class="card-title m-0" style={{ fontSize: '12px', color: 'black' }}>{`${upcoming_renewal} / ${upcoming_renewal_amount}`}</h5>
                    {
                      compersionFilter &&
                      <h5 class="card-title m-0" style={{ fontSize: '12px', color: 'black' }}> ({start_date}-{end_date}) ={`${upcoming_renewal_compersion} / ${upcoming_renewal_amount_compersion}`}</h5>

                    }
                  </div>
                </div>
              </Link>

            </div>

            <div className='col-sm-4 col-6  p-1'>
              <Link className='h-100'
                to='/lead-sale'
                style={{ textDecoration: 'none' }}
              >
                <div className="card align-items-center px-2 py-3 h-100 justify-content-center" >
                  <img src={`${process.env.PUBLIC_URL}/sales_dashboard/leadsale.png`} style={{ height: '60px' }} alt="group loss" />
                  <div class="text-center mt-3">
                    <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>Total Lead/Lead Sale</h5>
                    <h5 class="card-title m-0" style={{ fontSize: '12px', color: 'black' }}>{salesdashboard.total_lead}/{salesdashboard.lead_sale}</h5>
                  </div>
                </div>
              </Link>

            </div>

            <div className='col-sm-4 col-6  p-1'>
              <Link className='h-100'
                to={`/upcoming-renewal-details/${encodeURIComponent('PackagePendingActivation')}`}
                style={{ textDecoration: 'none' }}
              >
                <div className="card align-items-center px-2 py-3 h-100 justify-content-center" >
                  <img src={`${process.env.PUBLIC_URL}/sales_dashboard/packagepending.png`} style={{ height: '60px' }} alt="group loss" />
                  <div class="text-center mt-3">
                    <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>Package Pending Activation</h5>
                    <h5 class="card-title m-0" style={{ fontSize: '12px', color: 'black' }}>{salesdashboard.package_activation}</h5>
                    {
                      compersionFilter &&
                      <h5 class="card-title m-0" style={{ fontSize: '12px', color: 'black' }}> ({start_date}-{end_date}) ={sales_comperison_data.package_activation}</h5>
                    }
                  </div>
                </div>
              </Link>

            </div>

            <div className='col-sm-4 col-6  p-1'>
              <Link className='h-100'
                to={`/sales-description/${encodeURIComponent('NewRenew')}`}
                style={{ textDecoration: 'none' }}
              >
                <div className="card align-items-center px-2 py-3 h-100 justify-content-center" >
                  <img src={`${process.env.PUBLIC_URL}/sales_dashboard/renewal.png`} style={{ height: '60px' }} alt="group loss" />
                  <div class="text-center mt-3">
                    <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>New/Renew Sales</h5>
                    <h5 class="card-title m-0" style={{ fontSize: '12px', color: 'black' }}>{`${new_sale} / ${renew_sale}`}</h5>
                    {
                      compersionFilter &&
                      <h5 class="card-title m-0" style={{ fontSize: '12px', color: 'black' }}> ({start_date}-{end_date}) ={`${new_sale_compersion} / ${renew_sale_compersion}`}</h5>

                    }
                  </div>
                </div>
              </Link>


            </div>

            <div className='col-sm-4 col-6  p-1'>
              <div className="card align-items-center px-2 py-3 h-100 justify-content-center" >
                <img src={`${process.env.PUBLIC_URL}/sales_dashboard/incentivefactors.png`} style={{ height: '60px' }} alt="group loss" />
                <div class="text-center mt-3">
                  <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>Incentive Factors</h5>
                  <h5 class="card-title m-0" style={{ fontSize: '12px', color: 'black' }}>Increase Group Sale And Lead Sale Price</h5>
                </div>
              </div>
            </div>

            <div className='col-sm-4 col-6  p-1'>
              <Link className='h-100'
                to={{
                  pathname: "/sales-funnel-details",
                }}
                style={{ textDecoration: 'none' }}
              >

                <div className="card align-items-center px-2 py-3 h-100 justify-content-center" >
                  <img src={`${process.env.PUBLIC_URL}/sales_dashboard/forcast.png`} style={{ height: '60px' }} alt="group loss" />
                  <div class="text-center mt-3">
                    <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>Total Follow Ups/Forcast Accuracy</h5>
                    <h5 class="card-title m-0" style={{ fontSize: '12px', color: 'black' }}>{salesdashboard.no_of_followup}/{salesdashboard.accuracy_percent}</h5>
                  </div>
                </div>
              </Link>

            </div>

            <div className='col-sm-4 col-6  p-1'>
              <Link className='h-100'
                to='/due-amount'
                style={{ textDecoration: 'none' }}
              >
                <div className="card align-items-center px-2 py-3 h-100 justify-content-center" >
                  <img src={`${process.env.PUBLIC_URL}/sales_dashboard/due.png`} style={{ height: '60px' }} alt="group loss" />
                  <div class="text-center mt-3">
                    <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>Due Amount</h5>
                    <h5 class="card-title m-0" style={{ fontSize: '12px', color: 'black' }}>{salesdashboard.due_amount}</h5>
                    {
                      compersionFilter && (
                        <h5 className="card-title" style={{ fontSize: '12px', color: 'black' }}>
                          ({start_date}-{end_date}) = {sales_comperison_data.due_amount}
                        </h5>
                      )
                    }
                  </div>
                </div>
              </Link>

            </div>

            <div className='col-sm-4 col-6  p-1'>
              <Link className='h-100'
                to={`/regular-client/${encodeURIComponent('regularClient')}`}
                style={{ textDecoration: 'none' }}
              >
                <div className="card align-items-center px-2 py-3 h-100 justify-content-center" >
                  <img src={`${process.env.PUBLIC_URL}/sales_dashboard/regular_client.png`} style={{ height: '60px' }} alt="group loss" />
                  <div class="text-center mt-3">
                    <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>Regular Client</h5>
                    <h5 class="card-title m-0" style={{ fontSize: '12px', color: 'black' }}>{salesdashboard.active_client}</h5>
                  </div>
                </div>
              </Link>

            </div>

            <div className='col-sm-4 col-6  p-1'>
              <Link className='h-100'
                to={`/regular-client/${encodeURIComponent('regularClientDead')}`}
                style={{ textDecoration: 'none' }}
              >
                <div className="card align-items-center px-2 py-3 h-100 justify-content-center" >
                  <img src={`${process.env.PUBLIC_URL}/sales_dashboard/regular_client_dead.png`} style={{ height: '60px' }} alt="group loss" />
                  <div class="text-center mt-3">
                    <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>Regular Client Dead</h5>
                    <h5 class="card-title m-0" style={{ fontSize: '12px', color: 'black' }}>{salesdashboard.regular_client_dead}</h5>
                  </div>
                </div>
              </Link>

            </div>

            <div className='col-sm-4 col-6  p-1'>
              <div className="card align-items-center px-2 py-3 h-100 justify-content-center" >
                <img src={`${process.env.PUBLIC_URL}/sales_dashboard/only_today.png`} style={{ height: '60px' }} alt="group loss" />
                <div class="text-center mt-3">
                  <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>Collection/Followups</h5>
                  <h5 class="card-title m-0" style={{ fontSize: '12px', color: 'black' }}>{salesdashboard.today_collection}/{salesdashboard.followup_sum}</h5>
                </div>
              </div>
            </div>
            { desi_id == 38 &&
            <div className='col-sm-4 col-6  p-1'>
              <Link className='h-100'
                to='/sales-lead'
              >
                <div className="card align-items-center px-2 py-3 h-100 justify-content-center" >
                  <img src={`${process.env.PUBLIC_URL}/sales_dashboard/organization.png`} style={{ height: '60px' }} alt="group loss" />
                  <div class="text-center mt-3">
                    <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>Company Target</h5>
                    <h5 class="card-title m-0" style={{ fontSize: '12px', color: 'black' }}></h5>
                  </div>
                </div>
              </Link>
          </div>
         }

      { desi_id != 38 &&
            <div className='col-sm-4 col-6  p-1'>
              <Link className='h-100'
                to='/assign-target-list-sales'
              >
                <div className="card align-items-center px-2 py-3 h-100 justify-content-center" >
                  <img src={`${process.env.PUBLIC_URL}/sales_dashboard/organization.png`} style={{ height: '60px' }} alt="group loss" />
                  <div class="text-center mt-3">
                    <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>Company Target</h5>
                    <h5 class="card-title m-0" style={{ fontSize: '12px', color: 'black' }}></h5>
                  </div>
                </div>
              </Link>
          </div>
         }

            <div className='col-sm-4 col-6  p-1'>
              <Link className='h-100'
                to='/enquiry-data'
              >
                <div className="card align-items-center px-2 py-3 h-100 justify-content-center" >
                  <img src={`${process.env.PUBLIC_URL}/sales_dashboard/not-found.png`} style={{ height: '60px' }} alt="group loss" />
                  <div class="text-center mt-3">
                    <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>Client Not Found/Insufficient Clients Enquiry</h5>
                    <h5 class="card-title m-0" style={{ fontSize: '12px', color: 'black' }}>{salesdashboard.client_not_found}</h5>
                  </div>
                </div>
              </Link>
            </div>

            <div className='col-sm-4 col-6  p-1'>
              <Link className='h-100'
                to='/active-clients-list'
              >
                <div className="card align-items-center px-2 py-3 h-100 justify-content-center" >
                  <img src={`${process.env.PUBLIC_URL}/sales_dashboard/client.png`} style={{ height: '60px' }} alt="group loss" />
                  <div class="text-center mt-3">
                    <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>Active Clients</h5>
                    <h5 class="card-title m-0" style={{ fontSize: '12px', color: 'black' }}>{salesdashboard.total_active_clients}</h5>
                  </div>
                </div>
              </Link>



            </div>

            <div className='col-sm-4 col-6  p-1'>
              <Link className='h-100'
                to='/active-packages-list'
              >
                <div className="card align-items-center px-2 py-3 h-100 justify-content-center" >
                  <img src={`${process.env.PUBLIC_URL}/sales_dashboard/box.png`} style={{ height: '60px' }} alt="group loss" />
                  <div class="text-center mt-3">
                    <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>Active Packages</h5>
                    <h5 class="card-title m-0" style={{ fontSize: '12px', color: 'black' }}>{salesdashboard.total_active_packages}</h5>
                  </div>
                </div>
              </Link>
            </div>

            <div className='col-sm-4 col-6  p-1'>
              <Link className='h-100'
                to='/stop-packages'
              >
                <div className="card align-items-center px-2 py-3 h-100 justify-content-center" >
                  <img src={`${process.env.PUBLIC_URL}/sales_dashboard/stop.png`} style={{ height: '60px' }} alt="group loss" />
                  <div class="text-center mt-3">
                    <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>Stop Packages</h5>
                    <h5 class="card-title m-0" style={{ fontSize: '12px', color: 'black' }}>{salesdashboard.stop_packages}</h5>
                  </div>
                </div>
              </Link>
            </div>
            <div className='col-sm-4 col-6  p-1'>
              <Link className='h-100'
                to='/lead-not-sent'
              >
                <div className="card align-items-center px-2 py-3 h-100 justify-content-center" >
                  <img src={`${process.env.PUBLIC_URL}/sales_dashboard/not-sent.png`} style={{ height: '60px' }} alt="group loss" />
                  <div class="text-center mt-3">
                    <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>Lead Not Sent(More Than Two Days)</h5>
                    <h5 class="card-title m-0" style={{ fontSize: '12px', color: 'black' }}>{salesdashboard.lead_not_sent_packages}</h5>
                  </div>
                </div>
              </Link>
            </div>

            <div className="col-12 px-sm-3 px-0">
              <Bar
                data={prepareChartData()}
                options={{
                  scales: {
                    y: {
                      beginAtZero: true,
                    },
                  },
                }}
              />

            </div>

            <div class="col-12 px-sm-3 px-0">
              <div class="chart-container">

                <Bar
                  data={followupdatacounta()}
                  options={{
                    scales: {
                      y: {
                        beginAtZero: true,
                      },
                    },
                  }}
                />
              </div>
            </div>

          </div>
          <div className='col-sm-5 col-12 p-0'>
            <h4 className='mb-2'>Business Leads</h4>
            <div className="col-12 p-0" ref={businessLeadRef} id="business-lead" style={{ overflowY: 'hidden' }}>
              <table className="table table-bordered display nowrap myTable mt-1">
                <tr>
                  <td>Group</td>
                  <td>Category</td>
                  <td>Business</td>
                  <td>Action</td>
                </tr>
                {business_lead.map((row, index) => (
                  <tr>
                    <td>{row.group}</td>
                    <td>{row.category}</td>
                    <td>{row.business}</td>
                    {
                      dept_id != 9 && is_reporting_manager == 'Yes' &&
                      <td className='d-flex'>
                        <Link className="btn btn-info btn-sm" style={{ fontSize: '12px' }} onClick={() => business_lead_popup(row.id)} >Action</Link>
                        <Link className="btn btn-info btn-sm" style={{ fontSize: '12px' }} onClick={() => assignview(row.id)} >Assign</Link>
                      </td>
                    }

                    {
                      dept_id != 9 && is_reporting_manager == 'No' &&
                      <td className='d-flex'>
                        <Link className="btn btn-info btn-sm" style={{ fontSize: '12px' }} onClick={() => business_lead_popup(row.id)} >Action</Link>
                      </td>
                    }


                    {
                      row.exists == 'No' && dept_id == 9 &&
                      <td className='d-flex'>
                        <Link className="btn btn-info btn-sm" style={{ fontSize: '12px' }} onClick={() => business_lead_popup(row.id)} >Action</Link>
                        <Link className="btn btn-info btn-sm" style={{ fontSize: '12px' }} onClick={() => assignview(row.id)} >Assign</Link></td>
                    }

                  </tr>
                ))}


              </table>

            </div>
          </div>
        </div>



      </MDBox>
      <MDBox mt={4.5} />
      <Footer />
      <Dialog
        open={openmodal}
        //onClose={closeModalHandler}
        fullWidth
        PaperProps={{ style: { maxWidth: 'xl', padding: '40px', height: '400px' } }}
      >
        <DialogTitle>Assign</DialogTitle>
        <DialogContent>
          <form>
            <div className='container-fluid'>
              <div className="row">
                <div className="form-group col-md-12">
                  <label htmlFor="short name" style={{ fontSize: '16px', height: '20px' }}>Team</label>
                  <select
                    className="form-control form-select"
                    value={selectedmanagerid}
                    onChange={(e) => setselectedmanagerid(e.target.value)}
                    style={{ width: '100%', padding: '8px', fontSize: '11px' }}
                  >
                    <option>Select Team</option>
                    {sales_manager_lit.map((row) => (
                      <option key={row.emp_id} value={row.emp_id}>
                        {row.emp_fname} {row.emp_lame}
                      </option>
                    ))}
                  </select>
                  {ValidationErrors.selectedmangerid && (
                    <small className="text-danger d-block">{ValidationErrors.selectedmangerid}</small>
                  )}

                </div>
              </div>
              <h6 style={{ color: 'red' }}>Assigned Member : {assigned_member}</h6>
            </div>
          </form>
        </DialogContent>
        <DialogActions>
          <button className="btn btn-danger" onClick={closepopup} >
            Close
          </button>
          <button className="btn btn-info" onClick={assign_business_lead_to_manager} >
            Save
          </button>
        </DialogActions>
      </Dialog>
    </DashboardLayout >
  );
}

export default SalesDashboard;