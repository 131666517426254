import React, { useState, useEffect } from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import dashboardApi from "layouts/common_components/apibase_url";
import { useNavigate } from 'react-router-dom';
import secureLocalStorage from "react-secure-storage";
import Pagination from 'react-bootstrap/Pagination';
import ReactPaginate from 'react-paginate';
import './filter.css';

function TargetDetails() {
  const navigate = useNavigate();
  const empId = secureLocalStorage.getItem('emp_id');
  const token = secureLocalStorage.getItem('token');
  const [targetList, settargetlist] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [number, setNumber] = useState(1); 
  const [loading,setLoading] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [grouplist,setgrouplist] = useState([]);
  const [group,setgroup] = useState('');
  const [categorylist,setcategorylist] = useState([]);
  const [category,setcategory] = useState('');
  const [selectedDepartment, setSelectedDepartment] = useState('');
  const [attribute,setattribute] = useState([]);
  const [selectedAttributes, setSelectedAttributes] = useState([]);
  const [financialYears, setFinancialYears] = useState([]);
  const [financial_year,set_financialyear] = useState('');
  const [postPerPage] = useState(5);
  const [showAttributeDropdown, setShowAttributeDropdown] = useState(false); 
  const [attributefilter,setattributefilter] = useState([]);
  const [valuefilter,setvaluefilter] = useState({});
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [showGroupDropdown, setShowGroupDropdown] = useState(false);
  const [groupfilter,setgroupfilter] = useState([]);
  const [groupvaluefilter,setgroupvaluefilter] = useState({});
  const [goalattribute,setgoalattribute] = useState([]);
  const [goal,setselectedgoal] = useState('');
  const [goalsubattribute,setgoalsubattribute] = useState([]);
  const [selectedgoalsubattribute,setselectedgoalsubattribute] = useState('');
  const [goalsubsubattribute,setgoalsubsubattribute] = useState([]);
  const [selectedgoalsubsubattribute,setselectedgoalsubsubattribute] = useState('');
  const [child_attribute,setchildattribute] = useState([]);
  const [selectedchild_attribute,setselectedchildattribute] = useState('');
  const [service,getservice] = useState([]);
  const [selectedservice,setselectedservice] = useState('');
   const [productlist,setproductlist] = useState([]);
  const [product,setproduct] = useState('');
  
  useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }
  }, [token, navigate]);

  useEffect(() => {
    fetchStock();
    fetchattributelist();
    groupdetails();
  },[])

  useEffect(() => {
    const getCurrentFinancialYear = () => {
      const today = new Date();
      const currentMonth = today.getMonth() + 1;
      const currentYear = today.getFullYear();

      const financialYearStart = currentMonth >= 4 ? currentYear : currentYear - 1;
      const financialYearEnd = financialYearStart + 1;

      return `${financialYearStart}-${financialYearEnd}`;
    };

    const generateFinancialYears = () => {
      const currentFinancialYear = getCurrentFinancialYear();
      const nextFinancialYears = [];

      for (let i = 1; i <= 5; i++) {
        const startYear = parseInt(currentFinancialYear.split('-')[0]) + i;
        const endYear = startYear + 1;
        nextFinancialYears.push(`${startYear}-${endYear}`);
      }
      const allFinancialYears = [currentFinancialYear, ...nextFinancialYears];
      setFinancialYears(allFinancialYears);
    };

    generateFinancialYears();
  }, []);
  useEffect(()=>{
     if(selectedgoalsubsubattribute){
      setselectedchildattribute('');

     }

  },[selectedgoalsubsubattribute])

  //console.log(attributefilter);

  // useEffect(() => {
  //   if (selectedDepartment || category || group || selectedAttributes || financial_year) {
  //     const fetchtarget = async (page = 0) => {
  //         try {
  //             const response = await dashboardApi.get('target-details', {
  //                 params: {
  //                     per_page: postPerPage,
  //                     page: page + 1,
  //                     department: selectedDepartment,
  //                     category: category,
  //                     group: group,
  //                     attribute:selectedAttributes,
  //                     financial_year:financial_year,
  //                 }
  //             });
  //             if (response.data.status === 200) {
  //                 const responseData = response.data.data;
  //                 const stockdata = responseData.data || responseData;
  //                  //console.log(stockdata[0]?.attributes || []);
  //                   if (!selectedAttributes.length) {
  //                      setattributefilter(stockdata[0]?.attributes ?? []);
  //                      setvaluefilter(stockdata[0]?.values ?? [])
  //                   } else {
  //                    setattributefilter(stockdata[0]?.attributes);
  //                    setvaluefilter(stockdata[0]?.values)
  //                    //console.log(stockdata[0]?.values);

  //                   }

  //                   if (!group.length) {
  //                      setgroupfilter(stockdata[0]?.group ?? []);
  //                      setgroupvaluefilter(stockdata[0]?.group_data ?? [])
  //                   } else {
  //                    setgroupfilter(stockdata[0]?.group);
  //                    setgroupvaluefilter(stockdata[0]?.group_data)
  //                    //console.log(stockdata[0]?.group_data);

  //                   }
  //                 // setattributefilter(stockdata[0]?.attribute || []);
  //                 // setvaluefilter(stockdata[0].values);
  //                 settargetlist(stockdata);
  //                 setNumber(response.data.last_page);
  //             } else {
  //                 console.error('Error fetching data:', response.data.error);
  //             }
  //             setLoading(false);
  //         } catch (error) {
  //             console.error('Error fetching data:', error);
  //             setLoading(false);
  //         }
  //     };
  //     fetchtarget();
  //   }
  // }, [selectedDepartment, category, group,selectedAttributes,financial_year]);
  useEffect(() => {
    fetchTarget(currentPage);
}, [goal, selectedgoalsubattribute,product,financial_year, selectedgoalsubsubattribute, category,selectedchild_attribute,selectedDepartment, currentPage,group]);


  const fetchTarget = async (page = 0) => {
   //alert();
    try {
        setLoading(true);
        const response = await dashboardApi.get('get-target-save-data', {
            params: {
                per_page: postPerPage,
                page: page + 1, // Adjust page number (1-based index)
                goal: goal,
                subattribute: selectedgoalsubattribute,
                subsubattribute: selectedgoalsubsubattribute,
                childattribute: selectedchild_attribute,
                department:selectedDepartment,
                financial_year:financial_year,
                category:category,
                product:product,
                group:group,
                //emp_id:empId

            }
        });
       // console.log(response);
      //  if(response.data.sum){
      //   settotal(response.data.sum);
      //  }
        if (response.data.status === 200) {
            const responseData = response.data.data;
            const stockdata = responseData.data || responseData;
             if (!selectedchild_attribute.length) {
                   setattributefilter(stockdata[0]?.attribute_ids ?? []);
                   setvaluefilter(stockdata[0]?.attribute_values ?? [])
                    //const attributeIds = stockdata[0]?.attribute_ids;
                    console.log(stockdata)

                } else {
                 setattributefilter(stockdata[0]?.attribute_ids);
                 setvaluefilter(stockdata[0]?.attribute_values)
                // const attributeIds = stockdata[0]?.attribute_ids[0];
                 console.log(stockdata);

                }
            settargetlist(stockdata);
            setNumber(response.data.last_page);
            setLoading(false);

        } else {
            console.error('Error fetching data:', response.data.error);
            setLoading(false);
        }
    } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
    }
};
//console.log(valuefilter);

  const fetchStock = async (page = 0) => {
    try {
      const response = await dashboardApi.get('get-target-save-data', {
          params: {
            per_page: postPerPage,
            page: page + 1,
          }
        });
      if (response.data.status === 200) {
           const responseData = response.data.data;
           const stockdata = responseData.data || responseData;
          // console.log(stockdata);
           settargetlist(stockdata);
           setNumber(response.data.last_page);
      } else {
        console.error('Error fetching data:', response.data.error);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  const fetchattributelist = async () => {
    try {
     const endpoint = 'active-attribute';
     const response = await dashboardApi.get(endpoint);

     if (response.data.status === 200) {
       setgoalattribute(response.data.data);
     } else {
       console.error('Error fetching data:', response.data.message);
     }
   } catch (error) {
     console.error('Error fetching data:', error);
   }
  }
  useEffect(() => {
    if (goal && selectedDepartment){
       //setInputValues('');
        const fetchData = async () => {
          try {
            const endpoint = `goal-based-attribute/${goal}/${selectedDepartment}`;
            //const endpoint = `count-stock/${stock}`;
            const response = await dashboardApi.get(endpoint);
           // console.log(response);
    
            if (response.data.status === 200) {
              setgoalsubattribute(response.data.data);
            } else {
              console.error('Error fetching data:', response.data.message);
            }
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
    
        fetchData();
    }
      }, [goal,selectedDepartment]);

      useEffect(() => {
        if (selectedgoalsubattribute && selectedDepartment){
          //setInputValues('');
           const fetchData = async () => {
             try {
               const endpoint = `goal-based-subattribute/${selectedgoalsubattribute}/${selectedDepartment}`;
               //const endpoint = `count-stock/${stock}`;
               const response = await dashboardApi.get(endpoint);
               //console.log(response);
       
               if (response.data.status === 200) {
                 setgoalsubsubattribute(response.data.data);
               } else {
                 console.error('Error fetching data:', response.data.message);
               }
             } catch (error) {
               console.error('Error fetching data:', error);
             }
           };
       
           fetchData();
       }
         }, [selectedgoalsubattribute,selectedDepartment]);

         useEffect(() => {
          if (selectedgoalsubsubattribute && selectedDepartment){
            //alert();
             //setInputValues('');
              const fetchData = async () => {
                try {
                  const endpoint = `goal-based-sub-subattribute/${selectedgoalsubsubattribute}/${selectedDepartment}`;
                  //const endpoint = `count-stock/${stock}`;
                  const response = await dashboardApi.get(endpoint);
                  //console.log(response);
          
                  if (response.data.status === 200) {
                   setchildattribute(response.data.data);
                  } else {
                    console.error('Error fetching data:', response.data.message);
                  }
                } catch (error) {
                  console.error('Error fetching data:', error);
                }
              };
          
              fetchData();
          }
            }, [selectedgoalsubsubattribute,selectedDepartment]);          

  useEffect(() => {
    const fetchData = async () => {
      try {
        const endpoint = 'get-active-department';
        const response = await dashboardApi.get(endpoint);

        if (response.data.status === 200) {
          setDepartments(response.data.data);
        } else {
          console.error('Error fetching data:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

   useEffect(() => {
    if(product){
       const fetchData = async () => {
      try {
        const endpoint = `get-category-list-by-product-id/${product}`;
        const response = await dashboardApi.get(endpoint);

        if (response.data.status === 200) {
            getservice(response.data.data);
        } else {
          console.error('Error fetching data:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();

    }
  }, [product]);

   const groupdetails = async () => {
    try {
      const endpoint = 'group-details';
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
        setgrouplist(response.data.data);
      } else {
        console.error('Error fetching data:', response.data.error);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const getAttributeNameById = (attributeId) => {
  const selectedAttribute = attribute.find((attr) => attr.id === attributeId);
  return selectedAttribute ? selectedAttribute.attribute_name : '';
};

const getTargetValueByAttributeId = (targetId, attributeId) => {
  const target = targetList.find((target) => target.id === targetId);
  return target && target.values ? target.values[attributeId] || '-' : '-';
};


  useEffect(() => {
    if(selectedDepartment){
      setSelectedAttributes([]);
      setattributefilter([]);
      setvaluefilter([]);
        const fetchData = async () => {
          try {
            const endpoint = `department-subattribute/${selectedDepartment}`;
            const response = await dashboardApi.get(endpoint);
    
            if (response.data.status === 200) {
                setattribute(response.data.data);
            } else {
              console.error('Error fetching data:', response.data.message);
            }
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
    
        fetchData();
    }
  }, [selectedDepartment]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const endpoint = 'get-product-list';
        const response = await dashboardApi.get(endpoint);

        if (response.data.status === 200) {
          setproductlist(response.data.data);
        } else {
          console.error('Error fetching data:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const endpoint = 'category-details';
  //       const response = await dashboardApi.get(endpoint);
  
  //       if (response.data.status === 200) {
  //           setcategorylist(response.data.data);
  //       } else {
  //         console.error('Error fetching data:', response.data.message);
  //       }
  //     } catch (error) {
  //       console.error('Error fetching data:', error);
  //     }
  //   };
  //   fetchData();
  // }, []);

  const handlePageClick = (selected) => {
    setCurrentPage(selected.selected);
    fetchStock(selected.selected);
  };
  
  const download_excel = async()=>{
    try {
      const response = await dashboardApi.get('export-target',{
        responseType: 'blob',
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'target.xlsx');
      document.body.appendChild(link);
      
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error exporting data:', error);
    }
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <div className="card-body">
                <div className="row mb-3">
                   <div className="filter-group">
                     <label style={{fontSize:'15px'}}>Department:</label>
                    <div className="filter-input">
                    <select
                      //className="form-select form-control"
                      value={selectedDepartment}
                      onChange={(e) => setSelectedDepartment(e.target.value)}
                       className="form-select form-control filter-select"

                    >
                      <option value="">Department</option>
                      {departments.map(dept => (
                        <option key={dept.id} value={dept.id}>
                          {dept.department_name}
                        </option>
                      ))}
                    </select>
                  </div>
                  </div>
                  &nbsp; &nbsp; 

                   <div className="filter-group">
                     <label style={{fontSize:'15px'}}>Goal:</label>
                    <div className="filter-input">
                    <select
                      value={goal}
                      onChange={(e) => setselectedgoal(e.target.value)}
                      style={{ width: '100%', padding: '8px',fontSize:'15px' }}
                        className="form-select form-control filter-select"
                    >
                      <option value="">Select Goal</option>
                      {goalattribute.map(dept => (
                      <option key={dept.id} value={dept.id}>
                        {dept.attribute_name}
                      </option>
                    ))}
                    </select>
                  </div>
                  </div>
                   &nbsp; &nbsp; 
                   <div className="filter-group">
                     <label style={{fontSize:'15px'}}> Attribute:</label>
                    <div className="filter-input">
                     <select
                         value={selectedgoalsubattribute}
                          onChange={(e) => setselectedgoalsubattribute(e.target.value)}
                            style={{ width: '100%', padding: '8px',fontSize:'15px' }}
                            className="form-select form-control filter-select"
                            >
                            <option value="">Select Attribute</option>
                             {goalsubattribute.map(dept => (
                      <option key={dept.id} value={dept.id}>
                        {dept.attribute_name}
                      </option>
                    ))}
                    </select>

                  </div>
                  </div>
                   &nbsp; &nbsp; 
                 <div className="filter-group">
                     <label style={{fontSize:'15px'}}>Sub Attribute:</label>
                    <div className="filter-input">
                      <select
                         value={selectedgoalsubsubattribute}
                         onChange={(e) => setselectedgoalsubsubattribute(e.target.value)}
                         style={{ width: '100%', padding: '8px',fontSize:'15px' }}
                           className="form-select form-control filter-select"
                        >
                        <option value="">Select sub Attribute</option>
                        {goalsubsubattribute.map(dept => (
                        <option key={dept.id} value={dept.id}>
                        {dept.subattribute_name}
                      </option>
                    ))}
                  </select>
                  </div>
                  </div>
                   &nbsp; &nbsp; 


                     <div className="filter-group">
                     <label style={{fontSize:'15px'}}>product:</label>
                    <div className="filter-input">
                    <select
                      value={product}
                    onChange={(e) => setproduct(e.target.value)}
                      style={{ width: '100%', padding: '8px',fontSize:'15px' }}
                       className="form-select form-control filter-select"
                    >
                      <option value="">Select Product</option>
                      {productlist.map(dept => (
                      <option key={dept.id} value={dept.id}>
                        {dept.product_name}
                      </option>
                    ))}
                    </select>
                  </div>
                  </div>
                   &nbsp; &nbsp; 
                  <div className="filter-group">
                   <label style={{fontSize:'15px'}}>Filter Attributes:</label>
                    <div className="filter-input">
                      <input
                        type="text"
                        placeholder="Select Attribute"
                        readOnly
                        onClick={() => setShowAttributeDropdown(!showAttributeDropdown)} 
                        style={{ width: '100%', padding: '8px',fontSize:'15px' }}
                         className="form-select form-control filter-select"
                      />
                      {showAttributeDropdown && child_attribute.length > 0 && (
                        <div
                          style={{
                            position: 'absolute',
                            top: '100%',
                            left: 0,
                            width: '100%',
                            border: '1px solid #ced4da',
                            borderRadius: '4px',
                            maxHeight: '200px',
                            overflowY: 'auto',
                            zIndex: 999,
                            backgroundColor: '#fff',
                          }}
                        >
                          {child_attribute.map(row => (
                            <div key={row.id} style={{ padding: '5px' }}>
                              <input
                                type="checkbox"
                                id={row.id}
                                value={row.id}
                                checked={selectedchild_attribute.includes(row.id)}
                                onChange={() => {
                                  setselectedchildattribute(prevState =>
                                    prevState.includes(row.id)
                                      ? prevState.filter(id => id !== row.id)
                                      : [...prevState, row.id]
                                  );
                                }}
                              />
                              <label htmlFor={row.id} style={{ marginLeft: '5px' }}>{row.name}</label>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                   &nbsp; &nbsp; 

                     <div className="filter-group">
  <label style={{fontSize:'15px'}}>Group:</label>
  <div className="filter-input">
    <select
      value={group}
      onChange={(e) => setgroup(e.target.value)}
      className="form-select form-control filter-select"
       style={{ width: '100%', padding: '8px',fontSize:'15px' }}
    >
      <option value="">Select Group</option>
      {grouplist.map((row) => (
                  <option key={row.group_id} value={row.group_id}>
                    {row.name}
                  </option>
                ))}
    </select>
  </div>
</div>
 &nbsp; &nbsp; 
<div className="filter-group">
  <label style={{fontSize:'15px'}}>Financial Year:</label>
  <div className="filter-input">
    <select
      value={financial_year}
      onChange={(e) => set_financialyear(e.target.value)}
      className="form-select form-control filter-select"
       style={{ width: '100%', padding: '8px',fontSize:'15px' }}
    >
      <option value="">Select Financial year</option>
      {financialYears.map((year) => (
        <option key={year} value={year}>{year}</option>
      ))}
    </select>
  </div>
</div>


                </div>
                <Link className='btn btn-info btn-sm' to='/lead-target'>View Lead Target</Link> &nbsp;&nbsp;
                <Link className='btn btn-info btn-sm' to='/customer-target'>View Customer Target</Link> &nbsp;&nbsp;
                <div style={{float:'right'}}>
                  <Link className='btn btn-primary mb-2 float-end' to='/company-target'>
                    +
                  </Link>
                </div>
                {loading ? (
                  <p>Loading...</p>
                ) : (
                  <table className="table table-bordered table-hovered">
                    <thead>
                      <tr>
                        <td>S.No</td>
                        <td>Dept</td>
                        <td>Product</td>
                        <td>Goal</td>
                        <td>Attribute</td>
                        <td>SubAttribute</td>
                        <td>Year</td>
                        <td>Group</td>
                        
                         {
                          attributefilter && attributefilter.map((row,index)=>(
                          <>
                          <td>{row.name}</td>
                          <td>Acheived</td>
                          
                          </>

                          ))}
                      </tr>
                    </thead>
                    <tbody>
  {targetList.map((row, index) => (
    <tr key={row.id}>
      <td>{index + 1}</td>
      <td>{row.department}</td>
      <td>{row.product}</td>
      <td>{row.goal}</td>
      <td>{row.attribute}</td>
      <td>{row.subattribute}</td>
      <td>{row.year}</td>
      <td>{row.group}</td>
     {
      attributefilter && attributefilter.length > 0 &&
      attributefilter.map((attribute) => (
    <>
    <td>
      {valuefilter[attribute.id] || '-'}
    </td>
    <td>{row.acheived}</td>
     </>
  ))
}

{
  groupfilter && groupfilter.length > 0 &&
  groupfilter.map((group) => (
    <td key={group.group_id}>
      {groupvaluefilter[group.group_id] || '-'}
    </td>
  ))
}


    </tr>
  ))}
</tbody>
                  </table>
                )}
                {targetList.length > 0 && (
                  <Pagination className="custom-pagination">
                    <ReactPaginate
                      previousLabel={"Previous"}
                      nextLabel={"Next"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={number}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination"}
                      activeClassName={"active"}
                      pageLinkClassName={"page-link-style"}
                      breakLinkClassName={"break-link-style"}
                      previousLinkClassName={"prev-link-style"}
                      nextLinkClassName={"next-link-style"}
                    />
                  </Pagination>
                )}
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default TargetDetails;