import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import dashboardApi from "layouts/common_components/apibase_url";
import './attendance_report.css';
import ReactPaginate from 'react-paginate';

function Salary_info() {
  const token = secureLocalStorage.getItem("token");
  const emp_id = secureLocalStorage.getItem("emp_id");
  const [empsalary, setEmpsalary] = useState([]);
  const [department, setDepartment] = useState([]);
  const [selectedYear, setSelectedYear] = useState("");
  const currentYear = new Date().getFullYear();
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [number, setNumber] = useState(1);
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    } else {
      getReport();
    }
  }, [token, emp_id, navigate, selectedMonth, selectedYear, selectedDepartment, currentPage]);

  const years = Array.from({ length: 6 }, (_, i) => currentYear - i);

  const months = [
    "January", "February", "March", "April", "May", "June", "July", 
    "August", "September", "October", "November", "December"
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const endpoint = 'get-active-department';
        const response = await dashboardApi.get(endpoint);

        if (response.data.status === 200) {
          setDepartment(response.data.data);
        } else {
          console.error('Error fetching data:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  const getReport = async () => {
    try {
      const response = await dashboardApi.get("salary-details", {
        params: {
          emp_id: emp_id,
          month: selectedMonth,
          year: selectedYear,
          department: selectedDepartment,
          page: currentPage + 1, // Adjusted to 1-based page number
        },
      });

      if (response.data.status === 200) {
        setEmpsalary(response.data.data.data);
        setNumber(response.data.data.last_page); // Set total pages for pagination
      } else {
        console.error("Error fetching data:", response.data.message);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleChange = (e) => {
    setSelectedYear(e.target.value);
  };

  const handleMonthChange = (e) => {
    setSelectedMonth(e.target.value);
  };

  const handleDepartmentChange = (e) => {
    setSelectedDepartment(e.target.value);
  };

  const handleDownload = async (emp_id, months, year) => {
    try {
      const response = await fetch(`https://apiworkforce.r-ims.com/api/salary/generate-pdf/${emp_id}/${months}/${year}`);
      if (!response.ok) {
        throw new Error(`Failed to fetch HTML: ${response.statusText}`);
      }

      const html = await response.text();
      const newWin = window.open('', '_blank');
      newWin.document.write(html);
      newWin.document.close();
      newWin.location.href = `https://apiworkforce.r-ims.com/api/salary/generate-pdf/${emp_id}/${months}/${year}`;
    } catch (error) {
      console.error('Error fetching HTML:', error.message);
    }
  };

  const handlePageClick = (selected) => {
    setCurrentPage(selected.selected);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-3">
                    <select 
                      id="yearDropdown" 
                      value={selectedYear} 
                      onChange={handleChange} 
                      className="form-control"
                    >
                      <option value="">-- Year --</option>
                      {years.map((year) => (
                        <option key={year} value={year}>{year}</option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-3">
                    <select 
                      id="monthDropdown" 
                      value={selectedMonth} 
                      onChange={handleMonthChange} 
                      className="form-control"
                    >
                      <option value="">-- Month --</option>
                      {months.map((month, index) => (
                        <option key={index} value={month}>{month}</option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-3">
                    <select 
                      className="form-control" 
                      value={selectedDepartment} 
                      onChange={handleDepartmentChange}
                    >
                      <option value=''>Department</option>
                      {department.map((dept) => (
                        <option key={dept.id} value={dept.id}>
                          {dept.department_name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>

              <MDBox p={3}>
                <table className="attendencetable" style={{ width: "100%" }}>
                  <thead>
                    <tr>
                      <td>Emp Code</td>
                      <td>Employee</td>
                      <td>Department</td>
                      <td>Gross Salary</td>
                      <td>Deduction</td>
                      <td>Net Salary</td>
                      <td>Month</td>
                      <td>Year</td>
                      <td>Action</td>
                    </tr>
                  </thead>
                  <tbody>
                    {empsalary.map((row) => (
                      <tr key={row.emp_id + row.month}>
                        <td>{row.emp_id}</td>
                        <td>{row.emp_fname} {row.emp_lame}</td>
                        <td>{row.department_name}</td>
                        <td>{row.gross_salary}</td>
                        <td>{row.deduction}</td>
                        <td>{row.net_salary}</td>
                        <td>{row.month}</td>
                        <td>{row.year}</td>
                        <td>
                          <button 
                            onClick={() => handleDownload(row.emp_id, row.month, row.year)} 
                            className="btn btn-info btn-sm"
                          >
                            Slip
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                {empsalary.length > 0 && (
                  <ReactPaginate
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    pageCount={number}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    activeClassName={"paging__link--active"}
                    pageLinkClassName={"page-link-style"}
                    breakLinkClassName={"break-link-style"}
                    previousLinkClassName={"prev-link-style"}
                    nextLinkClassName={"next-link-style"}
                  />
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Salary_info;
