import React,{ useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { useNavigate,useParams } from "react-router-dom";
import EmployeeDetailsHeader from "layouts/common_components/emp_details_header";
import dashboardApi from "layouts/common_components/apibase_url";
import  secureLocalStorage  from  "react-secure-storage";
import Pagination from 'react-bootstrap/Pagination';
//import 'bootstrap/dist/css/bootstrap.min.css';
import ReactPaginate from 'react-paginate';


function GenerateLatters() {
  const employee_id = secureLocalStorage.getItem('emp_id');

  const token = secureLocalStorage.getItem('token');
  const [latter_type, set_latter_type] = useState('');
  const [department,set_department] = useState('');
  const [emp_id,set_emp_id] = useState('');
  const [department_list,set_department_list] = useState([]);
  const [emplist,set_emp_list] = useState([]);
  const [letters_list,set_letters_list] = useState([]);
  const navigate = useNavigate();
  const [validationErrors, setValidationErrors] = useState({});
  const [currentPage, setCurrentPage] = useState(0); 
  const [number, setNumber] = useState(1);
  useEffect(() => {
   if (!token) {
    navigate("/sign-in");
   // return null;
  }
  }, [token, navigate]);

  useEffect(()=>{
    get_emp_details();
   },[department,latter_type])

   useEffect(()=>{
    download_letters_list();
    },[currentPage])

    const clear_fields = ()=>{
        set_department('');
        set_emp_id('');
        set_latter_type('');

    }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const endpoint = 'get-active-department';
        const response = await dashboardApi.get(endpoint);
  
        if (response.data.status === 200) {
         set_department_list(response.data.data);
        } else {
          console.error('Error fetching data:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  }, []);

  const download_letters_list = async () => {
    try {
      const endpoint = 'get-emp-letters';
      const response = await dashboardApi.get(endpoint, {
        params: { page: currentPage + 1 }, 
      });
  
      if (response.data.status === 200) {
        const responseData = response.data.data;
        const employeeData = responseData.data || responseData;
        set_letters_list(employeeData);
        setNumber(responseData.last_page);
      } else {
        console.error('Error fetching data:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  

  const get_emp_details = async () => {
    try {
      const response = await dashboardApi.get('get-all-emp-details', {
        params: {
          //created_by: employee_id,
          latter_type:latter_type,
          dept_id:department,
        },
      });
      if (response.data.status === 200) {
          set_emp_list(response.data.data)
      } else {
        console.error('Error fetching data:', response.data.error);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const generate_latter = async () => {
    const errors = {};
    if (!emp_id) {
        errors.emp_id = 'Emp is required';
    }
    if (!latter_type) {
        errors.latter_type = 'Latter Type is required';
    }
    if (!department) {
        errors.department = 'Department is required';
    }
    setValidationErrors(errors);

    if (Object.keys(errors).length === 0) {
        try {
            const response = await dashboardApi.get('generate-latters', {
                params: {
                    latter_type: latter_type,
                    emp_id: emp_id,
                    created_by: employee_id,
                },
                responseType: 'blob', // Ensures the response is treated as a file
            });

            // Extract file name from Content-Disposition header
            const contentDisposition = response.headers['content-disposition'];
            console.log(contentDisposition);
            const fileNameMatch = contentDisposition && contentDisposition.match(/filename="(.+)"/);
            const fileName = fileNameMatch ? fileNameMatch[1] : 'download.pdf'; // Fallback name

            // Create blob and download link
            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error downloading the report:', error);
        }
    }
    download_letters_list();
    clear_fields();
};

const handlePageClick = (selected) => {
    setCurrentPage(selected.selected);
  };

return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <div className="card-body">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3">
                            <select className="form-control"
                              value={latter_type}
                              onChange={(e) => set_latter_type(e.target.value)}
                              >
                                <option value=''>Select Latter Type</option>
                                <option value='relieving_latter'>Releaving Latter</option>
                                <option value ='exprience_latter'>Exprience Latter</option>
                                <option value ='confirmation_latter'>Confirmation Latter</option>
                                </select>
                                {validationErrors.latter_type && (
                                 <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.latter_type}</div>
                                )}
                               </div>

                               <div className="col-md-3">
                               <select className="form-control"
                                value={department}
                                onChange={(event)=>{
                                  set_department(event.target.value)}}
                                >
                               <option value=''>Select Deprtment</option>
                                {department_list.map((departments) => (
                                <option key={departments.id} value={departments.id}>
                                {departments.department_name}
                              </option>
                              ))}
                            </select>
                            {validationErrors.department && (
                                 <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.department}</div>
                                )}
                            </div>

                             <div className="col-md-3">
                               <select className="form-control"
                                value={emp_id}
                                onChange={(event)=>{
                                  set_emp_id(event.target.value)}}
                                >
                               <option value=''>Select Employee</option>
                                {emplist.map((employee) => (
                                <option key={employee.emp_id} value={employee.emp_id}>
                                {employee.emp_fname} {employee.emp_lame}
                              </option>
                              ))}
                            </select>
                            {validationErrors.emp_id && (
                                 <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.emp_id}</div>
                                )}
                         </div>
                         <div className="col-md-3">
                         <button className="btn btn-info btn-sm" onClick={generate_latter}>Generate</button>

                         </div>
                         </div>
                         </div>
                         <br/>
                         <div className="table-responsive">
                         <table className="table table-bordered table-hovered">
                            <tr>
                            <td>Emp Name</td>
                            <td>Department</td>
                            <td>File</td>
                            <td>Action</td>
                           </tr>
                           {letters_list.map((letter, index) => (
                            <tr key={letter.id}>
                            <td>{letter.emp_fname} {letter.emp_lame}</td>
                            <td>{letter.department_name}</td>
                            <td>{letter.file_name}</td>
                            <td><Link to={letter.file_name} className="btn btn-info btn-sm" target='_blank'>View</Link></td>
                            </tr>
                 
                          ))}
                           </table>
                         </div>

                  {letters_list.length > 0 && (
                   <Pagination className="custom-pagination">
                      <ReactPaginate
                      previousLabel={"Previous"}
                      nextLabel={"Next"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={number}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination"}
                      activeClassName={"paging__link--active"} 
                      pageLinkClassName={"page-link-style"}
                      breakLinkClassName={"break-link-style"}
                      previousLinkClassName={"prev-link-style"}
                      nextLinkClassName={"next-link-style"}
                    />
                  </Pagination>
                   )}
                </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default GenerateLatters;
