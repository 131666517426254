import React, { useState, useEffect } from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
// Material Dashboard 2 React example components
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import Swal from 'sweetalert2';
import { useNavigate,useParams,Link } from 'react-router-dom';
import dashboardApi from "layouts/common_components/apibase_url";
import  secureLocalStorage  from  "react-secure-storage";

//const [loading, setLoading] = useState(true);

function EditAttribute() {
  const { id } = useParams();
  const token = secureLocalStorage.getItem('token');
  const emp_id = secureLocalStorage.getItem('emp_id');
  const navigate = useNavigate();
  const [options, setOptions] = useState([]);
  const [selectedCompany, setselectedCompany] = useState('');
  const [attribute, setattribute] = useState('');
  const [percentage, setpercentage] = useState('');
  const [amount, setamount] = useState('');
  const [startsallary,setstartsallary] = useState('');
  const [endsallary,setendsallary] = useState('');
  const [checked,setchecked] = useState('');
  const [validationErrors, setValidationErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [deduction,setdeduction] = useState('');
  const [deductionlist,setdeductionlist] = useState([]);
  const [parentattribute,setparentattribute] = useState('');
  const [contributtion,setcontributtion] = useState('');
  const [metrocity,setmetrocity] = useState('');
  const [description,setdescription] = useState('');
  //console.log(checked);
   useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }
  }, [token, navigate]);
  useEffect(()=>{
    fetchattribute()
  },[])
  const fetchattribute = async () => {
   await dashboardApi.get(`edit-attribute/${id}`).then(({data})=>{
      //console.log('hi');
      const {attribute,percentage,is_deduction_attribute,company_id,amount,start_salary,end_salary,deduction_from,is_contribution_attribute,
        parent_attribute,is_metrocity_attribute,description} = data.attribute;
      //alert(is_checked);
      setselectedCompany(company_id);
      setattribute(attribute);
      setpercentage(percentage);
      setchecked(is_deduction_attribute);
      setamount(amount);
      setstartsallary(start_salary);
      setendsallary(end_salary); 
      setdeduction(deduction_from);
      setcontributtion(is_contribution_attribute);
      setparentattribute(parent_attribute);
      setmetrocity(is_metrocity_attribute);
      setdescription(description);
      }).catch(({response:{data}})=>{
      Swal.fire({
        text:data.message,
        icon:"error"
      })
    })
  }
   useEffect(() => {
  const fetchData = async () => {
    try {
      const endpoint = 'get-active-company';
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
       setOptions(response.data.data);
      } else {
        console.error('Error fetching data:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  fetchData();
}, []);
 useEffect(() => {
    const fetchData = async () => {
    try {
      const endpoint = 'get-deduction-attribute';
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
       setdeductionlist(response.data.data);
      } else {
        console.error('Error fetching data:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  fetchData();
}, []);
const updateattribute = async (e) => {
  e.preventDefault();

  const errors = {};
  if (!selectedCompany) {
    errors.company = 'Company is required';
  }
  if (!attribute) {
    errors.attribute = 'Attribute is required';
  }

  setValidationErrors(errors);

  if (Object.keys(errors).length === 0) {
    const formData = new FormData();

    if (selectedCompany) formData.append('company', selectedCompany);
    if (attribute) formData.append('attribute', attribute);
    if (percentage) formData.append('percentage', percentage);
    if (amount) formData.append('amount', amount);
    if (startsallary) formData.append('start_salary', startsallary);
    if (endsallary) formData.append('end_salary', endsallary);
    formData.append('is_checked', checked ? 1 : 0);
    formData.append('contributtion', contributtion ? 1 : 0);
    if (deduction) formData.append('deduction', deduction);
    if (parentattribute) formData.append('parent', parentattribute);
    formData.append('metrocity', metrocity ? 1 : 0);
    if (description) formData.append('description', description);
    formData.append('emp_id', emp_id);

    try {
      const response = await dashboardApi.post(`update-attribute/${id}`, formData);

      if (response.data.status === 200) {
        Swal.fire({
          icon: 'success',
          text: response.data.message,
        });
        navigate('/salary-attribute-list');
      } else if (response.data.status === 400) {
        setValidationErrors(response.data.messages);
      } else {
        Swal.fire({
          icon: 'error',
          text: response.data.message || 'An error occurred',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while updating the attribute',
      });
    }
  }
};


  

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
            <div style={{float:'right'}}>
                <Link to='/salary-attribute-list' className="btn btn-info">Back</Link>

              </div>
              <div className="card-body">
                <form onSubmit={updateattribute}>
                   <div className="container-fluid">
                    <div className="row">
                    <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="country" style={{ fontSize: '16px' }}>Select Company</label>
                          <select className="form-control"
                          value={selectedCompany}
                          onChange={(e) => setselectedCompany(e.target.value)}
                          >
                            <option value=''>Select Country</option>
                             {options.map((option) => (
                             <option key={option.id} value={option.id}>
                              {option.business_name}
                              </option>
                              ))}
                          </select>
                          {validationErrors.company && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.company}</div>
                             )}
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="first name" style={{ fontSize: '16px' }}>Attribute Name</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Attribute Name"
                            value={attribute}
                            onChange={(e) => setattribute(e.target.value)}
                          />
                           {validationErrors.attribute && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.attribute}</div>
                             )}
                          
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="first name" style={{ fontSize: '16px' }}>Percentage</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter percentage"
                            value={percentage}
                            onChange={(e) => setpercentage(e.target.value)}
                           />
                           
                        </div>
                        
                      </div>
                         OR
                       <div className="col-md-2">
                        <div className="form-group">
                          <label htmlFor="first name" style={{ fontSize: '16px' }}>Amount</label>
                          
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Amount"
                            value={amount}
                            onChange={(e) => setamount(e.target.value)}
                           />
                           
                          
                        </div>
                      </div>
                      
                       
                      </div>
                       <div className= "row">
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="first name" style={{ fontSize: '16px' }}>Start Salary Range</label>
                           <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Start Salary"
                            value={startsallary}
                            onChange={(e) => setstartsallary(e.target.value)}
                           />
                          
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="first name" style={{ fontSize: '16px' }}>End Salary Range</label>
                           <input
                            type="text"
                            className="form-control"
                            placeholder="End Salary"
                            value={endsallary}
                            onChange={(e) => setendsallary(e.target.value)}
                           />
                          
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="first name" style={{ fontSize: '16px' }}>Deduction From</label>
                          <select className="form-control"
                            value={deduction}
                            onChange={(event) => {
                              setdeduction(event.target.value)
                            }}
                          >
                            <option value=''>Select Deduction From</option>
                            <option value='basic'>Basic</option>
                            <option value='Package'>CTC</option>
                             <option value='gross'>Gross Salary</option>
                            <option value='ctc-(basic+hra)'>CTC-(Basic+Hra)</option>
                          </select>
                          
                        </div>
                      </div>
                       <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="first name" style={{ fontSize: '16px' }}>For Deduction( like PF, ESIC, check here)</label>
                          <input
                            type="checkbox"
                            className="form-control"
                             checked={checked}
                             onChange={(e) => setchecked(e.target.checked)}
                           />
                          
                        </div>
                      </div>
                      </div>

                      
                      </div>
                      <div className = "row">
                       <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="first name" style={{ fontSize: '16px' }}>For contributtion( like PF, ESIC, check here)</label>
                          <input
                            type="checkbox"
                            className="form-control"
                             checked={contributtion}
                             onChange={(e) => setcontributtion(e.target.checked)}
                           />
                          
                        </div>
                      </div>
                      {contributtion &&(
                       <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="country" style={{ fontSize: '16px'}}>Select Parent Attribute</label>
                          <select className="form-control"
                           value={parentattribute}
                           onChange={(e) => setparentattribute(e.target.value)}
                            >
                            <option value=''>Select Parent Attribute</option>
                            {deductionlist.map((option) => (
                             <option key={option.id} value={option.id}>
                              {option.name}
                              </option>
                              ))}
                              
                          </select>
                        </div>
                      </div>
                      )}

                      <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="first name" style={{ fontSize: '16px' }}>Metro City Attribute</label>
                          <input
                            type="checkbox"
                            className="form-control"
                             checked={metrocity}
                             onChange={(e) => setmetrocity(e.target.checked)}
                           />
                          
                        </div>
                      </div>

                       <div className="col-md-3">
                        <div className="form-group">
                          <label htmlFor="first name" style={{ fontSize: '16px' }}>Description</label>
                          <input
                            type="text"
                            className="form-control"
                             value = {description}
                             onChange={(e) => setdescription(e.target.value)}
                           />
                          
                        </div>
                      </div>

                    
                      </div>
                      <div className="row">
                      <div className="col-md-12 text-center mt-5">
                       <button variant="primary" className="mt-2 btn btn-info" size="lg" block="block" type="submit">
                           Update
                        </button>
                      </div>
                    </div>
                      
                </form>
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default EditAttribute;