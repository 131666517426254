import React,{ useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import DataTable from "theme_layout/Tables/DataTable";
import authorsTableData from "layouts/employee/data/authorsTableData";
import projectsTableData from "layouts/employee/data/projectsTableData";
import { Link } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import {useNavigate } from "react-router-dom";
import Switch from "react-switch";
import dashboardApi from "layouts/common_components/apibase_url";
import Pagination from 'react-bootstrap/Pagination';
import ReactPaginate from 'react-paginate';
import  secureLocalStorage  from  "react-secure-storage";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

function EmpConfirmationDetails() {
  const navigate = useNavigate();
  //const { columns, rows } = authorsTableData();
 // const { columns: pColumns, rows: pRows } = projectsTableData();
  const [confirmation, set_confirmation] = useState([]);
  const [loading, setLoading] = useState(true);
  const token = secureLocalStorage.getItem("token");
  const [currentPage, setCurrentPage] = useState(0); 
  const [number, setNumber] = useState(1); 
  const [postPerPage] = useState(10);
  //const [selectyear,setselectyear] = useState('');
  const [start_date,setstart_date] = useState('');
  const [end_date,setend_date] = useState('');
  const [id,set_id] = useState('');
  const [openmodal,setopenmodal] = useState(false);
  const [status,setstatus] = useState('');
  const [next_date,set_nexr_date] = useState('');
  const [validationErrors, setValidationErrors] = useState({});
  //const [totalPages, setTotalPages] = useState(0);
    useEffect(() => {
       if (!token) {
        navigate("/sign-in");
     }
    }, [token, navigate]);

    useEffect(() => {
      fetchData(currentPage);
    }, [currentPage, start_date, end_date]);
  
    // Fetch data from API
    const fetchData = async (page = 0) => {
      setLoading(true);
      try {
        const response = await dashboardApi.get("emp-confirmation-details", {
          params: {
            per_page: postPerPage,
            page: page + 1,
            start_date,
            end_date,
          },
        });
  
        if (response.data.status === 200) {
          const { data, last_page } = response.data.data;
          set_confirmation(data);
          //setTotalPages(last_page);
          setNumber(last_page);
        } else {
          console.error("Error fetching data:", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
 
const handlePageClick = (selected) => {
    setCurrentPage(selected.selected);
  };

  const openpopup = (employee_id)=>{
   set_id(employee_id);
   setopenmodal(true);

  }
  const closemodal = ()=>{
    setstatus('');
    set_nexr_date('');
    setopenmodal(false);
    setValidationErrors({});
  }

  const savedata = async()=>{
    const errors = {};
    if(!status){
        errors.status = 'Status is Required';
       }

    if(status ==2){
       if(!next_date){
        errors.next_date = 'Next Date is Required';
       }

    }


     setValidationErrors(errors);

    if (Object.keys(errors).length === 0) {
        const formData = new FormData();
        formData.append('emp_id',id);
        formData.append('status',status);
        formData.append('next_date',next_date);
        try {
            const response =  await dashboardApi.post('emp-confirmation-status-update', formData);
          if (response.data.status === 200) {
            Swal.fire({
              icon: 'success',
              text: response.data.message,
            });
            fetchData();
            closemodal();
            //fetchEmployees()
    
          }
          else {
            Swal.fire({
              icon: 'error',
              text: response.data.message || 'An error occurred',
            });
          }
        } catch (error) {
          Swal.fire({
            icon: 'error',
            text: 'An error occurred while creating',
          });
        } finally {
          //setIsSubmitting(false);
        }

     }



  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <div className="card-body">
              <div className='row'>
                <div className='col-md-4'>
                  <input
                   type = 'date'
                   className = 'form-control'
                   value = {start_date}
                   onChange={(e) => setstart_date(e.target.value)}
                   />
                </div>

                <div className ='col-md-4'>
                 <input
                 type = 'date'
                 className="form-group form-control"
                 value = {end_date}
                 onChange={(e) => setend_date(e.target.value)}
                 />

                </div>

              </div>

                {loading ? (
                  <p>Loading...</p>
                ) : (
                  <table className="table table-bordered table-hovered">
                 <tr>
                  <td>S.No</td>
                  <td>Emp Name</td>
                  <td>Department</td>
                  <td>Designation</td>
                  <td>Branch</td>
                  <td>Doj</td>
                  <td>Confirmation Date</td>
                  <td>Status</td>
                  <td>Action</td>
               </tr>
               {confirmation.map((row, index) => (
                <tr key={row.emp_id}>
                  <td>{index + 1}</td>
                  <td>{row.emp_fname} {row.emp_lame}</td>
                  <td>{row.department_name}</td>
                  <td>{row.designation_name}</td>
                  <td>{row.branch_name}</td>
                  <td>{row.emp_doj}</td>
                  <td>{row.emp_doc}</td>
                  <td>{row.is_confirmed}</td>
                  <td>
                    {row.confirmation_status ==0 &&
                  <button className='btn btn-info btn-sm' onClick={() => openpopup(row.emp_id)}>Action</button>
                    }
                  </td>
                  
               </tr>
              ))}

            </table>
             )}
              {confirmation.length > 0 && (
                <Pagination className="custom-pagination">
                      <ReactPaginate
                      previousLabel={"Previous"}
                      nextLabel={"Next"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={number}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination"}
                      activeClassName={"active"}
                      pageLinkClassName={"page-link-style"}
                      breakLinkClassName={"break-link-style"}
                      previousLinkClassName={"prev-link-style"}
                      nextLinkClassName={"next-link-style"}
                    />
                  </Pagination>
               )}
                
              </div>
            </Card>
            <Dialog
            open={openmodal}
        //onClose={closeModalHandler}
          fullWidth
          PaperProps={{ style: { maxWidth: 'md', padding: '40px', height:'450px'} }}
      >
        <DialogTitle>Action On Request</DialogTitle>
        <DialogContent>
          <form>
           <div className='container'>
            <div className="row">
                <div className="col-md-12">
                <label htmlFor="short name" style={{ fontSize: '16px',height:'20px' }}>Select Status</label>
                   <select
                    className = 'form-control'
                    value={status}
                    onChange={(e) => setstatus(e.target.value)}
                    >
                   <option value=''>Select Status</option>
                    <option value = '1'>Confirmed</option>
                    <option value='2'>Pospond</option>
                   </select>
                   {validationErrors.status && (
                        <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.status}</div>
                     )}

                </div>

                {
                  status == 2 &&

                  <div className="col-md-12">
                   <label htmlFor="short name" style={{ fontSize: '16px',height:'20px' }}>Next Confirmation Date</label>
                   <input
                    type ='date'
                    className = 'form-control'
                    value={next_date}
                    onChange={(e) => set_nexr_date(e.target.value)}
                    />
                   {validationErrors.next_date && (
                        <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.next_date}</div>
                     )}

                </div>


                }

            </div>
            </div>
          </form>
        </DialogContent>
        <DialogActions>
          <button className="btn btn-danger" onClick={closemodal}>
            Close
          </button>
          <button className="btn btn-info" onClick = {savedata} >
            Save
          </button>
        </DialogActions>
      </Dialog>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default EmpConfirmationDetails;