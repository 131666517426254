import React, { useState, useEffect } from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import Swal from 'sweetalert2';
import { useNavigate, useParams, Link } from 'react-router-dom';
import EmployeeDetailsHeader from "layouts/common_components/emp_details_header";
import dashboardApi from "layouts/common_components/apibase_url";
import secureLocalStorage from "react-secure-storage";

function SallaryInfo() {
  const token = secureLocalStorage.getItem('token');
  const { id } = useParams();
  const employee_id  =  secureLocalStorage.getItem('emp_id');
  const [formData, setFormData] = useState([]);
  const navigate = useNavigate();
  const [packagedetails, setPackagedetails] = useState('');
  const [textValues, setTextValues] = useState({});
  const [salary, getsalary] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});
  const [savedData, setSavedData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [submitted, setsubmitted] = useState([]);
  const [senddata, setsenddata] = useState('');
  const [tds, settds] = useState('');
  const [tdsvalue, settdsvalue] = useState('');
  const [metrocity,setmetrocity] = useState(false);
  const [calculatestatus,setcalculatestatus] = useState(false);
  const [salary_description,get_salary_description] = useState({});

  useEffect(() => {
    fetchDesignation();
    fetchSavedData();
    fetchsalary();
    fetchsavedData();
    get_tds_data();
    get_gross_and_net_salary()
  }, []);

  //console.log(metrocity);

  const fetchsalary = async () => {
    try {
      const response = await dashboardApi.get(`get-salary-details/${id}`);
      if (response.data.status === 200) {
        getsalary(response.data.data);
      } else {
        console.error('Error fetching employee:', response.data.message);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching employee:', error);
      setLoading(false);
    }
  };


  const get_gross_and_net_salary = async () => {
    try {
      const response = await dashboardApi.get(`get-gross-and-net-salary/${id}`);
      if (response.data.status === 200) {
        get_salary_description(response.data.data);
      } else {
        console.error('Error fetching data:', response.data.message);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching employee:', error);
      setLoading(false);
    }
  };

  //console.log(salary_description);



  const getSavedAmount = (attributeId) => {
    //alert(attributeId);
    const savedAttribute = savedData.find((data) => data.attribute_id === attributeId);
    // if(!calculatestatus){
    //    return savedAttribute ? savedAttribute.amount_per_anum : '';
    //  }
    
       return savedAttribute ? savedAttribute.amount : '';

     
  };

  useEffect(() => {
    if ((packagedetails || metrocity)) {
      const fetchData = async () => {
        try {
          const response = await dashboardApi.post('salary-calc', {
            package: packagedetails,
            selectedCheckboxes,
            metrocity:metrocity,
          });

          if (response.data.status === 200) {
            setSavedData(response.data.data);
            setsenddata(response.data.data);
          } else {
            Swal.fire({
              icon: 'error',
              text: response.data.message,
            });
          }
        } catch (error) {
          console.error('Error fetching states data:', error);
        }
      };

      fetchData();
    }
  }, [packagedetails, selectedCheckboxes,metrocity]);

  const fetchDesignation = async () => {
    try {
      const response = await dashboardApi.get('salary-fields');
      if (response.data.status === 200) {
        setFormData(response.data.data);
        settds(response.data.tds);
      } else {
        console.error('Error fetching salary fields:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching salary fields:', error);
    }
  };

   const get_tds_data = async () => {
    try {
      const response = await dashboardApi.get(`get-tds-data/${id}`);
      if (response.data.status === 200) {
        settdsvalue(response.data.data);
        if(response.data.is_metro_city_emp==1){
           setmetrocity(true);

        }
        else{
           setmetrocity(false);

        }
       
      } else {
        console.error('Error fetching salary fields:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching salary fields:', error);
    }
  };

  const fetchsavedData = async () => {
    try {
      const endpoint = `submitted-salary/${id}`;
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
        const submittedModules = response.data.data;
        setsubmitted(submittedModules);
        setSavedData(submittedModules);

        const submittedModuleIds = Array.from(
          new Set(submittedModules.map((module) => module.attribute_id))
        );

        setSelectedCheckboxes(submittedModuleIds);
      } else {
        console.error('Error fetching data:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
 // console.log(savedData);

  const fetchSavedData = async () => {
    try {
      const response = await dashboardApi.get(`edit-salary/${id}`);
      if (response.data.status === 200) {
        setSavedData(response.data.data);
        setPackagedetails(response.data.data[0]?.emp_package || '');
        settdsvalue(response.data.data[0]?.tds || '');
      } else {
        console.error('Error fetching saved salary data:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching saved salary data:', error);
    }
  };

  const toggleStatus = async (empId) => {
    const isConfirm = await Swal.fire({
      title: 'Are you sure?',
      text: 'Are You Want To Change Status!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Change it!'
    }).then((result) => {
      return result.isConfirmed;
    });

    if (!isConfirm) {
      return;
    }

    try {
      const response = await dashboardApi.get(`get-salary-status/${empId}`);
      if (response.data.status === 200) {
        const currentStatus = response.data.data;
        const newStatus = currentStatus === 1 ? 0 : 1;

        const updateResponse = await dashboardApi.post('salary-status', {
          id: empId,
          status: newStatus,
        });

        if (updateResponse.data.status === 200) {
          getsalary((prevState) =>
            prevState.map((salaryObj) =>
              salaryObj.id === empId ? { ...salaryObj, status: newStatus } : salaryObj
            )
          );
          Swal.fire({
            icon: 'success',
            text: 'Pls Press Save Button to Submit Final Salary',
          });
          fetchsalary();
          fetchsavedData();
        } else {
          console.error('Error updating status:', updateResponse.data.message);
        }
      } else {
        console.error('Error fetching current status:', response.data.message);
      }
    } catch (error) {
      console.error('Error while toggling status:', error);
    }
  };

  const handleCheckboxChange = async (attrId) => {
    setSelectedCheckboxes((prevCheckboxes) => {
      const updatedCheckboxes = [...prevCheckboxes];
      const index = updatedCheckboxes.indexOf(attrId);

      if (index === -1) {
        updatedCheckboxes.push(attrId);
      } else {
        updatedCheckboxes.splice(index, 1);
      }
      

      const updateData = async () => {
        try {
          const response = await dashboardApi.post('salary-calc', {
            package: packagedetails,
            selectedCheckboxes: updatedCheckboxes,
            metrocity:metrocity,
            
          });

          if (response.data.status === 200) {
            setSavedData(response.data.data);
            setsenddata(response.data.data);
          } else {
            console.error('Error calling salary-calc API:', response.data.message);
          }
        } catch (error) {
          console.error('Error calling salary-calc API:', error);
        }
      };

      updateData();

      return updatedCheckboxes;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const attributesData = formData.map((attr) => ({
      id: attr.id,
      value: textValues[attr.id] || getSavedAmount(attr.id),
      //deduct_from_emp_salary: selectedCheckboxes.includes(`contribution_${attr.id}`) ? 1 : 0,
    }));
  
    const formDataObj = new FormData();
    formDataObj.append('package_detail', packagedetails);
    formDataObj.append('emp_id', id);
    formDataObj.append('created_by',employee_id)
    formDataObj.append('metrocity', metrocity ? 1 : 0);
    formDataObj.append('tds', tdsvalue);
    formDataObj.append('attributes', JSON.stringify(attributesData));
  
    try {
      const response = await dashboardApi.post('salary_calculate', formDataObj);
  
      if (response.data.status === 200) {
        Swal.fire({
          icon: 'success',
          text: response.data.message,
        });
        fetchsalary();
        get_gross_and_net_salary();
      }

       else {
        Swal.fire({
          icon: 'error',
          text: response.data.message || 'Failed to save salary details',
        });
      }
    } catch (error) {
      console.error('Error submitting salary details:', error);
      Swal.fire({
        icon: 'error',
        text: 'An error occurred while saving salary details. Please try again.',
      });
    }
  };
  

  

  useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }
  }, [token, navigate]);

   const checkmetrocity = (checked) => {
    setmetrocity(checked);
  };
  const setcalculatestatuschange = (checked)=>{
    setcalculatestatus(checked);

  }



  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <EmployeeDetailsHeader id={id} />
            <Card>
              <div className="card-body">
                <Link className='btn btn-primary btn-sm mb-2 float-end' to={"/employee-list"}>
                  Back
                </Link>
                <div className="container">
                  <form onSubmit={handleSubmit}>
                  <div className='row'>
                   {/* <div className="form-group col-md-4">
                      <label htmlFor="package" style={{ fontSize: '16px' }}>Calculate Salary Automatically Based On Ctc</label>
                      <input
                        type="checkbox"
                        className="form-control"
                        placeholder="Enter CTC"
                         checked={calculatestatus}
                        onChange={(e) => setcalculatestatuschange(e.target.checked)}
                      />
                    </div> */}

                    <div className="form-group col-md-4">
                      <label htmlFor="package" style={{ fontSize: '16px' }}>CTC</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter CTC"
                        value={packagedetails}
                        onChange={(e) => setPackagedetails(e.target.value)}
                      />
                    </div>

                     <div className="form-group col-md-2">
                      <label htmlFor="package" style={{ fontSize: '16px' }}>Metro City Emp</label>
                      <input
                        type="checkbox"
                        className="form-control"
                        placeholder="Enter CTC"
                         checked={metrocity}
                        onChange={(e) => checkmetrocity(e.target.checked)}
                      />
                    </div>
                    </div>
                    <div className="col-md-12">
                      <div className="row">
                        {formData &&
                          formData.map((attr, index) => (
                            <div key={attr.id} className="col-md-4">
                              <div className="form-group">
                                <label>
                                <h6>
                                  {attr.attribute} (
                                 {
                                (attr.percentage !== null && attr.percentage !== '') ? `${attr.percentage}%` :
                               (attr.amount !== null && attr.amount !== '') ? `${attr.amount} RS` :
                                ''
                               }

                                  )
                                  </h6>
                                </label>
                                {attr.is_deduction_attribute == 1 && attr.is_contribution_attribute != 1 && (
                                  <React.Fragment>
                                    <input
                                      type="checkbox"
                                      id={`SalaryCheckbox${attr.id}`}
                                      checked={selectedCheckboxes.includes(attr.id)}
                                      onChange={() => handleCheckboxChange(attr.id)}
                                    />
                                  </React.Fragment>
                                )}

                                  {/* {attr.is_deduction_attribute == 0 && attr.is_contribution_attribute == 1 && (
                                  <React.Fragment>
                                <input
                                   type="checkbox"
                                   id={`ContributionCheckbox${attr.id}`}
                                   checked={selectedCheckboxes.includes(`contribution_${attr.id}`)}
                                   onChange={() => handleCheckboxChange(`contribution_${attr.id}`)}
                               />
                                 <label htmlFor={`ContributionCheckbox${attr.id}`} style={{ marginLeft: '5px' }}>
                                   <h6>From Salary</h6>
                                  </label>
                               </React.Fragment>
                              )} */}
                               <input
                                  type="text"
                                  placeholder="Enter Amount"
                                  value={textValues[attr.id] || getSavedAmount(attr.id)}
                                  onChange={(e) => {
                                  setTextValues((prevTextValues) => ({
                                   ...prevTextValues,
                                  [attr.id]: e.target.value,
                                  }));
                                    }}
                                 className="form-control"
                                 readOnly
                                />

                              </div>
                            </div>
                          ))}
                        {tds &&

                          <div key={tds.id} className="col-md-4">
                            <div className="form-group">
                              <label>{tds.attribute}(Yearly)</label>

                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter TDS"
                                value={tdsvalue}
                                onChange={(e) => settdsvalue(e.target.value)}
                              />
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 text-center mt-5">
                        <button
                          variant="primary"
                          className="mt-2 btn btn-info btn-sm"
                          size="lg"
                          block="block"
                          type="submit"
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </form>

                  <center>Salary Details</center>
                  {loading ? (
                    <p>Loading...</p>
                  ) : (
                    <table className="table table-bordered table-hovered">
                      <thead>
                        <tr>
                          <th>S.No</th>
                          <th>Attribute</th>
                          <th>Amount Per Anum</th>
                          <th>Amount Per Month</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {salary.map((sal, index) => (
                          <tr key={sal.id}>
                            <td>{index + 1}</td>
                            <td>{sal.attribute}</td>
                            <td>{sal.amount_per_anum}</td>
                            <td>{sal.amount_per_month}</td>
                            <td>
                              <button
                                type="button"
                                className={`btn btn-sm btn-${sal.status === 1 ? 'success' : 'danger'}`}
                                onClick={() => toggleStatus(sal.id)}
                              >
                                {sal.status === 1 ? 'Active' : 'Inactive'}
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                  <div>
                    
                  </div>
                  <div className='row'>
                  <div className='col-md-12'>
                  <center>Salary Description</center>
                  <table className="table table-bordered table-hovered">
                     <tr>
                      <td>Attribute</td>
                      <td>Per Anum</td>
                      <td>Per Month</td>
                   </tr>
                    <tr>
                      <td>Gross Salary</td>
                      <td>{salary_description.gross_salary_anum}</td>
                      <td>{salary_description.gross_salary_month}</td>

                    </tr>

                    <tr>
                      <td>Deduction</td>
                      <td>{salary_description.deduction_per_anum}</td>
                      <td>{salary_description.deduction_per_month}</td>

                    </tr>

                    <tr>
                      <td>Net Salary</td>
                      <td>{salary_description.net_salary_anum}</td>
                      <td>{salary_description.net_salary_month}</td>

                    </tr>

                  </table>

                  </div>
                  </div>
                </div>
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default SallaryInfo;