import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import dashboardApi from "layouts/common_components/apibase_url";
import Pagination from 'react-bootstrap/Pagination';
import ReactPaginate from 'react-paginate';
import Modal from 'react-modal';
import { useNavigate,Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import  secureLocalStorage  from  "react-secure-storage";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

function RosterChangeRequest() {
  const token = secureLocalStorage.getItem("token");
  const emp_id = secureLocalStorage.getItem("emp_id");
  const [roster, setRoster] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [number, setNumber] = useState(1);
  const navigate = useNavigate();
  const [openmodal,setopenmodal] = useState(false);
  const [status,setstatus] = useState('');
  const [old_date,set_old_date] = useState('');
  const [new_date,set_new_date] = useState('');
  const [id,set_id] = useState('');
  const [employee_id,set_employee_id] = useState('');
   //const [validationErrors,setValidationErrors] = useState({});

  useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }
  }, [token, navigate]);

  useEffect(() => {
    fetchRoster();
  }, [currentPage]);

  const fetchRoster = async () => {
    try {
      const response = await dashboardApi.post('roster-change-request-list', {
        emp_id: emp_id,
        per_page: 10, 
        page: currentPage + 1,
      });

      if (response.data.status === 200) {
        const responseData = response.data.data;
        const employeeData = responseData.data || responseData;
        setRoster(employeeData);
        setNumber(response.data.last_page);
      } else {
        console.error("Error fetching:", response.data.message);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching:", error);
      setLoading(false);
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage.selected);
  };

  const openpopup = (old_date,new_date,id,emp_ids)=>{
    set_old_date(old_date);
    set_new_date(new_date);
    set_employee_id(emp_ids)
    set_id(id)
    setopenmodal(true);
}

const closepopup = ()=>{
  setstatus('');
  setopenmodal('');
  setValidationErrors({})

}

const savedata = async()=>{
   const errors = {};
    if(!status){
        errors.status = 'Status is Required';

    }
     setValidationErrors(errors);

    if (Object.keys(errors).length === 0) {
        const formData = new FormData();
        formData.append('emp_id',employee_id);
        formData.append('old_date',old_date);
        formData.append('new_date',new_date);
        formData.append('id',id);
        formData.append('action_by',emp_id);
        formData.append('status',status);


        try {
            const response =  await dashboardApi.post('update-roster-request', formData);
          if (response.data.status === 200) {
            Swal.fire({
              icon: 'success',
              text: response.data.message,
            });
            fetchRoster();
            closepopup();
            //fetchEmployees()
    
          }
          else {
            Swal.fire({
              icon: 'error',
              text: response.data.message || 'An error occurred',
            });
          }
        } catch (error) {
          Swal.fire({
            icon: 'error',
            text: 'An error occurred while creating',
          });
        } finally {
          //setIsSubmitting(false);
        }

     }


}

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <div className="card-body">
                {loading ? (
                  <p>Loading...</p>
                ) : (
                  <table className="table table-bordered table-hovered">
                    <thead>
                      <tr>
                        <td>S.No</td>
                        <td>Name</td>
                        <td>Old Date</td>
                        <td>New Date</td>
                        <td>Reason</td>
                        <td>Status</td>
                        <td>Action</td>
                      </tr>
                    </thead>
                    <tbody>
                      {roster.map((row, index) => (
                        <tr key={row.id}>
                          <td>{index + 1}</td>
                          <td>{row.emp_fname} {row.emp_lame}</td>
                          <td>{row.old_date}</td>
                          <td>{row.new_date}</td>
                          <td style={{minWidth:'10px'}}>{row.reason}</td>
                          <td>{row.status}</td>
                          <td>
                          <button className='btn btn-info btn-sm' onClick={() => openpopup(row.old_date,row.new_date,row.id,row.emp_id)}>Action</button>

                          </td>

                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
                {roster.length > 0 && (
                  <Pagination className="custom-pagination">
                    <ReactPaginate
                      previousLabel={"Previous"}
                      nextLabel={"Next"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={number}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={handlePageChange}
                      containerClassName={"pagination"}
                      activeClassName={"active"}
                      pageLinkClassName={"page-link-style"}
                      breakLinkClassName={"break-link-style"}
                      previousLinkClassName={"prev-link-style"}
                      nextLinkClassName={"next-link-style"}
                    />
                  </Pagination>
                )}
              </div>
            </Card>
             <Dialog
            open={openmodal}
        //onClose={closeModalHandler}
        fullWidth
        PaperProps={{ style: { maxWidth: 'md', padding: '40px', height:'300px'} }}
      >
        <DialogTitle>Action On Request</DialogTitle>
        <DialogContent>
          <form>
           <div className='container'>
            <div className="row">
                <div className="col-md-12">
                <label htmlFor="short name" style={{ fontSize: '16px',height:'20px' }}>Select Status</label>
                   <select
                    className = 'form-control'
                    value={status}
                    onChange={(e) => setstatus(e.target.value)}
                    >
                   <option value=''>Select Status</option>
                    <option value = '1'>Approved</option>
                    <option value='2'>Rejected</option>
                   </select>
                   {validationErrors.status && (
                        <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.status}</div>
                     )}

                </div>

            </div>
            </div>
          </form>
        </DialogContent>
        <DialogActions>
          <button className="btn btn-danger" onClick ={closepopup} >
            Close
          </button>
          <button className="btn btn-info" onClick={savedata} >
            Save
          </button>
        </DialogActions>
      </Dialog>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default RosterChangeRequest;
