import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import dashboardApi from "layouts/common_components/apibase_url";
import Pagination from 'react-bootstrap/Pagination';
import ReactPaginate from 'react-paginate';
import  secureLocalStorage  from  "react-secure-storage";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";

function Roster() {
  const token = secureLocalStorage.getItem('token');
  const navigate = useNavigate();
  const [validationErrors, setValidationErrors] = useState({});
  const [validationErrors1, setValidationErrors1] = useState({});
  const emp_id = secureLocalStorage.getItem('emp_id');
  const[option, setoption] = useState([]);
  const[employee,setemployee] = useState('');
  const[starttime,setstarttime ] = useState('');
  const[endtime,setendtime ] = useState('');
  const[weekoff,setweekoff] = useState('');
  const[roster,getroster] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [number, setNumber] = useState(1); 
  const [postPerPage] = useState(10);
   const [openModal, setOpenModal] = useState(false);
   const [id,setid] = useState('');
  useEffect(() => {
   if (!token) {
    navigate("/sign-in");
   // return null;
  }
  }, [token, navigate]);
   useEffect(() => {
  const fetchData = async () => {
    try {
      const endpoint = `manager-employee/${emp_id}`;
      const response = await dashboardApi.get(endpoint);
      //console.log(response);

      if (response.data.status === 200) {
        setoption(response.data.data);
      } else {
        console.error('Error fetching data:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  fetchData();
}, []);
 useEffect(()=>{
        fetchroster(); 
    },[currentPage])
   const  fetchroster = async () => {
    try {
       const response = await dashboardApi.post('roster-list', {
        id: emp_id,
        per_page:postPerPage,
        page: currentPage + 1,
      });

      if (response.data.status === 200) {
        const responseData = response.data.data;
        const employeeData = responseData.data || responseData;
        getroster(employeeData);
        setNumber(response.data.last_page);
      } else {
        console.error('Error fetching:', response.data.message);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching:', error);
      setLoading(false);
    }
  };
const saveroster = async (e) => {
    e.preventDefault();
    const errors = {};
    if (!employee) {
      errors.employee = 'Employee is required';
    }
    if (!weekoff) {
      errors.week_off = 'Week Off is required';
    }
    setValidationErrors(errors);

    if (Object.keys(errors).length === 0) {
    const formData = new FormData()
    formData.append('employee', employee);
    formData.append('week_off', weekoff);
    formData.append('emp_id', emp_id);
    try {
      const response = await dashboardApi.post('create-roster', formData);

      if (response.data.status === 200) {
        //console.log('iiii');
        Swal.fire({
          icon: 'success',
          text: response.data.message,
        });
          fetchroster();
          setemployee('');
          setstarttime('');
          setendtime('');
          setweekoff('');
         //navigate("/emp-type-list")
      } else if (response.data.status === 400) {
        // Display validation errors below the input fields
        setValidationErrors(response.data.messages);
      }
      else if(response.data.status === 500) {
        // Display validation errors below the input fields
        Swal.fire({
          icon: 'error',
          text: response.data.message || 'An error occurred',
        });
      }

       else {
        Swal.fire({
          icon: 'error',
          text: response.data.message || 'An error occurred',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'An error occurred',
      });
    }
  };
}
const editroster = async(id)=>{
   setid(id)
;
     try {
        const endpoint = `edit-roster/${id}`;
        const response = await dashboardApi.get(endpoint);
       if (response.data.status === 200) {
        //console.log(response);
          //alert(response.data.data[0].approval_remark);
      //const assignToValues = response.data.data.map(item => item.assign_to);
      setweekoff(response.data.data.week_off);
      setstarttime(response.data.data.start_time);
      setendtime(response.data.data.end_time);
      //setQuantity(response.data.data.quantity);
      //setselectedemployee(assignToValues);
        } else {
          console.error('Error fetching data:');
        }
       setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
   // seteditmodal(true);

  setOpenModal(true);

 
}
const closemodel = ()=>{
  setweekoff('');
  setOpenModal(false);
  setValidationErrors1({})
}

const updateroster = async(e)=>{
   e.preventDefault();
    const errors = {};
    if (!weekoff) {
      errors.week_off = 'Week Off is required';
    }
    setValidationErrors1(errors);
     if (Object.keys(errors).length === 0) {
    const formData = new FormData()
    formData.append('week_off', weekoff);
    try {
      const response =  await dashboardApi.post(`update-roster/${id}`, formData);
      if (response.data.status === 200) {
        //console.log('iiii');
        Swal.fire({
          icon: 'success',
          text: response.data.message,
        });
          fetchroster();
          setstarttime('');
          setendtime('');
          setweekoff('');
          setOpenModal(false);
         //navigate("/emp-type-list")
      } else if (response.data.status === 400) {
        // Display validation errors below the input fields
        setValidationErrors1(response.data.messages);
      }
      else if(response.data.status === 500) {
        // Display validation errors below the input fields
        Swal.fire({
          icon: 'error',
          text: response.data.message || 'An error occurred',
        });
      }

       else {
        Swal.fire({
          icon: 'error',
          text: response.data.message || 'An error occurred',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'An error occurred',
      });
    }
  };


}
const toggleStatus = async (rosterid) => {
  const isConfirm = await Swal.fire({
            title: 'Are you sure?',
            text: "Are You Want To Change Status!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Change it!'
          }).then((result) => {
            return result.isConfirmed
          });

          if(!isConfirm){
            return;
          }
    const response = await dashboardApi.get(`roster-status/${rosterid}`);
    if (response.data.status === 200) {
      const currentStatus = response.data.data;
      const newStatus = currentStatus === 1 ? 0 : 1;
      const updateResponse = await dashboardApi.post('roster-change-status', {
        roster_id: rosterid,
        status: newStatus,
      });
      if (updateResponse.data.status === 200) {
        // Update the local state with the new status
        getroster((prevState) =>
          prevState.map((rosterobj) =>
            rosterobj.id === rosterid ? { ...rosterobj, status: newStatus } : rosterobj
          )
        );
      } else {
        console.error('Error updating status:', updateResponse.data.message);
      }
    } else {
      console.error('Error fetching current status:', response.data.message);
    }
  };
const handlePageChange = (newPage) => {
    setCurrentPage(newPage.selected);
  };
 

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <div className="card-body">
               <form onSubmit={saveroster}>
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-md-5">
                      <div className="form-group">
                          <label htmlFor="country" style={{ fontSize: '16px'}}>Select Employee</label>
                          <select className="form-control"
                           value={employee}
                           onChange={(e) => setemployee(e.target.value)}
                            >
                            <option value=''>Select Employee</option>
                            {option.map((options) => (
                             <option key={options.emp_id} value={options.emp_id}>
                              {options.emp_fname} {options.emp_lame}
                              </option>
                              ))}
                              
                          </select>
                          {validationErrors.employee&& (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.employee}</div>
                             )}
                        </div>

                      </div>
                         <div className="col-md-5">
                         <div className="form-group">
                         <label htmlFor="country" style={{ fontSize: '16px'}}>Week Off</label>
                          <input type="Date"  className="form-control"
                           value={weekoff}
                           onChange={(e) => setweekoff(e.target.value)}
                          />
                         {validationErrors.week_off&& (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.week_off}</div>
                             )}
                          </div>
                          </div>
                         

                      
                          <div className="col-md-2 text-center mt-5">
                           <button variant="primary" className="mt-2 btn btn-info btn-sm" size="lg" block="block" type="submit">
                              Save
                            </button>
                         </div>
                         
                        
                      </div>
                      </div>
                  
                      
                </form>
                 <center>Roster Details</center>
                {loading ? (
                  <p>Loading...</p>
                ) : (
                  <table className="table table-bordered table-hovered">
                 <tr>
                  <td>S.No</td>
                  <td>Emp Name</td>
                  <td>Week off</td>
                  <td>Status</td>
                  <td>Action</td>
               </tr>
               {roster.map((row, index) => (
                <tr key={row.id}>
                  <td>{index + 1}</td>
                  <td>{row.emp_fname} {row.emp_lame}</td>
                  <td>{row.week_off}</td>
                  <td>
                  <button
                  type="button"
                  className={`btn btn-sm btn-${row.status === 1 ? 'success' : 'danger'}`}
                  onClick={() => toggleStatus(row.id)}
                  >
                  {row.status === 1 ? 'Active' : 'Inactive'}
                 </button>
                  </td>
                  <td><button className="btn btn-sm btn-info" onClick={() => editroster(row.id)}>Edit</button></td>

               </tr>
              ))}

            </table>
             )}
               {roster.length > 0 && (
                <Pagination className="custom-pagination">
                      <ReactPaginate
                      previousLabel={"Previous"}
                      nextLabel={"Next"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={number}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={handlePageChange}
                      containerClassName={"pagination"}
                      activeClassName={"active"}
                      pageLinkClassName={"page-link-style"}
                      breakLinkClassName={"break-link-style"}
                      previousLinkClassName={"prev-link-style"}
                      nextLinkClassName={"next-link-style"}
                    />
                  </Pagination>
               )}
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
      <Dialog
        open={openModal}
        
        fullWidth
        PaperProps={{ style: { maxWidth: 'xl', padding: '40px', height:'1900x'} }}
      >
        <DialogTitle>Edit Roster</DialogTitle>
        <DialogContent>
          <form>
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <label htmlFor="country" style={{ fontSize: '16px'}}>Week Off</label>
                          <input type="Date"  className="form-control"
                           value={weekoff}
                           onChange={(e) => setweekoff(e.target.value)}
                          />
                         {validationErrors1.week_off&& (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors1.week_off}</div>
                             )}
                </div>
                </div>
            </div>
          </form>
        </DialogContent>
        <DialogActions>
          <button className="btn btn-danger" onClick ={closemodel}>
            Close
          </button>
          <button className="btn btn-info" onClick={updateroster}>
            Save
          </button>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
}

export default  Roster;
