import React, { useState, useEffect } from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
// Material Dashboard 2 React example components
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
// Data
import { Link } from 'react-router-dom';
import "layouts/superadmin/components/global.css";

import dashboardApi from "layouts/common_components/apibase_url";
import { useNavigate } from 'react-router-dom';
import ClientDetailsHeader from 'layouts/common_components/client_details_header';
import secureLocalStorage from "react-secure-storage";
import "react-datepicker/dist/react-datepicker.css";
import Pagination from 'react-bootstrap/Pagination';
import ReactPaginate from 'react-paginate';

function InActiveClient() {
  const navigate = useNavigate();
  const emp_id = secureLocalStorage.getItem('emp_id');
  const token = secureLocalStorage.getItem('token');
  const department = secureLocalStorage.getItem('department');
  const [state, setState] = useState([]);
  const [loading, setLoading] = useState(false);
  const [clientDetails, setClientDetails] = useState([]);
  const [exeDetails, setExeDetails] = useState([]);
  const [selectedEmp, setSelectedEmp] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [number, setNumber] = useState(1);
  const [postPerPage,setPostPerPage] = useState(10);
  const [selectedMobileNo, setClientMobileNumber] = useState();
  const [selectedCompanyName, setClientCompanyName] = useState();

  const [groupDetails, setGroupDetails] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState('');

  const [product, setProduct] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState();
  const [service, setService] = useState([]);
  const [selectedService, setSelectedService] = useState();

  useEffect(() => {
    getProduct();
    getExeDetails();
    getGroupDetails();
    getClientDetails();
  }, []);

  // useEffect(() => {
  //   getClientDetails();
  // }, [currentPage,selectedMobileNo,selectedCompanyName,selectedGroup,selectedEmp])

  const getProduct = async () => {
  try {
    const response = await dashboardApi.get(`sales-product-list/active`);
    if (response.data.status === 200) {
      setProduct(response.data.data);
    }
    else {
      console.error('Error fetching countries:', response.data.message);
    }
    setLoading(false);
  }
  catch (error) {
    console.error('Error fetching countries:', error);
    setLoading(false);
  }
};

useEffect(() => {
  if (selectedProduct) {
    const fetchService = async () => {
      try {
        const response = await dashboardApi.get(`get-service-list-by-product-id/${selectedProduct}`);
        if (response.data.status === 200) {
          setService(response.data.data);
        } else {
          console.error('Error fetching countries:', response.data.message);
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching countries:', error);
        setLoading(false);
      }
    }
    fetchService();
  }
}, [selectedProduct]);

  // const getClientDetails = async () =>
  // {
  //   setLoading(true);
  //   try
  //   {
  //     const response = await dashboardApi.get(`get-inactive-client-details`, {
  //       params: {
  //         emp_id: emp_id,
  //         per_page: postPerPage,
  //         page: currentPage + 1,
  //         mobileNo: selectedMobileNo,
  //         companyName: selectedCompanyName,
  //         selectedGroup: selectedGroup,
  //         executive:selectedEmp,
  //         product:selectedProduct,
  //         service:selectedService
  //       }
  //     });

  //     if (response.data.status === 200)
  //     {
  //       const responseData = response.data.data;
  //       const clientData = responseData.data || responseData;
  //       setClientDetails(clientData);
  //       setNumber(responseData.last_page);
  //       setLoading(false);
  //     } else {
  //       console.error('Error fetching countries:', response.data.message);
  //     }
  //     setLoading(false);
  //   } catch (error) {
  //     console.error('Error fetching countries:', error);
  //     setLoading(false);
  //   }
  // }

  const getClientDetails = async () => {
    setLoading(true);
    try {
      const response = await dashboardApi.get(`get-inactive-client-details`, {
        params: {
          emp_id,
          per_page: postPerPage,
          page: currentPage + 1, // API expects pages to start from 1
          mobileNo: selectedMobileNo,
          companyName: selectedCompanyName,
          selectedGroup,
          executive: selectedEmp,
          product: selectedProduct,
          service: selectedService,
        },
      });

      if (response.data.status === 200) {
        const responseData = response.data.data;
        const clientData = responseData.data || responseData;
        setClientDetails(clientData);
        setNumber(responseData.last_page);
      } else {
        console.error("Error fetching client details:", response.data.message);
      }
    } catch (error) {
      console.error("Error fetching client details:", error);
    } finally {
      setLoading(false);
    }
  };

  const getGroupDetails = async () => {
    try {
      const response = await dashboardApi.get(`get-group-list`, {
        params: {
          emp_id: emp_id,
        }
      });
      if (response.data.status === 200)
      {
        setGroupDetails(response.data.data);
      }
      else
      {
        console.error('Error fetching countries:', response.data.message);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching countries:', error);
      setLoading(false);
    }
  }
  //console.log(number);

 
  const getExeDetails = async () => {
    try {
      const response = await dashboardApi.get(`get-executive-details/${emp_id}`);
      if (response.data.status === 200) {
        setExeDetails(response.data.data);
      } else {
        console.error('Error fetching countries:', response.data.message);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching countries:', error);
      setLoading(false);
    }
  }
  

  useEffect(() => {
    getClientDetails();
  }, [currentPage]);

  const handlePageClick = (selectedItem) => {
    setCurrentPage(selectedItem.selected); // Update currentPage
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3} pb={3}>
        <Card>
          <div className="card-body p-2">
            <div className="d-flex justify-content-between align-items-center">
              <ClientDetailsHeader></ClientDetailsHeader>
            </div>
            <div className='d-flex flex-wrap px-sm-0 px-2'>

              <div className='col-sm-1 d-flex my-2 flex-column  px-sm-2 px-1'>
                <small className='m-0'>Length</small>
                <select className="form-control"
                  value={postPerPage}
                  onChange={(e) => setPostPerPage(e.target.value)}
                >
                  <option value='10'>10</option>
                  <option value='20'>20</option>
                  <option value='30'>30</option>
                  <option value='50'>50</option>
                  <option value='100'>100</option>
                  <option value='200'>200</option>
                  <option value='500'>500</option>
                </select>
              </div>

              <div className='col-sm-2 d-flex my-2 flex-column px-sm-2 px-1'>
                <small>Mobile No</small>
                <input type="text" className='form-control' value={selectedMobileNo} onChange={(e) => { setClientMobileNumber(e.target.value); }} />
              </div>
              <div className='col-sm-2 d-flex my-2 flex-column px-sm-2 px-1'>
                <small>Company Name</small>
                <input type="text" className='form-control' value={selectedCompanyName} onChange={(e) => { setClientCompanyName(e.target.value); }} />
              </div>
              <div className='col-sm-2 d-flex my-2 flex-column px-sm-2 px-1'>
                <small>Select Eexecutive</small>
                <select className="form-control"
                  value={selectedEmp}
                  onChange={(e) => {
                    setSelectedEmp(e.target.value);
                    
                  }}
                >
                  <option value=''>Select Eexecutive</option>
                  {exeDetails.map((ed) => (
                    <option key={ed.emp_id} value={ed.emp_id}>
                      {ed.emp_fname} {ed.emp_lname}
                    </option>
                  ))}
                </select>
              </div>

              <div className="col-sm-2 d-flex my-2 flex-column  px-sm-2 px-1">
                <small>Salect Product</small>
                <select className="form-control" value={selectedProduct}
                  onChange={(e) => setSelectedProduct(e.target.value)}>
                  <option value=''>Select Product</option>
                  {product.map((pro) => (
                    <option key={pro.id} value={pro.id}>
                      {pro.product_name}
                    </option>
                  ))}
                </select>
              </div>

              {selectedProduct && (
                <div className="col-sm-2 d-flex my-2 flex-column  px-sm-2 px-1">
                  <small>Select Service</small>
                  <select className="form-control"
                    value={selectedService}
                    onChange={(e) => setSelectedService(e.target.value)}
                  >
                    <option value=''>Select Service</option>
                    {service.map((ser) => (
                      <option key={ser.id} value={ser.id}>
                        {ser.service_name}
                      </option>
                    ))}
                  </select>
                </div>
              )}


              <div className='col-sm-2 d-flex my-2 flex-column px-sm-2 px-1'>
                <small>Select Group</small>
                <select className="form-control"
                  value={selectedGroup}
                  onChange={(e) => {
                    setSelectedGroup(e.target.value);
                    //setCurrentPage(0);
                  }}
                >
                  <option value=''>Select Group</option>
                  {groupDetails && groupDetails.map((gd) => (
                    <option key={gd.group_id} value={gd.group_id}>
                      {gd.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className='col-sm-1 d-flex my-2 flex-column justify-content-end  px-sm-2 px-1'>
                  <span className="btn btn-primary btn-sm" onClick={getClientDetails}>Search</span>
              </div>

            </div>


            {loading ? (
              <p style={{ align: 'center' }}>
                <img src={`${process.env.PUBLIC_URL}/newfileloding.gif`} style={{ height: '80px', width: 'auto' }}></img>
              </p>
            ) : (
              <div className='p-2' style={{ overflowY: "hidden" }}>
                <table className="table table-bordered table-hovered" style={{ marginTop: '20px' }}>

                  <tr>
                    <td>S.No</td>
                    <td>Client Info</td>
                    <td>Company Info</td>
                    <td>Product</td>
                    <td>Service</td>
                    <td>Group Name</td>
                    <td>City Name</td>
                    <td>Status</td>
                    <td>Executive</td>
                    <td>Action </td>
                  </tr>

                  {clientDetails && clientDetails.map((cd, index) => (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{cd.client_name}<br />{cd.mobile_no} <br /> {cd.email}</td>
                      <td>{cd.business_name}<br />{cd.comp_mobile_no} <br /> {cd.comp_email}</td>
                      <td>{cd.product_name}</td>
                      <td>{cd.service_name}</td>
                      <td>{cd.group_name}</td>
                      <td>{(cd.city_name != '' && cd.city_name != null) ? cd.city_name : cd.city}</td>
                      <td>{cd.status === 3 ? <span style={{color:'red'}}>InActive</span> : ''}</td>
                      <td>{cd.emp_name}</td>
                      <td>
                        <Link className="btn btn-primary mb-2 float-end" to={`/mature-client-profile/${cd.client_id}`}>
                          <span class="material-icons-outlined" alt='Profile'>manage_accounts</span>
                        </Link>
                      </td>
                    </tr>
                  ))
                  }
                     
                </table>
                <div>
                  {/* Render client details */}
                  <Pagination className="custom-pagination">
                    <ReactPaginate
                      previousLabel={"Previous"}
                      nextLabel={"Next"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={number}
                      marginPagesDisplayed={2}s
                      pageRangeDisplayed={5}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination"}
                      activeClassName={"active"}
                      pageLinkClassName={"page-link-style"}
                      breakLinkClassName={"break-link-style"}
                      previousLinkClassName={"prev-link-style"}
                      nextLinkClassName={"next-link-style"}
                      forcePage={currentPage} // Ensure the correct button is highlighted
                    />
                  </Pagination>
                  {loading && <p>Loading...</p>}
                </div>
              </div>
            )}

          </div>
        </Card>
      </MDBox >
    <Footer />
    </DashboardLayout >
  );
}
export default InActiveClient;
