import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Link, Navigate } from 'react-router-dom';
import { Bar } from 'react-chartjs-2';
import MDBox from 'components/MDBox';
import DashboardLayout from 'theme_layout/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'theme_layout/Navbars/DashboardNavbar';
import Footer from 'theme_layout/Footer';
import 'react-toastify/dist/ReactToastify.css';
import dashboardApi from 'layouts/common_components/apibase_url';
import reportsLineChartData from 'layouts/dashboard/data/reportsLineChartData';
import secureLocalStorage from 'react-secure-storage';
import { useNavigate } from 'react-router-dom';
import { useAbsoluteLayout } from 'react-table';
import { ToastContainer } from 'react-toastify';


//import { Bar } from 'react-chartjs-2';
//import totalcollection from '../assets/images/totalcollection.png';

const HrDashboard = () => {
  const navigate = useNavigate();
  const { sales } = reportsLineChartData;
  const [productlist, setProductlist] = useState([]);
  const empId = secureLocalStorage.getItem('emp_id');
  const dept_id = secureLocalStorage.getItem('department_id');
  const [compersionFilter, setCompersionFilter] = useState(false);
  const [cscompresion_data,getcs_compersion_data] = useState('');
  //const [color,setcolor] = useState('');
  const [start_date, setstart_date] = useState('');
  const [end_date, setend_date] = useState('');
  
  const [departmentlist,getdepartmentlist] = useState([]);
  const [emptypelist,setemptypelist] = useState([]);
  const [branchlist,getbranchlist] = useState([]);
  const [selecteddepartment,setselecteddepartment] = useState('');
  const [emplist,setemplist] = useState([]);
  const [selectedemployee,setselectedemployee] = useState('');
  const [selectedbranch,setselectedbranch] = useState('');
  const [selectedemptype,setselectedemptype] = useState('');
  const [datalist,setdatalist] = useState([]);
  const [bardata,setbardata] = useState([]);
  const [compersion_filter,get_compersion_filter_data] = useState('');
  //const []

  useEffect(()=>{
    departmentdetails();
    emp_type_list();
    branch_details();
    get_data();
    bar_chart_data();


  },[])
  useEffect(()=>{

    department_employee();
  },[selecteddepartment])

  useEffect(()=>{
    get_data_filter();

  },[selectedbranch,selecteddepartment,selectedemployee,selectedemptype,start_date,end_date])

  useEffect(()=>{
    bar_chart_data_filter();

  },[selectedbranch,selecteddepartment,selectedemployee,selectedemptype,start_date,end_date])


  useEffect(()=>{
    compersion_filter_data();

  },[selectedbranch,selecteddepartment,selectedemployee,selectedemptype,start_date,end_date])

  useEffect(()=>{
    confirmation_notifications();

  },[dept_id ==5])
  

  const departmentdetails = async () => {
    try {
      const response = await dashboardApi.get('get-active-department');
      if (response.data.status === 200) {
        getdepartmentlist(response.data.data);
      } else {
        console.error('Error fetching data:', response.data.error);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


  const emp_type_list =  async()=>{
    try {
        const response = await dashboardApi.get('active-employee-type');
        if (response.data.status === 200) {
             setemptypelist(response.data.data);
        } else {
          console.error('Error fetching data:', response.data.error);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }

  }

  const branch_details  = async()=>{
    try {
        const response = await dashboardApi.get('get-active-branch');
        if (response.data.status === 200) {
             getbranchlist(response.data.data);
        } else {
          console.error('Error fetching data:', response.data.error);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }

  }
  const department_employee = async()=>{
    if(selecteddepartment){
    try {
      const response = await dashboardApi.get(`department-employee/${selecteddepartment}`);
      if (response.data.status === 200) {
           setemplist(response.data.data);
      } else {
        console.error('Error fetching data:', response.data.error);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  }

  const  get_data  = async()=>{
    try {
        const response = await dashboardApi.get('hr-dashboard-count');
        if (response.data.status === 200) {
             setdatalist(response.data.data);
        } else {
          console.error('Error fetching data:', response.data.error);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }

  }


  const  get_data_filter  = async()=>{
    if(!compersionFilter){
    try {
        const response = await dashboardApi.get('hr-dashboard-count', {
          params: {
           
            department:selecteddepartment,
            branch:selectedbranch,
            emp_type:selectedemptype,
            end_date:end_date,
            start_date:start_date,
            emp_id:selectedemployee,
           
          }
        });
        if (response.data.status === 200) {
             setdatalist(response.data.data);
        } else {
          console.error('Error fetching data:', response.data.error);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }

  }


  const  confirmation_notifications  = async()=>{
    if(dept_id ==5){
    try {
        const response = await dashboardApi.post('pending-confirmation-notification', {
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }

  }


  const bar_chart_data = async () => {
    try {
      const response = await dashboardApi.get('hr-dashboard-emp-count-graph')
      if (response.data.status === 200) {
        const responseData = response.data.data;
        console.log(responseData);
        setbardata(responseData);
      } else {
        console.error('Error fetching data:', response.data.error);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


  const bar_chart_data_filter = async () => {
    try {
      const response = await dashboardApi.get('hr-dashboard-emp-count-graph',{
        params: {
           
          department:selecteddepartment,
          branch:selectedbranch,
          emp_type:selectedemptype,
          end_date:end_date,
          start_date:start_date,
          emp_id:selectedemployee,
         
        }


      })
      if (response.data.status === 200) {
        const responseData = response.data.data;
        console.log(responseData);
        setbardata(responseData);
      } else {
        console.error('Error fetching data:', response.data.error);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
  }
  };
  const prepareChartData = () => {

    const labels = bardata.map(item => item.year);
    const data = bardata.map(item => item.count);
    //const get_year = bardata.map(item => item.year);

    

    return {
      labels: labels,
      datasets: [
        {
          label: 'Employee Count Yearly Basis',
          data: data,
          backgroundColor: 'blue',
          borderColor: 'rgba(75, 192, 192, 1)',
          borderWidth: 1,
        },
      ],
    }
  }





  const handleCheckboxChange = (checked) => {
    setCompersionFilter(checked);
  };

  const compersion_filter_data  = async()=>{
    if(compersionFilter){
    try {
        const response = await dashboardApi.get('hr-dashboard-count', {
          params: {
           
            department:selecteddepartment,
            branch:selectedbranch,
            emp_type:selectedemptype,
            end_date:end_date,
            start_date:start_date,
            emp_id:selectedemployee,
           
          }
        });
        if (response.data.status === 200) {
             get_compersion_filter_data(response.data.data);
        } else {
          console.error('Error fetching data:', response.data.error);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }

  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        <div>
          <div className="d-flex flex-wrap">
            <div className="col-sm-4 col-6 p-1">
              <select
                className="form-control form-select"
                value={selecteddepartment}
                onChange={(e) => setselecteddepartment(e.target.value)}
                aria-label="Default select example"
                style={{ width: '100%', padding: '8px', fontSize: '11px' }}
              >
                <option>Select Department</option>
                {departmentlist.map((row) => (
                  <option key={row.id} value={row.id}>
                    {row.department_name}
                  </option>
                ))}
              </select>
            </div>

            <div className="col-sm-4 col-6 p-1">
              <select
                className="form-control form-select"
                value={selectedemployee}
                onChange={(e) => setselectedemployee(e.target.value)}
                aria-label="Default select example"
                style={{ width: '100%', padding: '8px', fontSize: '11px' }}
              >
                <option>Select Emp</option>
                {emplist.map((row) => (
                  <option key={row.emp_id} value={row.emp_id}>
                    {row.emp_fname} {row.emp_lame}
                  </option>
                ))}
              </select>
            </div>

            <div className="col-sm-4 col-6 p-1">
              <select
                className="form-control form-select"
                value={selectedemptype}
                onChange={(e) => setselectedemptype(e.target.value)}
                aria-label="Default select example"
                style={{ width: '100%', padding: '8px', fontSize: '11px' }}
              >
                <option>Select Emp Type</option>
                {emptypelist.map((row) => (
                  <option key={row.id} value={row.id}>
                    {row.emp_type}
                  </option>
                ))}
              </select>
            </div>

            <div className="col-sm-4 col-6 p-1">
              <select
                className="form-control form-select"
                value={selectedbranch}
               onChange={(e) => setselectedbranch(e.target.value)}
                aria-label="Default select example"
                style={{ width: '100%', padding: '8px', fontSize: '11px' }}
              >
                <option value="">Select Branch</option>
                {branchlist.map((branch) => (
                  <option key={branch.id} value={branch.id}>
                    {branch.branch_name}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-sm-4 col-6 p-1">
              <input
                type="date"
               value={start_date}
               onChange={(e) => setstart_date(e.target.value)}
                className="form-control"
                style={{ width: '100%', padding: '8px', fontSize: '11px' }}
              />
            </div>
            <div className="col-sm-4 col-6 p-1">
              <input
                type="date"
                value={end_date}
                onChange={(e) => setend_date(e.target.value)}
                className="form-control"
                style={{ width: '100%', padding: '8px', fontSize: '11px' }}
              />
            </div>

          </div>
        </div>
          <div className="d-flex flex-wrap">


            <div className='col-sm-3 col-6 p-sm-2 p-1'>
           
                <div className="card align-items-center pt-3 h-100" >
                  <img src={`${process.env.PUBLIC_URL}/hr_dashboard/active_emp.png`} style={{ width: '100px', height: '100px' }} alt="group loss" />

                  <div class="card-body text-center">
                    <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>Total Active Employee</h5>
                    <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>{datalist.active_employee}</h5>
                   
                  </div>
                  {
                      compersionFilter && (
                        <h5 className="card-title" style={{ fontSize: '12px', color: 'black' }}>
                          <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>({start_date} {end_date}) {compersion_filter.active_employee}</h5>

                         
                        </h5>
                      )
                    }
                </div>
               
             

            </div>



            <div className='col-sm-3 col-6 p-sm-2 p-1'>
              
                <div className="card align-items-center pt-3 h-100" >
                  <img src={`${process.env.PUBLIC_URL}/hr_dashboard/salary.png`} style={{ width: '100px', height: '100px' }} alt="group loss" />
                  <div class="card-body text-center">
                    <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>Total Salaries</h5>
                    <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>{datalist.total_salary}</h5>
                   
                  </div>
                  {
                      compersionFilter && (
                        <h5 className="card-title" style={{ fontSize: '12px', color: 'black' }}>
                          <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>({start_date} {end_date}) {compersion_filter.total_salary}</h5>

                         
                        </h5>
                      )
                    }
                </div>
               
             

            </div>

            <div className='col-sm-3 col-6 p-sm-2 p-1'>
              
              <div className="card align-items-center pt-3 h-100" >
                <img src={`${process.env.PUBLIC_URL}/hr_dashboard/leave.png`} style={{ width: '100px', height: '100px' }} alt="group loss" />
                <div class="card-body text-center">
                  <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>Total Employee Leaves</h5>
                  <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>{datalist.leaves}</h5>
                 
                </div>
                {
                      compersionFilter && (
                        <h5 className="card-title" style={{ fontSize: '12px', color: 'black' }}>
                          <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>({start_date} {end_date}) {compersion_filter.leaves}</h5>

                         
                        </h5>
                      )
                    }
           
              </div>

          </div>

          <div className='col-sm-3 col-6 p-sm-2 p-1'>
              
              <div className="card align-items-center pt-3 h-100" >
                <img src={`${process.env.PUBLIC_URL}/hr_dashboard/work-from-home.png`} style={{ width: '100px', height: '100px' }} alt="group loss" />
                <div class="card-body text-center">
                  <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>Total Work From Home</h5>
                  <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>{datalist.total_wfh}</h5>
                 
                </div>
                {
                      compersionFilter && (
                        <h5 className="card-title" style={{ fontSize: '12px', color: 'black' }}>
                          <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>({start_date} {end_date}) {compersion_filter.total_wfh}</h5>

                         
                        </h5>
                      )
                    }
           
              </div>

          </div>

          <div className='col-sm-3 col-6 p-sm-2 p-1'>
              
              <div className="card align-items-center pt-3 h-100" >
                <img src={`${process.env.PUBLIC_URL}/hr_dashboard/roster.png`} style={{ width: '100px', height: '100px' }} alt="group loss" />
                <div class="card-body text-center">
                  <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>Roster</h5>
                  <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>{datalist.roster}</h5>
                 
                </div>
                {
                      compersionFilter && (
                        <h5 className="card-title" style={{ fontSize: '12px', color: 'black' }}>
                          <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>({start_date} {end_date}) {compersion_filter.roster}</h5>

                         
                        </h5>
                      )
                    }
              </div>
           

          </div>

          <div className='col-sm-3 col-6 p-sm-2 p-1'>
              <Link to = '/attendance-for-approval'>
              <div className="card align-items-center pt-3 h-100" >
                <img src={`${process.env.PUBLIC_URL}/hr_dashboard/attendance.png`} style={{ width: '100px', height: '100px' }} alt="group loss" />
                <div class="card-body text-center">
                  <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>Attendance Request</h5>
                  <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>{datalist.attendance_regularize}</h5>
                 
                </div>
                {
                      compersionFilter && (
                        <h5 className="card-title" style={{ fontSize: '12px', color: 'black' }}>
                          <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>({start_date} {end_date}) {compersion_filter.attendance_regularize}</h5>

                         
                        </h5>
                      )
                    }
           
              </div>
              </Link>

          </div>

          <div className='col-sm-3 col-6 p-sm-2 p-1'>
              <Link to ='/position-request'>
              <div className="card align-items-center pt-3 h-100" >
                <img src={`${process.env.PUBLIC_URL}/hr_dashboard/job.png`} style={{ width: '100px', height: '100px' }} alt="group loss" />
                <div class="card-body text-center">
                  <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>Job Description</h5>
                  <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>{datalist.job_request}</h5>
                 
                </div>
              </div>
           
           </Link>
          </div>

          
          <div className='col-sm-3 col-6 p-sm-2 p-1'>
              
              <div className="card align-items-center pt-3 h-100" >
                <img src={`${process.env.PUBLIC_URL}/hr_dashboard/offer.png`} style={{ width: '100px', height: '100px' }} alt="group loss" />
                <div class="card-body text-center">
                  <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>No Of Offer Letter Generated</h5>
                  <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>{datalist.offer_letter_count}</h5>
                 
                </div>
              </div>
           
          </div>

          <div className='col-sm-3 col-6 p-sm-2 p-1'>
              <Link to='/leave-request-details'>
              <div className="card align-items-center pt-3 h-100" >
                <img src={`${process.env.PUBLIC_URL}/hr_dashboard/request.png`} style={{ width: '100px', height: '100px' }} alt="group loss" />
                <div class="card-body text-center">
                  <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>Leave Request</h5>
                  <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>{datalist.leave_request}</h5>
                 
                </div>
              </div>
           
              </Link>
              </div>

              <div className='col-sm-3 col-6 p-sm-2 p-1'>
              <Link to='/emp-confirmation-details'>
              <div className="card align-items-center pt-3 h-100" >
                <img src={`${process.env.PUBLIC_URL}/hr_dashboard/confirmation.png`} style={{ width: '100px', height: '100px' }} alt="group loss" />
                <div class="card-body text-center">
                  <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>Confirmation Request</h5>
                  <h5 class="card-title" style={{ fontSize: '12px', color: 'black' }}>{datalist.count_of_confirmation_request}</h5>
                 
                </div>
              </div>
           
              </Link>
              </div>
              
          </div>
          
          <div class="mt-4">
            <div className="col-sm-6 col-12">
              <Bar
                data={prepareChartData()}
                options={{
                  scales: {
                    y: {
                      beginAtZero: true,
                    },
                  },
                }}
              />

            </div>

            
          </div>
          
      </MDBox>
      <MDBox mt={4.5} />
      <Footer />
     
      <ToastContainer />
    </DashboardLayout>
  );
}

export default HrDashboard;