import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Card from "@mui/material/Card";


function KraKpiHeader(props) {

  return (
            <Card>
              <div className="card-body">
                  <div className="container-fluid">
                  <div className="row">
                 
                  <Link to='/target-type' className='btn btn-outline-primary'>
                            Target Type
                  </Link>
                  &nbsp; &nbsp;
                  
                  
                 <Link to='/company-goal-list' className='btn btn-outline-primary'>
                           Goal
                  </Link>
                  &nbsp; &nbsp;
                 
                 
                 <Link to='/subattribute-list' className='btn btn-outline-primary'>
                            Attribute
                  </Link>
                  &nbsp; &nbsp;
                
                  
                  <Link to='/target-subattribute' className='btn btn-outline-primary'>
                            SubAttribute
                  </Link>
                  &nbsp; &nbsp;
                 
                  
                  <Link to='/target-child-attribute' className='btn btn-outline-primary'>
                             Child Attribute
                  </Link>
                   &nbsp; &nbsp;

                  </div>
                  </div>
              </div>
            </Card>
  );
}


export default KraKpiHeader;
