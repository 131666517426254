import React, { useState, useEffect, useRef} from 'react';
import dashboardApi from "layouts/common_components/apibase_url";
import { useNavigate,useParams } from 'react-router-dom';
import secureLocalStorage from "react-secure-storage";
import DatePicker from 'react-datepicker';
import { TextField } from '@material-ui/core';

import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';
//import Grid from "@mui/material/Grid";
import { addDays } from 'date-fns';


import { Grid,InputLabel,FormControl, Select, MenuItem, Checkbox, ListItemText } from '@mui/material';
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
//import { TextField } from '@mui/material';
import { Button } from 'react-bootstrap';
import Swal from 'sweetalert2';



function Add_enquiry()
{
  const navigate = useNavigate();
  let {mobile_no} = useParams();
  let mobileNo = '';
 
  if(mobile_no){
    mobileNo = mobile_no;
  }

  let {enq_id} = useParams();
  let enqId = '';
  if(enq_id)
  {
    enqId = enq_id;
  }
  
  const emp_id = secureLocalStorage.getItem('emp_id');
  const token = secureLocalStorage.getItem('token');
  const department = secureLocalStorage.getItem('department');
  const [state, setState] = useState([]);
  const [loading, setLoading] = useState(true);
  const inputRef = useRef(null);
  const inputRef2 = useRef(null);
  
  const [name, setName] = useState('');
  const [phone, setPhone] = useState(mobileNo);
  const [secondaryPhone, setSecondaryPhone] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [phone2Error, setPhone2Error] = useState('');
  const [email,setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [movingDate, setMovingDate] = useState(null);
  const [movingDateError, setMovingDateError] = useState('');

  const [product, setProduct] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState('');

  const [service, setService] = useState([]);
  const [selectedService, setSelectedService] = useState('');

  const [category, setCategory] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');

  const [sourceFrom, setSourceFrom] = useState([]);
  const [selectedSourceFrom, setSelectedSourceFrom] = useState('');

  const [enqStatus, setEnqStatus] = useState([]);
  const [selectedEnqStatus, setSelectedEnqStatus] = useState('');
  const [selectedRemarks, setEnqRemarks] = useState('');
  const [selectedEnqType, setSelectedEnqType] = useState('');
  const [selectedSourceType, setSelectedSourceType] = useState('');
  const [selectedSource, setSelectedSource] = useState('');
  
  const [itemDetails,setItemDetails] = useState('');
 
  const [number, setNumber] = useState(1);



const [fromLat, setFromLat] = useState(null);
const [fromLng, setFromLng] = useState(null);
const [fromCity, setFromCity] = useState('');
const [fromState, setFromState] = useState('');
const [fromLocation, setFromLocation] = useState('');
const [fromAddress, setFromAddress] = useState('');
//setFromLat,setFromLng,setFromCity,setFromState,setFromLocation,setFromAddress
// Separate state variables for 'to' location
const [toLat, setToLat] = useState(null);
const [toLng, setToLng] = useState(null);
const [toCity, setToCity] = useState('');
const [toState, setToState] = useState('');
const [toLocation, setToLocation] = useState('');
const [toAddress, setToAddress] = useState('');

const [submitStatus,setSubmitStatus] = useState(0);

const [googleKey,setGoogleKey]=useState('');

const customStyles = {
  calendar: {
    backgroundColor: '#fff', // Sets a solid background color (non-transparent)
    border: '1px solid #ccc',
  },
  input: {
    width: '100%',
  },
};
<style>
  {`
    .custom-calendar {
      background-color: 
      border-radius: 8px;
      padding: 10px;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    }

    .react-datepicker__day {
      color: #333;
    }

    .react-datepicker__day--disabled {
      color: #d9d9d9 !important;
    }
    
    
  `}
</style>

  const [showForm, setShowForm] = useState(false);
  const [selectedItemTypes, setSelectedItemTypes] = useState({});
  const [selectedItems, setSelectedItems] = useState({});
  const [quantities, setQuantities] = useState({});

  const toggleForm = () => setShowForm((prev) => !prev);

  const handleItemTypeChange = (itemId) => {
    setSelectedItemTypes((prev) => ({
      ...prev,
      [itemId]: !prev[itemId],
    }));
  };

  const handleItemChange = (itemId) => {
    setSelectedItems((prev) => {
      const isSelected = !prev[itemId];
      setQuantities((quantitiesPrev) => ({
        ...quantitiesPrev,
        [itemId]: isSelected ? 1 : 0, // Set to 1 when checked, and 0 when unchecked
      }));
      return {
        ...prev,
        [itemId]: isSelected,
      };
    });
  };

  const updateQuantity = (itemId, delta) => {
    setQuantities((prev) => {
      const newQuantity = (prev[itemId] || 1) + delta;
      // Prevent the quantity from being less than 1 if the item is selected
      return {
        ...prev,
        [itemId]: newQuantity < 1 ? 1 : newQuantity,
      };
    });
  };

  useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }
    getProduct();
    getSourceFrom();
    getEnqStatus();
    //getGoogleKey();
  }, [token, navigate]);

 

  // useEffect(() => {
  //   if(enqId)
  //   {
  //     const getEnqUpdateData = async () => {
  //       try {
  //         const response = await dashboardApi.get(`get-enquiry-by-id/${enqId}`);
  //         if(response.data.status === 200)
  //         {
  //           //const jsonData = await response.json();
  //           const res = response.data.data; 
  //           //const selectedCategory = res.category_id.join(', ');
  //           const categoryString = Array.isArray(res.category_id) 
  //           ? res.category_id.join(', ') 
  //           : '';
            
  //           setSelectedCategory(categoryString);

  //           setName(res.name);
  //           setPhone(res.primary_no);
  //           setSecondaryPhone(res.primary_no);
  //           setEmail(res.email);
  //           setMovingDate(res.event_date);
  //           setSelectedProduct(res.product_id);
  //           setSelectedService(res.service_id);
  //           //setSelectedCategory(res.category_id);
  //           setSelectedSourceFrom(res.source_from);
  //           setSelectedEnqStatus(res.followup_status);
  //           setEnqRemarks(res.remarks);
  //           setSelectedEnqType(res.enq_for);
  //           setSelectedSourceType(res.source_type);
  //           setSelectedSource(res.source);
  //           setFromAddress(res.address_from);
  //           setToAddress(res.address_to);

  //           setFromLat(res.lat_from);
  //           setFromLng(res.long_from);
  //           setFromCity(res.city_from);
  //           setFromState(res.state_from);
  //           setFromLocation(res.locality_from);
            
  //           setToLat(res.lat_to);
  //           setToLng(res.long_to);
  //           setToCity(res.city_to);
  //           setToState(res.state_to);
  //           setToLocation(res.locality_to);

  //           setSelectedItemTypes(res.item_type ? JSON.parse(res.item_type) : '');
  //           setSelectedItems(res.item_list ? JSON.parse(res.item_list) : '');
  //           setQuantities(res.item_quantity ? JSON.parse(res.item_quantity) : '');
  //         } else {
  //           console.error('Error fetching source from:', response.data.message);
  //         }
  //         setLoading(false);
  //       } catch (error) {
  //         console.error('Error fetching source from:', error);
  //         setLoading(false);
  //       }
  //     };
  //     getEnqUpdateData();
  //   }
  // },[enqId]);

  useEffect(() => {
    if (enqId) {
      const getEnqUpdateData = async () => {
        try {
          const response = await dashboardApi.get(`get-enquiry-by-id/${enqId}`);
          if (response.data.status === 200) {
            const res = response.data.data;
  
            const categoryString = Array.isArray(res.selected_category_id)
              ? res.selected_category_id.join(',')
              : typeof res.selected_category_id === 'string'
              ? res.selected_category_id
              : '';
            setSelectedCategory(categoryString);
            setName(res.name);
            setPhone(res.primary_no);
            setSecondaryPhone(res.primary_no);
            setEmail(res.email);
            setMovingDate(res.event_date);
            setSelectedProduct(res.product_id);
            setSelectedService(res.service_id);
            setSelectedSourceFrom(res.source_from);
            setSelectedEnqStatus(res.followup_status);
            setEnqRemarks(res.remarks);
            setSelectedEnqType(res.enq_for);
            setSelectedSourceType(res.source_type);
            setSelectedSource(res.source);
            setFromAddress(res.address_from);
            setToAddress(res.address_to);
  
            setFromLat(res.lat_from);
            setFromLng(res.long_from);
            setFromCity(res.city_from);
            setFromState(res.state_from);
            setFromLocation(res.locality_from);
  
            setToLat(res.lat_to);
            setToLng(res.long_to);
            setToCity(res.city_to);
            setToState(res.state_to);
            setToLocation(res.locality_to);
  
            setSelectedItemTypes(res.item_type ? JSON.parse(res.item_type) : '');
            setSelectedItems(res.item_list ? JSON.parse(res.item_list) : '');
            setQuantities(res.item_quantity ? JSON.parse(res.item_quantity) : '');
          } else {
            console.error('Error fetching source from:', response.data.message);
          }
          setLoading(false);
        } catch (error) {
          console.error('Error fetching source from:', error);
          setLoading(false);
        }
      };
      getEnqUpdateData();
    }
  }, [enqId]);
  
  const emptyfilds =()=>{
          setName('');
          setPhone('');
          setSecondaryPhone('');
          setEmail('');
          setMovingDate('');
          setSelectedProduct('');
          setSelectedService('');
          setSelectedCategory('');
          setSelectedSourceFrom('');
          setSelectedEnqStatus('');
          setEnqRemarks('');
          setSelectedEnqType('');
          setSelectedSourceType('');
          setSelectedSource('');
          setFromAddress('');
          setToAddress('');
          setFromLat('');
          setFromLng('');
          setFromCity('');
          setFromState('');
          setFromLocation('');
          setToLat('');
          setToLng('');
          setToCity('');
          setToState('');
          setToLocation('');
          setSelectedItemTypes('');
          setSelectedItems('');
          setQuantities('');
  }
  

  const getSourceFrom = async () => {
    try {
      const response = await dashboardApi.get(`get-source-from`);
      if (response.data.status === 200) {
        setSourceFrom(response.data.data);
      } else {
        console.error('Error fetching source from:', response.data.message);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching source from:', error);
      setLoading(false);
    }
  };

  const getEnqStatus = async () => {
    try {
      const response = await dashboardApi.get(`get-verify-enq-status`);
      if (response.data.status === 200) {
        setEnqStatus(response.data.data);
      } else {
        console.error('Error fetching enquiry status:', response.data.message);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching enquiry status:', error);
      setLoading(false);
    }
  };

  const getProduct = async () => {
    try {
      const response = await dashboardApi.get(`sales-product-list/active`);
      if (response.data.status === 200) {
        setProduct(response.data.data);
      } else {
        console.error('Error fetching product list:', response.data.message);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching product list:', error);
      setLoading(false);
    }
  };

  

  useEffect(() => {
    if (selectedProduct) {
      const fetchService = async () => {
        try {
          const response = await dashboardApi.get(`get-service-list-by-product-id/${selectedProduct}`);
          if (response.data.status === 200) {
            setService(response.data.data);
          } else {
            console.error('Error fetching service list:', response.data.message);
          }
          setLoading(false);
        } catch (error) {
          console.error('Error fetching service list:', error);
          setLoading(false);
        }
      };
      fetchService();
    }
  }, [selectedProduct]);

  useEffect(() => {
    if (selectedProduct || selectedService) {
      const fetchService = async () => {
        try {
          const response = await dashboardApi.get(`get-item-details`,{
            params:{
              emp_id:emp_id,
              product_id: selectedProduct,
              service:selectedService,
            }
          });
          if (response.data.status === 200) {
            setItemDetails(response.data.data);
          } else {
            console.error('Error fetching service list:', response.data.message);
          }
          setLoading(false);
        } catch (error) {
          console.error('Error fetching service list:', error);
          setLoading(false);
        }
      };
      fetchService();
    }
  }, [selectedProduct,selectedService]);

  useEffect(() => {
    if (selectedService) {
      const fetchCategory = async () => {
        try {
          const response = await dashboardApi.get(`get-category-list-by-service-id/${selectedService}`);
          if (response.data.status === 200) {
            setCategory(response.data.data);
          } else {
            console.error('Error fetching category list:', response.data.message);
          }
          setLoading(false);
        } catch (error) {
          console.error('Error fetching category list:', error);
          setLoading(false);
        }
      };
      fetchCategory();
    }
  }, [selectedService]);

  const phoneCheck = () => {
    const phonePattern = /^[0-9]{10}$/;
    if (!phonePattern.test(phone)) {
      setPhoneError('Please Enter Valid Phone Number.');
    } else {
      setPhoneError('');
    }
  };

  const handlePhoneBlur = () => {
    phoneCheck();
  };

  const handleSecondaryPhoneBlur = () => {
    const phonePattern = /^[0-9]{10}$/;
    if (!phonePattern.test(secondaryPhone)) {
      setPhone2Error('Please Enter Valid Phone Number.');
    } else {
      setPhone2Error('');
    }
  };

  const handleMovingDateChange = (date) => {
    setMovingDate(date);
    setMovingDateError('');
  };

  const handleMovingDateBlur = () => {
    if (!movingDate) {
      setMovingDateError('Please Select Moving Date');
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setEmailError('');
  };

  const handleEmailBlur = () => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailPattern.test(email)) {
      setEmailError('Please enter a valid email address.');
    }
  };



  // useEffect(() => {
  //   if(!window.google)
  //   {
  //     const getGoogleKey = async()=>
  //     {
        
  //       try {
  //         const formData = new FormData();
  //         formData.append('key_type','suggestion');
  //         const response = await dashboardApi.post(`third-party/google-key`,formData);
  //         if (response.data.status === 200) {
          
  //           setGoogleKey(response.data);
  //           //console.error(response.data);
  //         } else {
  //           console.error('Error fetching category list:', response.data.message);
  //         }
  //         setLoading(false);
  //       } catch (error) {
  //         console.error('Error fetching category list:', error);
  //         setLoading(false);
  //       }
  //     }
  //    // getGoogleKey();
  //     alert(googleKey);
  //     const script = document.createElement('script');
  //     script.src = `https://maps.googleapis.com/maps/api/js?key=${googleKey}'&libraries=places`;
  //     script.async = true;
  //     script.defer = true;
  //     script.onload = () => initAutocomplete();
  //     document.head.appendChild(script);
  //   } else {
  //     initAutocomplete();
  //   }
  // }, []);
  
  useEffect(() => {
    const loadGoogleMapsScript = async (key) => {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${key}&libraries=places`;
      script.async = true;
      script.defer = true;
      script.onload = () => initAutocomplete();
      document.head.appendChild(script);
    };
  
    if (!window.google) {
      const getGoogleKey = async () => {
        try {
          const formData = new FormData();
          formData.append('key_type', 'suggestion');
          const response = await dashboardApi.post('third-party/google-key', formData);
  
          if (response.data.status === 200) {
            const key = response.data.data;
            setGoogleKey(key); 
            await loadGoogleMapsScript(key);
          } else {
            console.error('Error fetching Google API key:', response.data.message);
          }
        } catch (error) {
          console.error('Error fetching Google API key:', error);
        } finally {
          setLoading(false);
        }
      };
  
      getGoogleKey();
    } else {
      initAutocomplete();
    }
  }, []);
  


  useEffect(() => {
    if (window.google) {
      initAutocomplete(); // Reinitialize autocomplete whenever `selectedService` changes
    }
  }, [selectedService]);

  const initAutocomplete = () =>
    {
      if (inputRef.current && window.google) {
        const searchBox = new window.google.maps.places.Autocomplete(inputRef.current, { types: ['geocode'] });
        const searchBox2 = new window.google.maps.places.Autocomplete(inputRef2.current, { types: ['geocode'] });
    
        searchBox.setFields(['address_component', 'formatted_address', 'geometry', 'place_id']);
        searchBox2.setFields(['address_component', 'formatted_address', 'geometry', 'place_id']);
    
        if (selectedService !== '2') {
          searchBox.setComponentRestrictions({ country: ['in'] });
          searchBox2.setComponentRestrictions({ country: ['in'] });
        } else {
          // No restrictions applied when selectedService == '2'
          searchBox.setComponentRestrictions({});
          searchBox2.setComponentRestrictions({});
        }
    
        searchBox.addListener('place_changed', () => handlePlaceChanged(searchBox, 'from'));
        searchBox2.addListener('place_changed', () => handlePlaceChanged(searchBox2, 'to'));
      }
    };
  
  const handlePlaceChanged = (searchBox, type) => {
    const place = searchBox.getPlace();
    if (!place || !place.geometry) return;
  
    const complete_info = place.address_components;
    const lat = place.geometry.location.lat();
    const lng = place.geometry.location.lng();
    const formatted_address = place.formatted_address;
  
    let city = '';
    let state = '';
    let location = '';
  
    complete_info.forEach(component => {
      const types = component.types;
      if (types.includes('locality'))
      {
        city = component.long_name;
      }
      else if (types.includes('sublocality') || types.includes('sublocality_level_1') || types.includes('sublocality_level_2'))
      {
        location = component.long_name;
      } else if (types.includes('administrative_area_level_1')) {
        state = component.long_name;
      }
    });
  
    if (type === 'from') {
      setFromLat(lat);
      setFromLng(lng);
      setFromCity(city);
      setFromState(state);
      setFromLocation(location);
      setFromAddress(formatted_address);
    } else if (type === 'to') {
      setToLat(lat);
      setToLng(lng);
      setToCity(city);
      setToState(state);
      setToLocation(location);
      setToAddress(formatted_address);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Perform validation checks
    if (!name) {
      Swal.fire("Error", "Please enter customer name.", "error");
      return;
    }

    if (!phone) {
      Swal.fire("Error", "Please enter customer phone number.", "error");
      return;
    }

    if (phoneError) {
      Swal.fire("Error", phoneError, "error");
      return;
    }

    if (emailError) {
      Swal.fire("Error", emailError, "error");
      return;
    }

    if (!movingDate) {
      setMovingDateError('Please Select Event Date');
      return;
    }

    if (phone2Error) {
      Swal.fire("Error", phone2Error, "error");
      return;
    }

    setSubmitStatus(1);

    try {
      const formattedDate = format(movingDate, 'yyyy-MM-dd');

      const formData = {
        enqId,
        emp_id,
        name,
        phone,
        email,
        secondaryPhone,
        moving_date: formattedDate,
        source_from: selectedSourceFrom,
        enq_status: selectedEnqStatus,
        enq_remarks: selectedRemarks,
        service: selectedService,
        product: selectedProduct,
        category: selectedCategory,
        enq_type:selectedEnqType,
        source_type:selectedSourceType,
        //source_url:selectedSource,
        selectedItemTypes:selectedItemTypes,
        selectedItems:selectedItems,
        quantities:quantities,

        fromLat:fromLat,
        fromLng:fromLng,
        fromCity:fromCity,
        fromState:fromState,
        fromLocation:fromLocation,
        fromAddress:fromAddress,

        toLat:toLat,
        toLng:toLng,
        toCity:toCity,
        toState:toState,
        toLocation:toLocation,
        toAddress:toAddress,
 
      };

   
      const response = await dashboardApi.post('add-enquiry', formData);
      if(response.data.status === 200)
      {
        setSubmitStatus(0);
        Swal.fire("Success", response.data.message, "success").then(() => {
        emptyfilds();
        
        });
      } else {
        setSubmitStatus(0);
        Swal.fire("Error", response.data.message, "error");
      }
    } catch (error) {
      setSubmitStatus(0);
      console.error('Error submitting enquiry:', error);
      Swal.fire("Error", "Failed to submit enquiry. Please try again.", "error");
    }
  };

  const handleFromInputChange = (event) => {
    setFromAddress(event.target.value); 
  };

  const handleToInputChange = (event) => {
    setToAddress(event.target.value); 
  };

  // const handleCategoryChange = (event) => {
  //   const selectedIds = event.target.value;
  //   setSelectedCategory(selectedIds.join(', ')); 
  // };

  // const handleCategoryChange = (event) => {
  //   const selectedValues = event.target.value;
  //   setSelectedCategory(selectedValues.join(', ')); // Update selectedCategory as a comma-separated string
  // };

  // const selectedNames = selectedCategory
  //   .split(', ')
  //   .map((id) => category.find((opt) => opt.id.toString() === id)?.category_name)
  //   .filter(Boolean); 

  const handleCategoryChange = (event) => {
    const selectedValues = event.target.value;
    setSelectedCategory(selectedValues.join(',')); // Save as a comma-separated string
  };

  const selectedNames = selectedCategory
  .split(',')
  .map((id) => category.find((opt) => opt.id.toString() === id)?.category_name)
  .filter(Boolean);

  

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={4} mb={4}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <form onSubmit={handleSubmit}>
                <MDBox p={3}>
                  <h5>Add Enquiry</h5>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label="Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label="Phone"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        onBlur={handlePhoneBlur}
                        error={!!phoneError}
                        helperText={phoneError}
                        required
                      />
                    </Grid>
                    {selectedService && selectedService !=6 && selectedService !=7 &&(
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label="Secondary Phone"
                        value={secondaryPhone}
                        onChange={(e) => setSecondaryPhone(e.target.value)}
                        onBlur={handleSecondaryPhoneBlur}
                        error={!!phone2Error}
                        helperText={phone2Error}
                      />
                    </Grid>
                    )}
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label="Email"
                        value={email}
                        onChange={handleEmailChange}
                        onBlur={handleEmailBlur}
                        error={!!emailError}
                        helperText={emailError}
                      />
                    </Grid>
                    

                    <Grid item xs={12} md={6}>
                      <DatePicker
                        selected={movingDate}
                        onChange={handleMovingDateChange}
                        onBlur={handleMovingDateBlur}
                        dateFormat="yyyy-MM-dd"
                        placeholderText="Select Event Date"
                        customInput={
                          <TextField
                            fullWidth
                            label="Event Date"
                            error={!!movingDateError}
                            helperText={movingDateError}
                          />
                        }
                        minDate={addDays(new Date(), 0)} // Restrict to dates strictly after today
                        calendarClassName="custom-calendar" // Assign custom class to calendar
                        required
                      />
                    </Grid>
                    
                    
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label="Product"
                        select
                        value={selectedProduct}
                        onChange={(e) => setSelectedProduct(e.target.value)}
                        SelectProps={{
                          native: true,
                        }}
                        required
                      >
                        <option value=""></option>
                        {product.map((option) => (
                          <option key={option.id} value={option.id}>
                            {option.product_name}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label="Service"
                        select
                        value={selectedService}
                        onChange={(e) => setSelectedService(e.target.value)}
                        SelectProps={{
                          native: true,
                        }}
                        required
                      >
                        <option value=""></option>
                        {service.map((option) => (
                          <option key={option.id} value={option.id}>
                            {option.service_name}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                   

                    {/* <Grid item xs={12} md={6}>
                      <FormControl fullWidth required>
                        <InputLabel>Category</InputLabel>
                        <Select
                        className='p-2'
                          
                          label="Category"
                          multiple
                          value={selectedCategory ? selectedCategory.split(', ').filter(Boolean) : []}
                          onChange={handleCategoryChange}
                          renderValue={() => selectedNames.join(', ')}
                        >
                          {category.map((option) => (
                            <MenuItem key={option.id} value={option.id.toString()}>
                              <Checkbox checked={selectedCategory.split(', ').includes(option.id.toString())} />
                              <ListItemText primary={option.category_name} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid> */}

                    {/* <Grid item xs={12} md={6}>
                      <FormControl fullWidth required>
                        <InputLabel>Category</InputLabel>
                        <Select
                          className="p-2"
                          label="Category"
                          multiple
                          value={selectedCategory ? selectedCategory.split(', ').filter(Boolean) : []} // Convert string to array for Select
                          onChange={handleCategoryChange}
                          renderValue={(selected) =>
                            selected
                              .map((id) => {
                                const categoryOption = category.find((option) => option.id.toString() === id);
                                return categoryOption ? categoryOption.category_name : '';
                              })
                              .join(',')
                          }
                        >
                          {category.map((option) => (
                            <MenuItem key={option.id} value={option.id.toString()}>
                              <Checkbox
                                checked={selectedCategory.split(', ').includes(option.id.toString())} // Check if value is selected
                              />
                              <ListItemText primary={option.category_name} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid> */}

                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth required>
                      <InputLabel>Category</InputLabel>
                      <Select
                        className="p-2"
                        label="Category"
                        multiple
                        value={selectedCategory ? selectedCategory.split(',').filter(Boolean) : []} // Split by ',' to handle comma-separated string
                        onChange={handleCategoryChange}
                        renderValue={(selected) =>
                          selected
                            .map((id) => {
                              const categoryOption = category.find((option) => option.id.toString() === id);
                              return categoryOption ? categoryOption.category_name : '';
                            })
                            .join(', ')
                        }
                      >
                        {category.map((option) => (
                          <MenuItem key={option.id} value={option.id.toString()}>
                            <Checkbox
                              checked={selectedCategory.split(',').includes(option.id.toString())} // Split by ',' for consistency
                            />
                            <ListItemText primary={option.category_name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>



                    {selectedProduct && selectedProduct ==1 && selectedService !=6 && selectedService !=7 && (
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label="Enquiry Type"
                        select
                        value={selectedEnqType}
                        onChange={(e) => setSelectedEnqType(e.target.value)}
                        SelectProps={{
                          native: true,
                        }}
                        
                      >
                       
                        <option value=""></option>
                        <option value="1"> Outside City</option>
                        <option value="2"> Within City</option>
                        <option value="3"> International</option>
                      </TextField>
                    </Grid>
                    )}
                   
                    {selectedService && selectedService !=6 && selectedService !=7 &&(
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label="Source From"
                        select
                        value={selectedSourceFrom}
                        onChange={(e) => setSelectedSourceFrom(e.target.value)}
                        SelectProps={{
                          native: true,
                        }}
                        className="custom-select"
                        required
                      >
                        <option value=""></option>
                        {sourceFrom.map((option) => (
                          <option key={option.id} value={option.id}>
                            {option.source_name}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                    )}
                    
                    {selectedService && selectedService !=6 && selectedService !=7 &&(
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label="Source Type"
                        select
                        value={selectedSourceType}
                        onChange={(e) => setSelectedSourceType(e.target.value)}
                        SelectProps={{
                          native: true,
                        }}
                        className="custom-select"
                        required
                      ><option value=""></option>
                        <option value="TF">Toll Free</option>
                        <option value="WA">Whats App</option>
                        <option value="CS">CS</option>
                        <option value="FB">Facebook</option>
                        <option value="IG">Instagram</option>
                      </TextField>
                    </Grid>
                    )}

                  {/* {selectedService && selectedService !=6 && selectedService !=7 &&(
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label="Source URL"
                        value={selectedSource}
                        onChange={(e) => setSelectedSource(e.target.value)}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                  )} */}
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label={selectedProduct==1 && selectedService !=3 ?'From Location':"Event Address"}
                        value={fromAddress}
                        onChange={handleFromInputChange}
                        inputRef={inputRef}
                        required
                      />
                    </Grid>

                    <Grid item xs={12} md={3}>
                      <TextField
                        fullWidth
                        label={selectedProduct==1 && selectedService !=3 ?'From City':"Event City"}
                        value={fromCity}
                       
                      />
                    </Grid>

                    <Grid item xs={12} md={3}>
                      <TextField
                        fullWidth
                        label={selectedProduct==1 && selectedService !=3 ?'Locality From':"Event Locality"}
                        value={fromLocation}
                        required
                      />
                    </Grid>
                   

                    <Grid item xs={12} md={6} style={{ display: selectedProduct && selectedProduct != 2 && selectedService != 3 ? 'block' : 'none' }}>
                      <TextField
                        fullWidth
                        label="To Location"
                        value={toAddress}
                        inputRef={inputRef2}
                        onChange={handleToInputChange}
                        disabled={!selectedProduct || selectedProduct == 2 || selectedService == 3}
                      />
                    </Grid>

                    <Grid item xs={12} md={3} style={{ display: selectedProduct && selectedProduct != 2 && selectedService != 3 ? 'block' : 'none' }}>
                      <TextField
                        fullWidth
                        label="To City"
                        value={toCity}
                        //inputRef={inputRef2}
                        disabled={!selectedProduct || selectedProduct == 2 || selectedService == 3}
                      />
                    </Grid>

                    <Grid item xs={12} md={3} style={{ display: selectedProduct && selectedProduct != 2 && selectedService != 3 ? 'block' : 'none' }}>
                      <TextField
                        fullWidth
                        label="To Locality"
                        value={toLocation}
                        //inputRef={inputRef2}
                        disabled={!selectedProduct || selectedProduct == 2 || selectedService == 3}
                      />
                    </Grid>
                    
                  {selectedService && selectedService !=6 && selectedService !=7 &&(
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label="Enquiry Status"
                        select
                        value={selectedEnqStatus}
                        onChange={(e) => setSelectedEnqStatus(e.target.value)}
                        SelectProps={{
                          native: true,
                        }}
                        
                      >
                        <option value=""></option>
                        {enqStatus.map((option) => (
                          <option key={option.id} value={option.id}>
                            {option.name}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                  )}

                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label="Remarks"
                        value={selectedRemarks}
                        onChange={(e) => setEnqRemarks(e.target.value)}
                      />
                    </Grid>
                    {selectedService && selectedService !=6 && selectedService !=7 &&(
                    <Grid item xs={12}>
                   
                    <div>
                        <span onClick={toggleForm} className="btn btn-success btn-sm mx-1">
                          {showForm ? 'Hide More Information -' : 'Add More Information +'}
                        </span>
                        {showForm && (
                          <div className="row" style={{ overflowY: 'hidden' }}>
                            <table className="table table-bordered table-hovered" style={{ width: '100%' }}>
                              {itemDetails &&
                                itemDetails.map((item, index) => (
                                  <React.Fragment key={item.id}>
                                    <tr>
                                      <td>{index + 1}</td>
                                      <td>
                                        <input
                                          type="checkbox"
                                          checked={!!selectedItemTypes[item.id]}
                                          onChange={() => handleItemTypeChange(item.id)}
                                        />
                                        &nbsp;&nbsp;{item.item_type}
                                      </td>

                                      <td>
                                        {selectedItemTypes[item.id] &&
                                          item.item_info.map((it, ind) => (
                                            <tr key={it.item_id}>
                                              <td>{ind + 1}</td>
                                              <td>
                                                <input
                                                  type="checkbox"
                                                  checked={!!selectedItems[it.item_id]}
                                                  onChange={() => handleItemChange(it.item_id)}
                                                />
                                                &nbsp;&nbsp;{it.item_name}
                                              </td>
                                              <td>
                                                {selectedItems[it.item_id] && (
                                                  <div className="counter d-flex btn btn-primary">
                                                    <span
                                                      className="btn btn-primary btn-danger btn-sm"
                                                      onClick={() => updateQuantity(it.item_id, -1)}
                                                    >
                                                      -
                                                    </span>

                                                    <span className="btn btn-default btn-sm" style={{ color: 'white' }}>
                                                      {quantities[it.item_id] || 1}
                                                    </span>
                                                    <span
                                                      className="btn btn-primary btn-danger btn-sm"
                                                      onClick={() => updateQuantity(it.item_id, 1)}
                                                    >
                                                      +
                                                    </span>
                                                  </div>
                                                )}
                                              </td>
                                            </tr>
                                          ))}
                                      </td>
                                    </tr>
                                  </React.Fragment>
                                ))}
                            </table>
                          </div>
                        )}
                      </div>
                    </Grid>
                    )}

                    <Grid item xs={12}>
                      {submitStatus==0 &&
                        <Button variant="primary" onClick={handleSubmit} style={{float:'right'}}>Submit</Button>
                      }
                    </Grid>

                  </Grid>
                </MDBox>
              </form>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Add_enquiry;