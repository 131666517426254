import React, { useState, useEffect } from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import dashboardApi from "layouts/common_components/apibase_url";
import { useNavigate } from 'react-router-dom';
import secureLocalStorage from "react-secure-storage";
import Pagination from 'react-bootstrap/Pagination';
import ReactPaginate from 'react-paginate';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import './filter.css'

function SalesLead() {
  const navigate = useNavigate();
  const empId = secureLocalStorage.getItem('emp_id');
  const dept_id = secureLocalStorage.getItem('department_id');
 // alert(dept_id);
  const token = secureLocalStorage.getItem('token');
  const [assignedTasks, setAssignedTasks] = useState([]);
  const [targetList, settargetlist] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [number, setNumber] = useState(1); 
  const [postPerPage] = useState(10);
  const [loading,setLoading] = useState(false);
  const [grouplist,setgrouplist] = useState([]);
  const [categorylist,setcategorylist] = useState([]);
  const [category,setcategory] = useState('');
  const [openModal,setopenModal] = useState(false);
  const [openeditmodel,setopeneditmodel] = useState(false);
  const [employee,setemployee] = useState([]);
  const [selectedemployee,setselectedemployee] = useState('');
  const [validationErrors, setValidationErrors] = useState({});
  const [value,setvalue] = useState('');
  const [assigngroup,setassigngroup] = useState('');
  const [assigncategory,setassigncategory] = useState('');
  const [selectedmonth,setmonth] = useState([]);
  const [financialYears, setFinancialYears] = useState([]);
  const [financial_year,set_financialyear] = useState('');
  const [groupid,setgroupid] = useState('');
  const [categoryid,setcategoryid] = useState('')
  const [lead ,setlead] = useState('');
  const [selectedgroup, setselectedgroup] = useState([]);
  const [remaining,getremaining] = useState('');
  const [assignmonth,setassignmonth] = useState('');
  const [assignyear,setassignyear] = useState('');
  const [source,setsource] = useState('');
  const [productlist,setproductlist] = useState([]);
  const [product,setproduct] = useState('');
  const [target_type,set_target_type] = useState('');
  const [remark,set_remark] =useState('');
  const [target_id,set_target_id] = useState('');
  const [id,set_id] = useState('');
  const [no_of_packages,set_no_of_packages] = useState('');
  //const [assign_no_of_leads,set_no_of_leads] = useState('');

  const [searchText, setSearchText] = useState('');
  const [selectedchild_attribute,setselectedchildattribute] = useState('');
  const [showAttributeDropdown, setShowAttributeDropdown] = useState(false);
  const[employeedetails,setemployeedetails] = useState([]);
  const[amount_target,fetchamount_target] = useState([]);
  const [manager_target,get_manager_targets] = useState([]);
  const [team_target,get_team_target] = useState([]);
  const [team_collection_view,show_team_collection_view] = useState(false);
  const [assign_no_of_leads,set_no_of_leads] = useState('');
  const [servicelist,setservicelist] = useState([]);
  const [selectedservice,setselectedservice] = useState('');

  const [remaining_leads,set_remaining_lead] = useState();
  const [remaining_packages,set_remaining_packages] = useState();
  const [categoryData, setCategoryData] = useState([]);
  const [category_details,set_category_details] = useState([]);
  const [selectedcategory,setselectedcategory] = useState('');

  //const []

  console.log(categoryData);


   const [inputData, setInputData] = useState({});
  const filteredGroups = searchText
    ? employee.filter(row => row.emp_fname.toLowerCase().includes(searchText.toLowerCase()))
    : employee;

  var currentDate = new Date();
  var currentYear = currentDate.getFullYear();
  var startYear, endYear;
if (currentDate.getMonth() < 3) {
    startYear = currentYear - 1;
    endYear = currentYear;
} else {
    startYear = currentYear;
    endYear = currentYear + 1;
}
const curr_fyear =  startYear + " - " + endYear;
const month = currentDate.toLocaleString('default', { month: 'long' });
//alert(month);

   const months = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  //console.log(remaining);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const endpoint = 'get-product-list';
        const response = await dashboardApi.get(endpoint);

        if (response.data.status === 200) {
          setproductlist(response.data.data);
        } else {
          console.error('Error fetching data:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

   useEffect(() => {
      if (product) {
        const fetchData = async () => {
          try {
            const endpoint = `get-category-based-on-product-id/${product}`;
            const response = await dashboardApi.get(endpoint);
  
            if (response.data.status === 200) {
              set_category_details(response.data.data);
            } else {
              console.error('Error fetching data:', response.data.message);
            }
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
  
        fetchData();
      }
    }, [product]);


  useEffect(() => {
    if (product) {
      const fetchData = async () => {
        try {
          const endpoint = `get-service-list-by-product-id/${product}`;
          const response = await dashboardApi.get(endpoint);

          if (response.data.status === 200) {
            setservicelist(response.data.data);
          } else {
            console.error('Error fetching data:', response.data.message);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchData();
    }
  }, [product]);



  
  //const [attribute,setattribute]= useState('');

  //alert(dept_id);

  useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }
  }, [token, navigate]);

  useEffect(() => {
    fetch_sales_target();
    //fetchtarget();
  },[])

  useEffect(() => {
        if (!openModal) {
        fetchTarget(currentPage);
      }
    }, [selectedcategory, selectedgroup, selectedmonth, financial_year,  currentPage,product,openModal]);

  // useEffect(() => {
  //       target_no_counts();
  //   }, [category, selectedgroup, selectedmonth, financial_year]);

    useEffect(()=>{
       if (!openModal) {
         fetch_amount_target(currentPage);
          }

      } ,[selectedcategory, selectedgroup, selectedmonth, financial_year,  currentPage,product,openModal])


      useEffect(()=>{
        if(!openModal){
           manager_targets();

        }

      },[selectedgroup, selectedmonth, financial_year,product,openModal])

      useEffect(()=>{
        if(openModal){
          show_category_data();

        }
    
      },[selectedservice,selectedgroup,openModal]);


  useEffect(() => {
    const getCurrentFinancialYear = () => {
      const today = new Date();
      const currentMonth = today.getMonth() + 1;
      const currentYear = today.getFullYear();

      const financialYearStart = currentMonth >= 4 ? currentYear : currentYear - 1;
      const financialYearEnd = financialYearStart + 1;

      return `${financialYearStart}-${financialYearEnd}`;
    };


    const generateFinancialYears = () => {
      const currentFinancialYear = getCurrentFinancialYear();
      const nextFinancialYears = [];

      for (let i = 1; i <= 5; i++) {
        const startYear = parseInt(currentFinancialYear.split('-')[0]) + i;
        const endYear = startYear + 1;
        nextFinancialYears.push(`${startYear}-${endYear}`);
      }
      const allFinancialYears = [currentFinancialYear, ...nextFinancialYears];
      setFinancialYears(allFinancialYears);
    };

    generateFinancialYears();
  }, []);
 const fetchTarget = async (page = 0) => {
        try {
            setLoading(true);
            const response = await dashboardApi.get('get-sales-lead', {
                params: {
                    per_page: postPerPage,
                    page: page + 1,
                    category: selectedcategory,
                    group: selectedgroup,
                    month: selectedmonth,
                    financial_year: financial_year,
                    //additional:additional,
                    id:empId,
                    product:product,
                }
            });
            if (response.data.status === 200) {
                const responseData = response.data.data;
                const stockdata = responseData.data || responseData;
                settargetlist(stockdata);
                setNumber(response.data.last_page);
                setLoading(false);

            } else {
                console.error('Error fetching data:', response.data.error);
                setLoading(false);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };

    // const target_no_counts = async () => {
    //     try {
    //         //setLoading(true);
    //         const response = await dashboardApi.get('assign-target-no-counts', {
    //             params: {
    //                 category: category,
    //                 group: selectedgroup,
    //                 month: selectedmonth,
    //                 financial_year: financial_year,
    //                 //additional:additional,
    //             }
    //         });
    //         if (response.data.status === 200) {
    //              set_remaining_lead(response.data.remaining_lead);
    //              set_remaining_packages(response.data.remaining_packages);

    //         } else {
    //             console.error('Error fetching data:', response.data.error);
    //             setLoading(false);
    //         }
    //     } catch (error) {
    //         console.error('Error fetching data:', error);
    //         setLoading(false);
    //     }
    // };

    //console.log(remaining_leads);

    const manager_targets = async (page = 0) => {
      try {
          
          const response = await dashboardApi.get('managers-targets', {
              params: {
                  group: selectedgroup,
                  month: selectedmonth,
                  financial_year: financial_year,
                  //additional:additional,
                  id:empId,
                  product:product,
              }
          });
          if (response.data.status === 200) {
            get_manager_targets(response.data.data);
              

          } else {
              console.error('Error fetching data:', response.data.error);
              //setLoading(false);
          }
      } catch (error) {
          console.error('Error fetching data:', error);
          //setLoading(false);
      }
  };




    const fetch_amount_target = async (page = 0) => {
      try {
          setLoading(true);
          const response = await dashboardApi.get('lead-amount-details', {
              params: {
                  per_page: postPerPage,
                  page: page + 1,
                  category: selectedcategory,
                  group: selectedgroup,
                  month: selectedmonth,
                  financial_year: financial_year,
                  //additional:additional,
                  id:empId,
                  product:product,
              }
          });
          if (response.data.status === 200) {
              const responseData = response.data.data;
              const amount_target = responseData.data || responseData;
              fetchamount_target(amount_target);
              setNumber(response.data.last_page);
              setLoading(false);

          } else {
              console.error('Error fetching data:', response.data.error);
              setLoading(false);
          }
      } catch (error) {
          console.error('Error fetching data:', error);
          setLoading(false);
      }
  };




  const handlePageClick = (selected) => {
    setCurrentPage(selected.selected);
    fetchTarget(selected.selected); 
};

  const handletargetamount = (selected)=>{
    setCurrentPage(selected.selected);
    fetch_amount_target(selected.selected);


  }

  const handleCategoryChange = (e, group_id, category_id, type) => {
    const { value } = e.target;
    setCategoryData((prevData) => {
      const index = prevData.findIndex(
        (item) => item.group_id === group_id && item.category_id === category_id
      );
      if (index !== -1) {
        const updatedData = [...prevData];
        updatedData[index] = {
          ...updatedData[index],
          [type === 'leads' ? 'no_of_leads' : 'no_of_packages']: value,
        };
        return updatedData;
      } else {
        return [
          ...prevData,
          {
            group_id,
            category_id,
            no_of_leads: type === 'leads' ? value : '',
            no_of_packages: type === 'packages' ? value : '',
          },
        ];
      }
    });
  };



  const fetch_sales_target = async (page = 0) => {
      try {
        const response = await dashboardApi.get('get-sales-lead', {
            params: {
              per_page: postPerPage,
              page: page + 1,
              id:empId,
              //emp_id:empId,
            }
          });
        if (response.data.status === 200) {
             const responseData = response.data.data;
             const stockdata = responseData.data || responseData;
             settargetlist(stockdata);
             setNumber(response.data.last_page);
        } else {
          console.error('Error fetching data:', response.data.error);
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    const managerteam_collection = async(employee_id)=>{

      show_team_collection_view((prevState) => !prevState);

      try {
        const response = await dashboardApi.get('manager-team-targets', {
            params: {
                  group: selectedgroup,
                  month: selectedmonth,
                  financial_year: financial_year,
                  //additional:additional,
                  id:employee_id,
                  product:product,
            }
          });
        if (response.data.status === 200) {
          get_team_target(response.data.data)

             
        } else {
          console.error('Error fetching data:', response.data.error);
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }

    }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const endpoint = 'group-details';
        const response = await dashboardApi.get(endpoint);

        if (response.data.status === 200) {
            setgrouplist(response.data.data);
        } else {
          console.error('Error fetching data:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  //alert(department);

  // useEffect(() => {
  //   if (selectedservice) {
  //     const fetchData = async () => {
  //       try {
  //         const endpoint = `get-category-based-on-service-id/${selectedservice}`;
  //         const response = await dashboardApi.get(endpoint);

  //         if (response.data.status === 200) {
  //           setcategorylist(response.data.data);
  //         } else {
  //           console.error('Error fetching data:', response.data.message);
  //         }
  //       } catch (error) {
  //         console.error('Error fetching data:', error);
  //       }
  //     };

  //     fetchData();
  //   }
  // }, [selectedservice]);

  useEffect(() => {
      const fetchData = async () => {
        try {
          const endpoint = `sales-dashboard-show-manager-team/${empId}`;
          const response = await dashboardApi.get(endpoint);

          if (response.data.status === 200) {
             setemployee(response.data.data);
          } else {
            console.error('Error fetching data:', response.data.message);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchData();
  }, []);

  


  // useEffect(() => {
  //       const fetchtarget = async () => {
  //           try {
  //               const response = await dashboardApi.get('get-group-employee', {
  //                   params: {
  //                       assigngroup:assigngroup,
  //                   }
  //               });
  //               if (response.data.status === 200) {
  //                 setemployee(response.data.data);
  //               } else {
  //                   console.error('Error fetching data:', response.data.error);
  //               }
  //               setLoading(false);
  //           } catch (error) {
  //               console.error('Error fetching data:', error);
  //               setLoading(false);
  //           }
  //       };
  //       fetchtarget();
  // }, [assigngroup]);

  useEffect(()=>{
    if(selectedchild_attribute.length>0){
       const fetchData = async () => {
       try {
          const response = await dashboardApi.get('emp-data', {
              params: {
               emp_ids:selectedchild_attribute
                //emp_id:empId,
              }
            });
          if (response.data.status === 200) {
           setemployeedetails(response.data.data)
               
          } else {
            console.error('Error fetching data:', response.data.error);
          }
          //setLoading(false);
        } catch (error) {
          console.error('Error fetching data:', error);
         // setLoading(false);
        }
  
    }
      fetchData();
  
  }
  
  },[selectedchild_attribute])


  const assign_to_team_view = ()=>{
   // alert();
    setproduct('');
    setselectedservice('');
    set_financialyear('');
    setselectedemployee('');
    setmonth('');
    set_remark('');
    set_target_type('');
    setopenModal(true);
    setValidationErrors({});
    setselectedgroup([]);
    setcategorylist([]);
    setCategoryData([]);
  }
  

  // const assign_to_team = async(group,category,month,financial_year,target_id,id)=>{
  //   try {
  //     const endpoint = `count-sales-lead-data/${group}/${category}/${month}/${financial_year}`;
  //     const response = await dashboardApi.get(endpoint);

  //     if (response.data.status === 200) {

  //       getremaining(response.data.sales_data);
  //     } else {
  //       console.error('Error fetching:', response.data.message);
  //     }
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //   }
  //   setassigngroup(group);
  //   setassigncategory(category);
  //   setassignmonth(month);
  //   setassignyear(financial_year);
  //   setsource(source);
  //   set_target_id(target_id)
  //   set_id(id);
  //   setopenModal(true);
  //   }
 const handleclose = ()=>{
    setopenModal(false);
    setproduct('');
    setselectedservice('');
    set_financialyear('');
    setselectedemployee('');
    setmonth('');
    set_remark('');
    set_target_type('');
    setValidationErrors({});
    setselectedgroup([]);
    setcategorylist([]);
    setCategoryData([]);



 }
 //alert(year);
 

//console.log(selectedgroup);

//alert(assignyear);
//  const handlesave = async (e) => {
//   e.preventDefault();
//   const errors = {};
//   setValidationErrors(errors);
//  if (Object.keys(errors).length === 0) {
//   const formData = new FormData()
//   formData.append('emp_id',empId);
//   formData.append('department',dept_id);
//   formData.append('group',assigngroup);
//   formData.append('category',assigncategory);
//   formData.append('employee',selectedemployee);
//   formData.append('remark',remark);
//   formData.append('value',value);
//   formData.append('year',assignyear);
//   formData.append('month',assignmonth);
//   formData.append('source',source)
//   formData.append('emp_type',1);
//   formData.append('emp_ids',JSON.stringify(assignedTasks));
//   try {
//     const response = await dashboardApi.post('save-assign-target', formData);

//     if (response.data.status === 200) {
//       //console.log('iiii');
//       Swal.fire({
//         icon: 'success',
//         text: response.data.message,
//       });
//       fetchStock();
//       handleclose();

//     } else if (response.data.status === 400) {
//       // Display validation errors below the input fields
//       setValidationErrors(response.data.messages);
//     }
//     else if (response.data.status === 201) {
//       Swal.fire({
//         icon: 'error',
//         text: response.data.message,
//       });
//     }
//      else {
//       Swal.fire({
//         icon: 'error',
//         text: response.data.message || 'An error occurred',
//       });
//     }
//   } catch (error) {
//     Swal.fire({
//       icon: 'error',
//       text: 'An error occurred while creating the stock',
//     });
//   }
// };
// }
const handleDetailsClick = (empId) => {
  get_team_target((prevData) =>
    prevData.map((employee) =>
      // If the emp_id matches, toggle the showDetails property
      employee.emp_id === empId
        ? { ...employee, showDetails: !employee.showDetails }
        : employee
    )
  );
};


const handlesave = async (e) => {
    e.preventDefault();
    const errors = {};
    if (!selectedemployee) {
      errors.selectedemployee = 'Employee is required';
    }

     if (!product) {
      errors.product = 'Product is required';
    }
     if (!selectedservice) {
      errors.selectedservice = 'Service is required';
    }
     if (!financial_year) {
      errors.financial_year = 'year is required';
    }
    if (!selectedmonth) {
      errors.selectedmonth = 'month is required';
    }

    if (!target_type) {
      errors.target_type = 'Type Is Required';
    }
    if(categoryData.length==0){
       errors.categoryData = 'Please Enter Data in Category';


    }
    if (Object.keys(errors).length > 0) {
         setValidationErrors(errors);
         return;  
    }
    setValidationErrors({});

    const formData = new FormData()
    formData.append('employee', selectedemployee);
    formData.append('target_mode', target_type);
    formData.append('category',JSON.stringify(categoryData));
    formData.append('emp_id', empId);
    formData.append('year',financial_year);
    formData.append('product',product);
    formData.append('month',selectedmonth);
    formData.append('remark',remark);
    formData.append('service',selectedservice);

    //formData.append('no_of_clients',no_of_clients);
    try {
      const response = await dashboardApi.post('save-assign-target-for-sales', formData);

      if (response.data.status === 200) {
      Swal.fire({
        icon: 'success',
        text: response.data.message,
      });
      fetch_sales_target();
      handleclose();

    } else if (response.data.status === 400) {
        setValidationErrors(response.data.messages);
      }
      else if(response.data.status === 500) {
        Swal.fire({
          icon: 'error',
          text: response.data.message || 'An error occurred',
        });
      }

       else {
        Swal.fire({
          icon: 'error',
          text: response.data.message || 'An error occurred',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'An error occurred',
      });
    }
  };

  const show_category_data = async()=>{
    try {
      const response = await dashboardApi.get('category-based-on-group-id-with-total-target-data', {
          params: {
             group :selectedgroup,
             service:selectedservice,

            //emp_id:empId,
          }
        });
      if (response.data.status === 200) {
         setcategorylist(response.data.data)
           
      } else {
        console.error('Error fetching data:', response.data.error);
      }
      //setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
     // setLoading(false);
    }

  }
const handleeditclose = ()=>{
  setlead('');
  setValidationErrors('');
  setopeneditmodel(false);
}

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <div className="card-body">
              <div className="row mb-2">
              </div>

                <div className="row mb-3">
                <div className="col">
                     <label htmlFor="department">Product:</label>
                     <select
                    class="form-select"
                    classname="form-control"
                    value={product}
                    onChange={(e) => setproduct(e.target.value)}
                    aria-label="Default select example"
                    style={{ width: '100%', padding: '8px', fontSize: '14px' }}
                  >
                    <option value="">Select Product</option>
                    {productlist.map(dept => (
                      <option key={dept.id} value={dept.id}>
                        {dept.product_name}
                      </option>
                    ))}
                   
                  </select>
                  </div>
                  <div className="col">
                     <label htmlFor="department">Category:</label>
                    <select
                      value={selectedcategory}
                      onChange={(e) => setselectedcategory(e.target.value)}
                      style={{ width: '100%', padding: '8px', fontSize: '14px' }}
                    >
                      <option value="">Select Category</option>
                      {category_details.map(row => (
                        <option key={row.id} value={row.id}>
                          {row.category_name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="col">
                     <label htmlFor="department">Group:</label>
                     <select
                         value={selectedgroup}
                          onChange={(e) => setselectedgroup(e.target.value)}
                            style={{ width: '100%', padding: '8px', fontSize: '14px' }}
                            >
                            <option value="">Select Group</option>
                             {grouplist.map(row => (
                               <option key={row.group_id} value={row.group_id}>
                                {row.name}
                                  </option>
                                ))}
                              </select>

                  </div>
                  <div className="col">
                     <label htmlFor="department">Year:</label>
                      <select
                         value={financial_year}
                         onChange={(e) => set_financialyear(e.target.value)}
                         style={{ width: '100%', padding: '8px', fontSize: '14px' }}
                        >
                        <option value ="">Select year</option>
                        {financialYears.map((year) => (
                        <option key={year} value={year}>{year}</option>
                     ))}
                  </select>
                  </div>

                   <div className="col">
                     <label htmlFor="department"> Month:</label>
                       <select id="months"
                         value={selectedmonth}
                          onChange={(e) => setmonth(e.target.value)}
                          style={{ width: '100%', padding: '8px', fontSize: '14px' }}

                          >
                            <option value ="">Select Month</option>
                             {months.map((month, index) => (
                             <option key={index} value={month}>{month}</option>
                            ))}
                         </select>

                  </div>
                </div>

                <div style={{float:'right'}}>
                 <button className='btn btn-info btn-sm' onClick={assign_to_team_view}>Assign Target</button>
                </div>

                <center style={{color:'red'}}>Targets</center>

                {loading ? (
                  <p>Loading...</p>
                ) : (
                  <table className="table table-hover table-bordered table-striped table-responsive">
                    <thead>
                      <tr>
                        <td>S.No</td>
                        <td>Group</td>
                        <td>Product</td>
                        <td>Service</td>
                        <td>Category</td>
                        <td>Month</td>
                        <td>Year</td>
                        <td>Total Lead Target</td>
                        <td>Acheived</td>
                        <td>Remaining</td>
                        <td>No of Active packages</td>
                        <td>Acheived</td>
                        <td>Remaining</td>
                      </tr>
                    </thead>
                    <tbody>
                      {targetList.map((row, index) => (
                        <tr key={row.category_id-row.group_id}>
                          <td>{index + 1}</td>
                          <td>{row.group}</td>
                          <td>{row.product}</td>
                          <td>{row.service}</td>
                          <td>{row.category}</td>
                          <td>{row.month}</td>
                          <td>{row.financial_year}</td>
                          <td>{row.value}</td>
                          <td>{row.acheived}</td>
                          <td>{row.remaining}</td>
                          <td>{row.no_of_packages}</td>
                          <td>{row.acheived_packages}</td>
                          <td>{row.remaining_packages}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
                {targetList.length > 0 && (
                  <Pagination className="custom-pagination">
                    <ReactPaginate
                      previousLabel={"Previous"}
                      nextLabel={"Next"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={number}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination"}
                      activeClassName={"active"}
                      pageLinkClassName={"page-link-style"}
                      breakLinkClassName={"break-link-style"}
                      previousLinkClassName={"prev-link-style"}
                      nextLinkClassName={"next-link-style"}
                    />
                  </Pagination>
                )}
              </div>
              <center style={{color:'red'}}>Amount Target</center>
              {loading ? (
                  <p>Loading...</p>
                ) : (
                  <table className="table table-hover table-bordered table-striped">
                    <thead>
                      <tr>
                        <td>S.No</td>
                        <td>Group</td>
                        <td>Product</td>
                        <td>Service</td>
                        <td>Category</td>
                        <td>Month</td>
                        <td>Year</td>
                        <td>Total Amount</td>
                        <td>Acheived Amount</td>
                        <td>Remaining Amount</td>
                      </tr>
                    </thead>
                    <tbody>
                      {amount_target.map((row, index) => (
                        <tr key={row.category_id-row.group_id}>
                          <td>{index + 1}</td>
                          <td>{row.group}</td>
                          <td>{row.product}</td>
                          <td>{row.service}</td>
                          <td>{row.category}</td>
                          <td>{row.month}</td>
                          <td>{row.financial_year}</td>
                          <td>{row.amount}</td>
                          <td>{row.acheived}</td>
                          <td>{row.remaining}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
                {amount_target.length > 0 && (
                  <Pagination className="custom-pagination">
                    <ReactPaginate
                      previousLabel={"Previous"}
                      nextLabel={"Next"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={number}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={handletargetamount}
                      containerClassName={"pagination"}
                      activeClassName={"active"}
                      pageLinkClassName={"page-link-style"}
                      breakLinkClassName={"break-link-style"}
                      previousLinkClassName={"prev-link-style"}
                      nextLinkClassName={"next-link-style"}
                    />
                  </Pagination>
                )}

                <center>
                  <div className='table-responsive'>
    <table className="table table-hover table-bordered table-striped">
  <thead>
    <tr>
      <td>Name</td>
      <td>Total Target</td>
      <td>Month</td>
      <td>Year</td>
      <td>Achieved</td>
      <td>Remaining</td>
      <td>Complete Percent</td>
      <td>No Of Active Pkgs</td>
      <td>Acheived</td>
      <td>Remaining</td>
      <td>Action</td>
    </tr>
  </thead>
  <tbody>
    {manager_target.map((row, index) => (
      <tr key={row.manager_id}>
        <td>{row.name}</td>
        <td>{row.total_targets}</td>
        <td>{row.month}</td>
        <td>{row.f_year}</td>
        <td>{row.total_acheived}</td>
        <td>{row.remaining}</td>
        <td>{row.complete_percent}</td>
        <td>{row.total_packages}</td>
        <td>{row.achevied_packages}</td> 
        <td>{row.remaining_packages}</td> 
        <td>
          <button
            onClick={() => managerteam_collection(row.manager_id)}
            className="btn btn-info btn-sm"
          >
            Team
          </button>
        </td>
      </tr>
    ))}
  </tbody>
</table>
</div>

  </center>
    {team_collection_view == true &&
                 <center>
      <h5>Team Targets Details</h5>
      <div className="table-responsive">
        <table className="table table-hover table-bordered table-striped">
          <thead>
            <tr>
              <td>Name</td>
              <td>Total Target</td>
              <td>Month</td>
              <td>Year</td>
              <td>Achieved</td>
              <td>Remaining</td>
              <td>Complete Percent</td>
              <td>Active Packages</td>
              <td>Achevied</td>
              <td>Remaining</td>
              <td>Action</td>
            </tr>
          </thead>
          <tbody>
            {team_target.map((row) => (
              <React.Fragment key={row.emp_id}>
                {/* Employee Summary Row */}
                <tr>
                  <td>{row.name}</td>
                  <td>{row.total_target}</td>
                  <td>{row.month}</td>
                  <td>{row.year}</td>
                  <td>{row.achieved}</td>
                  <td>{row.remaining}</td>
                  <td>{row.complete_percent}</td>
                  <td>{row.no_of_packages}</td>
                  <td>{row.achevied_packages}</td>
                  <td>{row.remaining_packages}</td>
                  <td>
                    <button className='btn btn-info btn-sm' onClick={() => handleDetailsClick(row.emp_id)}>
                      {row.showDetails ? 'Details' : 'Details'}
                    </button>
                  </td>
                </tr>

                {/* Employee Details Row */}
                {row.showDetails && (
                  <tr>
                    <td colSpan="10">
                      <table className="table table-bordered" style={{ width: '100%' }}>
                        <thead>
                          <tr>
                            <td>Product</td>
                            <td>Category</td>
                            <td>Group</td>
                            <td>Total Target</td>
                            <td>Achieved</td>
                            <td>Remaining</td>
                          </tr>
                        </thead>
                        <tbody>
                          {row.details?.map((detail, index) => (
                            <tr key={index}>
                              <td>{detail.product}</td>
                              <td>{detail.category}</td>
                              <td>{detail.group}</td>
                              <td>{detail.total_target}</td>
                              <td>{detail.achieved}</td>
                              <td>{detail.remaining}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </center>
                  }
              
        </Card>

        <Dialog
        open={openModal}
        fullWidth
        PaperProps={{ style: { maxWidth: 'xl', padding: '40px', height:'2200x'} }}
      >
        <DialogTitle>Assign To Team</DialogTitle>
        <DialogContent>
          <form>
            <div className="container">
              <div className='row'>
                <div className='col-md-6'>
                <label htmlFor="short name" style={{ fontSize: '16px' }}>Select Employee</label>
                <select
                  className="form-control form-select"
                  value={selectedemployee}
                  onChange={(e) => setselectedemployee(e.target.value)}
                  aria-label="Default select example"
                  style={{ width: '100%', padding: '8px', fontSize: '11px' }}
                >
                  <option>Select Team</option>
                  {employee.map((row) => (
                    <option key={row.emp_id} value={row.emp_id}>
                      {row.emp_fname} {row.emp_lame}
                    </option>
                  ))}
                </select>
                 {validationErrors.selectedemployee && (
                      <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.selectedemployee}</div>
                  )}


                </div>

                <div className='col-md-6'>
                <div className="form-group">
                  <label htmlFor="short name" style={{ fontSize: '16px' }}>Select Product</label>
                  <select
                  className="form-control form-select"
                  value={product}
                  onChange={(e) => setproduct(e.target.value)}
                  aria-label="Default select example"
                  style={{ width: '100%', padding: '8px', fontSize: '11px' }}
                >
                   <option value="">Select Product</option>
                    {productlist.map(pro => (
                      <option key={pro.id} value={pro.id}>
                        {pro.product_name}
                      </option>
                    ))}
                </select>
                          {validationErrors.product && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.product}</div>
                             )}
                        </div>


                </div>


              </div>

               <div className='row'>
                <div className='col-md-6'>
                <label htmlFor="short name" style={{ fontSize: '16px' }}>Select Service</label>
                <select
                  className="form-control form-select"
                  value={selectedservice}
                  onChange={(e) => setselectedservice(e.target.value)}
                  aria-label="Default select example"
                  style={{ width: '100%', padding: '8px', fontSize: '11px' }}
                >
                  <option>Select Service</option>
                  {servicelist.map((row) => (
                    <option key={row.id} value={row.id}>
                      {row.service_name} 
                    </option>
                  ))}
                </select>
                 {validationErrors.selectedservice && (
                      <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.selectedservice}</div>
                  )}


                </div>
                <div className='col-md-6'>
                <div className="form-group">
                  <label htmlFor="short name" style={{ fontSize: '16px' }}>Select Year</label>
                   <select
                         className="form-control form-select"
                         value={financial_year}
                         onChange={(e) => set_financialyear(e.target.value)}
                          aria-label="Default select example"
                         style={{ width: '100%', padding: '8px', fontSize: '11px' }}
                        >
                        <option value ="">Select year</option>
                        {financialYears.map((year) => (
                        <option key={year} value={year}>{year}</option>
                     ))}
                  </select>
                          {validationErrors.financial_year && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.financial_year}</div>
                             )}
                        </div>


                </div>

                </div>

              <div className='row'>
                <div className='col-md-6'>
                <label htmlFor="short name" style={{ fontSize: '16px' }}>Select Month</label>
                    <select id="months"
                         className="form-control form-select"
                         value={selectedmonth}
                          onChange={(e) => setmonth(e.target.value)}
                          aria-label="Default select example"
                          style={{ width: '100%', padding: '8px', fontSize: '11px' }}
                          >
                          <option value ="">Select Month</option>
                             {months.map((month, index) => (
                             <option key={index} value={month}>{month}</option>
                            ))}
                         </select>
                 {validationErrors.selectedmonth && (
                      <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.selectedmonth}</div>
                  )}

                </div>
                </div>
                <div className="row">
                <div className="col-md-12">
                 <label htmlFor="short name" style={{ fontSize: '16px' }}>Select Group</label>
           <div className="row">
            {grouplist.map((row) => (
            <div key={row.group_id} className="col-md-4" style={{ display: 'flex', alignItems: 'center' }}>
              <input
                type="checkbox"
                id={`group_${row.group_id}`}
                value={row.group_id}
                checked={selectedgroup.includes(row.group_id)}
                onChange={(e) => {
                  if (e.target.checked) {
                    setselectedgroup([...selectedgroup, row.group_id]);
                  } else {
                    setselectedgroup(selectedgroup.filter((id) => id !== row.group_id));
                  }
                }}
                style={{ marginRight: '8px' }}
              />
              <label htmlFor={`group_${row.group_id}`} style={{ fontSize: '14px' }}>{row.name}</label>
            </div>
          ))}
        </div>
        </div>
        </div>
        <div className="row">
  <div className="col-md-12">
    <label htmlFor="short name" style={{ fontSize: '16px' }}>
      Select Category
    </label>
    {validationErrors.categoryData && (
                      <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.categoryData}</div>
                  )}
    <div className="row">
      {categorylist.map((group) => (
        <div key={group.group_id} className="col-md-12" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <h4 style={{ color: 'red' }}>{group.group_name}</h4> 

          <div className="row" style={{ width: '100%' }}>
            {group.categories.map((category) => (
              <div key={category.category_id} className="col-md-12" style={{ marginBottom: '10px', display: 'flex', justifyContent: 'space-between' }}>
                
                <div className="col-md-5" style={{ paddingRight: '5px' }}>
                  <label htmlFor={`no_of_leads_${category.category_id}`} style={{ fontSize: '14px' }}>
                    <span style={{color:'red'}}>{category.category_name}(leads To Assign = {category.no_of_leads})</span>
                  </label>
                  <input
                    type="text"
                    id={`no_of_leads_${category.category_id}`}
                    onChange={(e) => handleCategoryChange(e, group.group_id, category.category_id, 'leads')}
                    style={{ width: '100%', padding: '8px', fontSize: '14px' }}
                    placeholder="No Of Leads"
                  />
                </div>

                <div className="col-md-5" style={{ paddingLeft: '5px' }}>
                  <label htmlFor={`no_of_packages_${category.category_id}`} style={{ fontSize: '14px' }}>
                  <span style={{color:'red'}}>{category.category_name}(Packages To Assign = {category.no_of_packages})</span>
                  </label>
                  <input
                    type="text"
                    id={`no_of_packages_${category.category_id}`}
                    onChange={(e) => handleCategoryChange(e, group.group_id, category.category_id, 'packages')}
                    style={{ width: '100%', padding: '8px', fontSize: '14px' }}
                    placeholder="No Of Packages"
                  />
                </div>
              </div>
            ))}


          </div>
        </div>
      ))}
    </div>
  </div>
</div>





              <div className='row'>
                <div className='col-md-12'>
               <div className="form-group">
                          <label htmlFor="short name" style={{ fontSize: '16px' }}>Assign Target</label>
                          <select className='form-control'
                           value={target_type}
                           onChange={(event)=>{
                           set_target_type(event.target.value)}}
                          
                          >
                            <option>Assign Target</option>
                            <option value='team_based'>Team Based</option>
                            <option value='individual'>Individual</option>

                          </select>
                          {validationErrors.target_type && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.target_type}</div>
                             )}
                        </div>


              </div>
              </div>
              <div className="row">
              <div className='col-md-12'>
               <div className="form-group">
                          <label htmlFor="short name" style={{ fontSize: '16px' }}>Remark</label>
                          <textarea
                            className='form-control'
                            value={remark} 
                             onChange={(event)=>{
                              set_remark(event.target.value)}}
                              placeholder="Add Remark"
                            rows="2"
                           cols="30"
                         />
                          {validationErrors.remark && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.remark}</div>
                             )}
                        </div>


              </div>

              </div>

              </div>
          </form>
        </DialogContent>
        <DialogActions>
          <button className="btn btn-danger" onClick={handleclose}>
            Close
          </button>
          <button className="btn btn-info" onClick={handlesave}>
            Save
          </button>
        </DialogActions>
       </Dialog>

          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default SalesLead;